import {
  polygonClickEnabledStyle,
  gradientLegend,
  gradientLayerStyle,
} from '@/apps/features/map/utils';
import { GENERATION_POTENTIAL } from '@/configs/color-constants';

export const LAYER_KEY__POTENTIAL_PV_FREE = 'potential_pv_free';
export const LAYER_KEY__POTENTIAL_WIND = 'potential_wind';

const defaultLegendPotential = (name, color) => ({
  name: 'Potenziale',
  unit: '',
  items: [
    {
      color: color,
      name: name,
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export default {
  // potentials:
  [LAYER_KEY__POTENTIAL_PV_FREE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__POTENTIAL_PV_FREE,
      sourceLayer: LAYER_KEY__POTENTIAL_PV_FREE,
      layerId: LAYER_KEY__POTENTIAL_PV_FREE,
      visibility: 'none',
      tilesDirectory: 'uc1',
    },
    sidebar: {
      name: 'PV-Freifläche (Potenzial)',
      subtitles: ['id', 'municipality_name', 'municipality_key'],
      chips: [
        {
          title: 'Potenziale',
          active: true,
          items: [
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'globalradiation_kwh_per_m2',
                  unit: 'kWh/m²',
                  decimal: 0,
                },
                {
                  key: 'potential_area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'pv_free_full_load_hours_h_a',
                  unit: 'h/a',
                  decimal: 0,
                },
                {
                  key: 'power_kw',
                  unit: 'kW',
                  decimal: 0,
                },
                {
                  key: 'production_kwh_a',
                  unit: 'MWh/a',
                  decimal: 0,
                  multiply: 0.001,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: polygonClickEnabledStyle([GENERATION_POTENTIAL.PV_FREE]),
      potentialPvFreeKw: polygonClickEnabledStyle(
        gradientLayerStyle(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 10000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
    },
    legend: {
      default: defaultLegendPotential(
        'PV-Freifläche',
        GENERATION_POTENTIAL.PV_FREE,
      ),
      potentialPvFreeKw: gradientLegend(
        'Potenzial PV-Freifläche nach Nennleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 10, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
    },
  },
  [LAYER_KEY__POTENTIAL_WIND]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'potentials',
      router_name: 'PotentialWind',
      key_name: 'id',
      type: 'polygon',
      id: LAYER_KEY__POTENTIAL_WIND,
      sourceLayer: LAYER_KEY__POTENTIAL_WIND,
      layerId: LAYER_KEY__POTENTIAL_WIND,
      visibility: 'none',
      tilesDirectory: 'uc1',
    },
    sidebar: {
      name: 'Wind (Potenzial)',
      subtitles: ['id', 'municipality_name', 'municipality_key'],
      chips: [
        {
          title: 'Potenziale',
          active: true,
          items: [
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'potential_area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'potential_wind_full_load_hours_h_a',
                  unit: 'h/a',
                  decimal: 0,
                },
                {
                  key: 'power_kw',
                  unit: 'kW',
                  decimal: 0,
                },
                {
                  key: 'production_kwh_a',
                  unit: 'MWh/a',
                  decimal: 0,
                  multiply: 0.001,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: polygonClickEnabledStyle([GENERATION_POTENTIAL.WIND]),
      potentialWindKw: polygonClickEnabledStyle(
        gradientLayerStyle(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
          { value: 50000, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
        ),
      ),
    },
    legend: {
      default: defaultLegendPotential('Wind', GENERATION_POTENTIAL.WIND),
      potentialWindKw: gradientLegend(
        'Windpotenzial nach Nennleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
        { value: 50, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
      ),
    },
  },
};
