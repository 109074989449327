import { defineStore } from 'pinia';
import axios from '@/utils/axiosHelper';
import { restFetchAll, restFetchAllWithParam } from '@/utils/rest-utils';
import { PotentialAnalysisDto } from './potential-analysis-dto';

export const usePotentialAnalysisStore = defineStore('potential-analysis', {
  state: () => ({
    categoriesNamingMap: {},
    subCategoriesNamingMap: {},
    categoriesData: [],
    potentialsData: [],
  }),
  actions: {
    async fetchRestrictions() {
      if (this.categoriesData.length === 0) {
        const data = await axios({
          method: 'GET',
          url: '/api/usecase-2/restrictions/',
        });
        this.categoriesNamingMap = data.data.categories;
        this.subCategoriesNamingMap = data.data.subcategories;
        this.categoriesData = data.data.grouped_categories;
      }
    },
    async fetchPotentials() {
      try {
        this.potentialsData = await restFetchAllWithParam(
          PotentialAnalysisDto,
          { no_pagination: true },
        );
        this.potentialsData.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
});
