import MapboxDraw from '@mapbox/mapbox-gl-draw';
const DrawLineString = MapboxDraw.modes.draw_line_string;
import length from '@turf/length';

const measurePoints = Object.assign({}, DrawLineString);

function getLengthInMeters(feature) {
  return length(feature, { units: 'kilometers' }) * 1000;
}

measurePoints.onSetup = function (opts) {
  const onSetup = DrawLineString.onSetup.bind(this);
  const state = onSetup();
  state.drawCreateCallback = opts.drawCreateCallback;
  return state;
};

measurePoints.clickAnywhere = function (state, e) {
  const clickAnywhere = DrawLineString.clickAnywhere.bind(this, state, e);
  clickAnywhere();
  if (state.line.coordinates.length > 1) {
    const distance = getLengthInMeters(state.line.toGeoJSON()).toLocaleString(
      'de-DE',
      { maximumFractionDigits: 0 },
    );
    if (typeof state.drawCreateCallback === 'function') {
      state.drawCreateCallback(`Länge: ${distance} m`);
    }
  }
};

export default measurePoints;
