<template>
  <div class="flex flex-col gap-5">
    <h4>Wappenauswahl</h4>
    <div class="standard-elevation-0 py-2.5 pl-2 pr-5">
      <VRadioGroup v-model="ThumbnailChoice">
        <VRadio value="custom" class="mb-5" @click="ThumbnailChoice = 'custom'">
          <template #label>
            <div
              class="flex items-center justify-between w-full cursor-not-allowed"
            >
              <h5 class="cursor-pointer ml-2">Eigenes Wappen</h5>
              <ButtonEl
                v-if="!imageFileUrl"
                text="Hochladen"
                icon="add_moderator"
                variant="secondary"
                color="color2"
                icon-type="round"
                :icon-right="true"
                class="cursor-pointer"
                :disabled="ThumbnailChoice === 'placeholder'"
                @click="triggerFileUpload"
              />
              <div
                v-if="imageFileUrl"
                class="bg-subtle flex py-2 px-4 rounded-[48px] items-center cursor-default"
              >
                <img
                  :src="imageFileUrl"
                  alt="Image Preview"
                  class="w-8 object-contain mr-2.5"
                />
                <IconWrapper
                  icon="close"
                  fill="text-core-color1"
                  hover="hover:text-button-primary-hover-color1"
                  class="cursor-pointer"
                  @click="imageFile = null"
                />
              </div>
              <VFileInput
                ref="fileUploadEl"
                :model-value="imageFile"
                accept="image/png, image/jpeg, image/bmp"
                class="hidden"
                @update:model-value="handleFileUpload"
              />
            </div>
          </template>
        </VRadio>
        <VRadio
          value="placeholder"
          @click="
            ThumbnailChoice = 'placeholder';
            imageFile = null;
          "
        >
          <template #label>
            <div class="flex w-full align-center justify-between">
              <h5 class="ml-2">Platzhalter verwenden</h5>
              <img
                src="@/assets/svg/coat-of-arms-placeholder.svg"
                class="cursor-default w-8 mr-4"
              />
            </div>
          </template>
        </VRadio>
      </VRadioGroup>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { useToastStore } from '@/stores/toast-store';

const { showToast } = useToastStore();

const emit = defineEmits(['fileChange', 'isValid']);

const ThumbnailChoice = ref('custom');
const imageFile = ref(null);
const fileUploadEl = ref('fileUploadEl');
const isValid = ref(false);

const imageFileUrl = computed(() => {
  return imageFile.value ? URL.createObjectURL(imageFile.value) : null;
});

const triggerFileUpload = () => {
  fileUploadEl.value.click();
};

const handleFileUpload = (file) => {
  imageFile.value = file;
};

watch(imageFile, (newFile) => {
  function handleFileValidation(isValidValue, message = null) {
    isValid.value = isValidValue;
    if (message) {
      showToast({ message, color: 'error' });
    }
    emit('isValid', isValid.value);
    emit('fileChange', newFile);
  }

  if (!newFile) return handleFileValidation(false);

  if (newFile?.size > 1000000) {
    imageFile.value = null;
    return handleFileValidation(
      false,
      'Wappen darf nicht größer als 1 MB sein',
    );
  }

  handleFileValidation(true);
});

watch(ThumbnailChoice, (newChoice) => {
  // Always valid if placeholder, for custom choice also file upload needed
  isValid.value = newChoice === 'placeholder';
  emit('isValid', isValid.value);
});
</script>

<style lang="scss" scoped>
:deep(.v-label) {
  width: 100%;
}

:deep(.v-selection-control) {
  width: 100%;
  max-width: unset;
}
</style>
