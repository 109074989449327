<template>
  <div
    class="bg-elevated standard-elevation-0-dark grid grid-flow-row auto-rows-fr gap-5 p-5 rounded-[20px] custom-height"
  >
    <div
      v-for="row in cardRows"
      :key="row"
      class="flex flex-col gap-5 justify-center"
    >
      <LandingPageCardRow
        :card-data="cardData.filter((e) => e.row === row)"
        :is-landing-page="true"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { rawCardData } from './raw-card-data';
import LandingPageCardRow from '@/apps/landing-page/components/LandingPageCardRow.vue';
import { useUsers } from '@/apps/auth/users-store';

const userStore = useUsers();

const router = useRouter();

const cardData = rawCardData.map(attachNavigate);
const cardRows = computed(() => {
  const rows = new Set(cardData.map((card) => card.row));
  return Array.from(rows).sort();
});

function attachNavigate(card) {
  return {
    ...card,
    action: () => router.push({ name: card.routeName }),
    children: card.children?.map(attachNavigate),
    disabled: !userStore.hasPermission(
      card.permission.app,
      card.permission.permission,
    ),
  };
}
</script>

<style scoped>
.custom-height {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
