import en from '../translations/en';
import de from '../translations/de';

export default {
  // current locale
  locale: 'de',

  // when translation is not available fallback to that locale
  fallbackLocale: 'de',

  // availabled locales for user selection
  availableLocales: [
    {
      code: 'en',
      flag: 'us',
      label: 'English',
      messages: en,
    },
    {
      code: 'de',
      flag: 'de',
      label: 'Deutsch',
      messages: de,
    },
  ],
};
