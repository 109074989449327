import MapboxDraw from '@mapbox/mapbox-gl-draw';
import FreehandMode from '@/apps/features/map/draw/free-hand';
import MeasurePoints from '@/apps/features/map/draw/measure-points';
import MeasureArea from '@/apps/features/map/draw/measure-area';
import FeaturesWithin from '@/apps/features/map/draw/features-within';
import SelectFeature from '@/apps/features/map/draw/select-feature';
import drawLayerStyle from './drawLayerStyle';
import { getZoom } from '@/mapbox/main';
import simplify from '@turf/simplify';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { modes } from './constants';
import setPoint from '@/apps/features/map/draw/set-point';

FreehandMode.simplify = function (polygon) {
  simplify(polygon, {
    mutate: true,
    tolerance: 1 / Math.pow(2, getZoom()),
    highQuality: true,
  });
};

export const draw = new MapboxDraw({
  userProperties: true,
  displayControlsDefault: false,
  controls: {
    polygon: true,
    line_string: true,
    point: true,
    trash: true,
  },
  styles: drawLayerStyle,
  modes: Object.assign(MapboxDraw.modes, {
    draw_polygon: FreehandMode,
    [modes.DRAW_FEATURES_WITHIN]: FeaturesWithin,
    [modes.DRAW_SELECT_FEATURE]: SelectFeature,
    [modes.DRAW_MEASURE_LINE]: MeasurePoints,
    [modes.DRAW_MEASURE_POLYGON]: MeasureArea,
    [modes.DRAW_SET_POINT]: setPoint,
  }),
});

export function addDraw(map) {
  map.addControl(draw, 'top-right');
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon',
  ).style.display = 'none';
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash',
  ).style.display = 'none';
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line',
  ).style.display = 'none';
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_point',
  ).style.display = 'none';
}

export function deleteFeatures() {
  draw.deleteAll();
}

export function getDrawFeatures() {
  return draw.getAll().features;
}

export function getDrawFeaturesByType(featureType) {
  return draw
    .getAll()
    .features.filter((e) => e.properties.featureType === featureType);
}

export function exitDraw() {
  const trashButton = document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash',
  );
  if (trashButton) trashButton.click();
}
