<template>
  <div class="map-container h-full">
    <InfoPanel
      v-if="showInfoPanel"
      class="absolute top-[80px] flex items-stretch z-20"
      :feature="clickedFeature"
      :feature-id="clickedFeatureId"
      :map-store
      :panel-objects
      data-test="info-panel"
    />
    <div id="map"></div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import {
  createMap,
  destroyMap,
  setFilter,
  setStyle,
  setVisibility,
  toggleSatellite,
  changeSourceLayer,
} from '@/mapbox/main';
import InfoPanel from '@/apps/features/map/info-panel/InfoPanel.vue';
import { storeToRefs } from 'pinia';

const emits = defineEmits(['map-style-loaded-signal']);
const props = defineProps({
  selectedAnalysisId: {
    type: Number,
    required: true,
  },
  mapStore: {
    type: Object,
    required: true,
  },
  panelObjects: {
    type: Array,
    required: true,
  },
  baseYear: {
    type: Number,
    default: new Date().getFullYear(),
  },
  layerConfig: {
    type: Object,
    required: true,
  },
  flyToFunction: {
    type: Function,
    required: true,
  },
  // function is executed whenever left click event on map is fired
  // and has the first queried feature as parameter (undefined when no feature
  // was found)
  extendLeftClickFunctionality: {
    type: Function,
    default: (feature) => {},
  },
});

const {
  satelliteIsActive,
  showInfoPanel,
  year,
  getLayerStates,
  getAppliedFilters,
  getLayers,
} = storeToRefs(props.mapStore);

const clickedFeature = ref(null);
const clickedFeatureId = ref(null);
const initialMapStyleIsLoaded = ref(false);
const layer = ref(props.layerConfig);

props.mapStore.setYear(props.baseYear);

onMounted(() => {
  createMap(
    props.mapStore,
    {
      applyFilters,
      featureClicked,
      featureIdClicked,
      initialMapStyleLoaded,
      styleLoaded,
      flyToFunction: props.flyToFunction,
      extendOnClick: props.extendLeftClickFunctionality,
    },
    layer.value,
    year,
    props.selectedAnalysisId,
  );
});

onUnmounted(() => {
  destroyMap();
  props.mapStore.resetSearch();
});

function applyFilters(filters) {
  Object.entries(filters).forEach(([key, value]) => {
    setFilter(key, value);
  });
}

function initialMapStyleLoaded(isLoaded) {
  initialMapStyleIsLoaded.value = isLoaded;
}

function featureClicked(feature) {
  clickedFeature.value = feature;
}

function featureIdClicked(id) {
  clickedFeatureId.value = id;
}

function styleLoaded() {
  emits('map-style-loaded-signal');
}

watch(satelliteIsActive, (newValue) => {
  toggleSatellite(newValue);
});

watch(
  [getLayerStates, year],
  () => {
    setVisibility(getLayerStates.value);
    setStyle(getLayerStates.value, layer.value, year.value);
  },
  { deep: true },
);

watch(
  () => props.selectedAnalysisId,
  (newVal, oldVal) => {
    const analysisIds = { previous: oldVal, new: newVal };
    getLayers.value.forEach((sourceLayer) => {
      const tilesDirectory =
        props.layerConfig[sourceLayer].layerConfig.tilesDirectory;
      changeSourceLayer(sourceLayer, analysisIds, tilesDirectory);
    });
    props.mapStore.setShowInfoPanel(false);
  },
  { deep: true },
);

watch(
  getAppliedFilters,
  (newValue) => {
    if (initialMapStyleIsLoaded.value) {
      applyFilters(newValue);
    }
  },
  { deep: true },
);
</script>
