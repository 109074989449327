<template>
  <div class="standard-container">
    <h5 class="text-title-neutral">
      Ausweisung von Potenzialen nur bei folgenden Flächenkategorien:
    </h5>
    <div class="flex gap-5">
      <div
        class="w-full standard-elevation-0-dark rounded-md p-5 flex flex-col gap-2.5"
      >
        <div class="subtitle-2 text-title-neutral flex gap-2.5 items-center">
          Benachteiligte Flächen
          <ToolTip
            class="pb-0.5"
            tooltip-text="Benachteiligte Gebiete nach Maßgabe der FFÖ-VO bzw. des EEG auf Ebene der Gemarkungen. Sind Gemarkungen vollständig als benachteiligtes Gebiet erfasst, liegen alle Flurstücke der Gemarkung im benachteiligten Gebiet. In den Fällen, in denen nur Teilflächen als benachteiligtes Gebiet eingestuft sind, befinden sich nur Teile (Flurstücke) der Gemarkung im benachteiligten Gebiet."
          />
        </div>
        <div class="flex flex-col gap-2.5">
          <CheckboxEl
            v-for="choice in disadvantageAreaMeta.filter((e) => e.value !== 2)"
            :key="choice.value"
            v-model="disadvantageArea"
            :label="choice.display_name"
            :value="choice.value"
            :disabled="readOnly"
          />
        </div>
        <div class="subtitle-3 text-title-neutral flex gap-2.5 mt-5">
          {{ explainChoice.disadvantage }}
        </div>
      </div>
      <div
        class="w-full standard-elevation-0-dark rounded-md p-5 flex flex-col gap-2.5"
      >
        <div class="subtitle-2 text-title-neutral flex gap-2.5 items-center">
          Landwirtschaftliche Parzellen
          <ToolTip
            class="pb-0.5"
            tooltip-text="Kategorisierung von landwirtschaftlichen Parzellen gemäß dem Geografischen Informationssystem Entwicklung in der Landwirtschaft (GISELa)."
          />
        </div>
        <div class="flex flex-col gap-2.5">
          <CheckboxEl
            v-for="choice in agriculturalPlotsMeta"
            :key="choice.value"
            v-model="agriculturalPlots"
            :label="choice.display_name"
            :value="choice.value"
            :disabled="readOnly"
          />
        </div>
        <div class="subtitle-3 text-title-neutral flex gap-2.5 mt-5">
          {{ explainChoice.landUse }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { toRefs, useVModel } from '@vueuse/core';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  metaData: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);
const {
  agricultural_plots_list: agriculturalPlots,
  disadvantage_area_list: disadvantageArea,
} = toRefs(useVModel(props, 'modelValue', emit));

const {
  agricultural_plots_list: {
    child: { choices: agriculturalPlotsMeta },
  },
  disadvantage_area_list: {
    child: { choices: disadvantageAreaMeta },
  },
} = props.metaData;

const explainChoice = computed(() => {
  const explain = {
    landUse:
      'Es gibt keine Einschränkungen bezüglich landwirtschaftlicher Parzellen, alle Flächen werden berücksichtigt',
    disadvantage:
      'Es gibt keine Einschränkungen bezüglich benachteiligter Flächen, alle Flächen werden berücksichtigt',
  };
  if (agriculturalPlots.value.length === 2) {
    explain.landUse =
      'Nur Ackerland- und Grünland-Parzellen werden berücksichtigt';
  } else if (Array.from(agriculturalPlots.value).includes(0)) {
    explain.landUse = 'Nur Ackerland-Parzellen werden berücksichtigt';
  } else if (Array.from(agriculturalPlots.value).includes(1)) {
    explain.landUse = 'Nur Grünland-Parzellen werden berücksichtigt';
  }

  if (disadvantageArea.value.length === 2) {
    explain.disadvantage =
      'Nur benachteiligte und teilweise benachteiligte Flächen werden berücksichtigt';
  } else if (Array.from(disadvantageArea.value).includes(0)) {
    explain.disadvantage = 'Nur benachteiligte Flächen werden berücksichtigt';
  } else if (Array.from(disadvantageArea.value).includes(1)) {
    explain.disadvantage =
      'Nur teilweise benachteiligte Flächen werden berücksichtigt';
  }

  return explain;
});
</script>
