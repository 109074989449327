export const LAYER_KEY__ROAD = 'road';

import {
  LIND_GREEN_60,
  IMPULSE_ORANGE_MEDIUM,
  DEEP_BLUE_MEDIUM,
  NEUTRAL_GREY_60,
  WARM_GREY_50,
  GREY_BEIGE_70,
  GREY_BEIGE_90,
  IMPULSE_ORGANGE_DARK,
  NEUTRAL_GREY_30,
  ROAD_TYPES_BY_ENUMS,
  RESTRICTIONS_BY_ENUM,
} from '@/configs/color-constants';

export default {
  [LAYER_KEY__ROAD]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__ROAD,
      sourceLayer: LAYER_KEY__ROAD,
      layerId: LAYER_KEY__ROAD,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    style: {
      default: {
        lineColor: [
          'match',
          ['get', 'surface_category_pade'],
          ...Object.entries(ROAD_TYPES_BY_ENUMS).flatMap(([key, { color }]) => [
            key,
            color,
          ]),
          NEUTRAL_GREY_30,
        ],
        lineWidth: 1,
      },
    },
    legend: {
      default: {
        name: 'Straßen und Wege',
        unit: '',
        items: Object.values(ROAD_TYPES_BY_ENUMS).map((v) => ({
          color: v.color,
          name: v.label,
          icon: 'mdi-checkbox-blank-circle',
        })),
      },
    },
  },
};
