export const SECTOR_LEGENDS_ORDERED = [
  'Private Haushalte',
  'Gewerbe, Handel,\nDienstleistungen',
  'Industrie',
  'Öffentlich',
  'Mobilität',
  'Sonstige',
];
export const SECTOR_NO_MOBILITY_LEGENDS_ORDERED = [
  'Private Haushalte',
  'Gewerbe, Handel,\nDienstleistungen',
  'Industrie',
  'Öffentlich',
  'Sonstige',
];
export const RENEWABLE_GENERATION_LEGENDS_ORDERED = [
  'PV-Aufdach',
  'PV-Freifläche',
  'Wind',
  'Biomasse',
  'Wasserkraft',
];

export function formatChartTooltip(value, unit = null) {
  const options =
    value >= 10000
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : { minimumFractionDigits: 1, maximumFractionDigits: 1 };

  const formattedValue = value.toLocaleString('de-DE', options);

  const finalValue = formattedValue.endsWith(',0')
    ? formattedValue.slice(0, -2)
    : formattedValue;

  return `${finalValue}${unit ? ` ${unit}` : ''}`;
}

export function formatChartAxisLabel(value) {
  return value.toLocaleString('de-DE');
}
