<template>
  <div>
    <layerToggle
      :on-layer="aggregationLayerActive"
      :layer-state="layerState"
      :icon="'layers'"
      :grand-parent-id
      :parent-id
      :map-store
      @update-style="changeAggregationStyle($event)"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import LayerToggle from '@/apps/features/map/layer-panel/input-forms/LayerToggle.vue';

const props = defineProps({
  toggleOption: {
    type: Object,
    required: true,
  },
  layerState: {
    type: String,
    required: true,
  },
  parentId: {
    type: String || null,
    default: null,
  },
  grandParentId: {
    type: String || null,
    default: null,
  },
  mapStore: {
    type: Object,
    required: true,
  },
});

const aggregationLayerActive = computed(() => {
  const aggregationLevelActive = props.mapStore.aggregationLevel;
  const onAggregationLayers =
    props.toggleOption?.includeAggregationToggle?.onLayers;
  if (
    onAggregationLayers &&
    !onAggregationLayers.includes(aggregationLevelActive)
  ) {
    return 'null';
  }
  return props.mapStore.aggregationLevel;
});

function changeAggregationStyle(layerStyle) {
  props.mapStore.setAggregationStyle(layerStyle);
}
</script>

<style lang="scss" scoped></style>
