import { LAYER_KEY__BUILDINGS } from '@/apps/usecase-1/map/layer-config/buildings';
import {
  LAYER_KEY__POTENTIAL_PV_FREE,
  LAYER_KEY__POTENTIAL_WIND,
} from '../layer-config/potential';
import {
  LAYER_KEY__COUNTY,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
} from '../layer-config/geographical-level';
import { LAYER_KEY__ESTATE } from '../layer-config/estates';
import { LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS } from '../layer-config/infrastructure';
import {
  LAYER_KEY__SEARCH_AREA_STATIONS,
  LAYER_KEY__GRID_STATIONS_SUBSTATION,
  LAYER_KEY__HIGH_VOLTAGE_GRID,
} from '@/apps/features/map/layer-config/base-data';
import {
  LAYER_KEY__GENERATION_PV_FREE,
  LAYER_KEY__GENERATION_WIND,
  LAYER_KEY__GENERATION_HYDROPOWER,
  LAYER_KEY__GENERATION_BIOMASS,
} from '@/apps/usecase-1/map/layer-config/existing-power-plants';

const municipalityAndHigher = [
  LAYER_KEY__MUNICIPALITY,
  LAYER_KEY__COUNTY,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
];

export const layerPanelConfig = {
  sections: [
    {
      title: 'Basisdaten',
      groups: [
        {
          title: 'Gebäudestruktur',
          id: 'buildingStructure',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                  icon: 'maps_home_work',
                },
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'default',
            },
            {
              title: 'Sektor',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                  icon: 'maps_home_work',
                },
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'sector',
            },
            {
              title: 'Baualtersklasse',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                  icon: 'maps_home_work',
                },
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'constructionYearClass',
            },
          ],
        },
        {
          title: 'Flurstücke',
          id: 'estateStructure',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__ESTATE,
                },
              },
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Nachfrage',
      groups: [
        {
          title: 'Strom',
          id: 'power',
          items: [
            {
              title: 'Strombedarf',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                  icon: 'maps_home_work',
                },
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'electricity_demand_total_kwh_a',
            },
            {
              title: 'Stromlast',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                  icon: 'maps_home_work',
                },
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'electricity_demand_total_kw',
            },
          ],
        },
        {
          title: 'Wärme',
          id: 'heat',
          items: [
            {
              title: 'Wärmebedarf',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                  icon: 'maps_home_work',
                },
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'heat_demand_total_kwh_a',
            },
            {
              title: 'Wärmelast',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                  icon: 'maps_home_work',
                },
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'heat_demand_total_kw',
            },
            {
              title: 'Wärmeliniendichte',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                  icon: 'maps_home_work',
                },
              },
              layerState: 'heat_line_density_kwh_am',
            },
            {
              title: 'Energieträger',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                  icon: 'maps_home_work',
                },
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'heating_technology',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Erzeugung',
      groups: [
        {
          title: 'Bestand',
          id: 'generation',
          items: [
            {
              title: 'PV-Dach',
              id: 'generationPvRoof',
              items: [
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'generationPvRoofKw',
                },
              ],
            },
            {
              title: 'PV-Freifläche',
              id: 'generationPvFree',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_PV_FREE,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Betriebsstatus',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_PV_FREE,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_PV_FREE,
                      icon: 'pin_drop',
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'generationPvFreeKw',
                },
              ],
            },
            {
              title: 'Wind',
              id: 'generationWind',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_WIND,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Betriebsstatus',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_WIND,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_WIND,
                      icon: 'pin_drop',
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'generationWindKw',
                },
              ],
            },
            {
              title: 'Biomasse',
              id: 'generationBiomass',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_BIOMASS,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Betriebsstatus',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_BIOMASS,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_BIOMASS,
                      icon: 'pin_drop',
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'generationBiomassKw',
                },
              ],
            },
            {
              title: 'Wasserkraft',
              id: 'generationHydropower',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_HYDROPOWER,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Betriebsstatus',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_HYDROPOWER,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_HYDROPOWER,
                      icon: 'pin_drop',
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'generationHydropowerKw',
                },
              ],
            },
          ],
        },
        {
          title: 'Potenzial',
          id: 'potential',
          items: [
            {
              title: 'PV-Dach',
              id: 'potentialPvRoof',
              items: [
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeAggregationToggle: {
                      partial: false,
                    },
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__BUILDINGS,
                      icon: 'maps_home_work',
                    },
                  },
                  layerState: 'potentialPvRoofKw',
                },
              ],
            },
            {
              title: 'PV-Freifläche',
              id: 'potentialPvFree',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__POTENTIAL_PV_FREE,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__POTENTIAL_PV_FREE,
                      icon: 'shape_line',
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'potentialPvFreeKw',
                },
              ],
            },
            {
              title: 'Wind',
              id: 'potentialWind',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__POTENTIAL_WIND,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__POTENTIAL_WIND,
                      icon: 'shape_line',
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'potentialWindKw',
                },
              ],
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Infrastruktur',
      groups: [
        {
          title: 'Wärme ',
          id: 'InfrastructureHeat',
          items: [
            {
              title: 'Wärmeliniendichte',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS,
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Strom ',
          id: 'InfrastructurePower',
          items: [
            {
              title: 'Hochspannungsnetz',
              id: 'infrastructureGrid',
              items: [
                {
                  title: 'keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__HIGH_VOLTAGE_GRID,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Leitungstyp',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__HIGH_VOLTAGE_GRID,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'ltype',
                },
              ],
            },
            {
              title: 'Umspannwerke',
              id: 'infrastructureGridStation',
              items: [
                {
                  itemClass: 'toggle',
                  title: 'Keine Einfärbung',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GRID_STATIONS_SUBSTATION,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'default',
                },
              ],
            },
            {
              title: 'Suchraum Umspannwerk',
              id: 'infrastructureGridStationSearchArea',
              items: [
                {
                  itemClass: 'toggle',
                  title: 'keine Einfärbung',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__SEARCH_AREA_STATIONS,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'default',
                },
                {
                  itemClass: 'toggle',
                  title: 'Status',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__SEARCH_AREA_STATIONS,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'construction_category',
                },
                {
                  itemClass: 'toggle',
                  title: 'Zeitraum',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__SEARCH_AREA_STATIONS,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'time_period',
                },
              ],
            },
          ],
        },
      ],
      show: true,
    },
  ],
};
