<template>
  <VuetifyDataTable
    v-if="scenarios.length > 0"
    :item-data="sortedScenariosByCreatedAt"
    :headers="ScenarioListHeaders"
    :show-expand="false"
    :header-small="true"
    :show-background="false"
    class="rounded-none shadow-none"
  >
    <template #title="{ item }">
      <ToolTip :tooltip-text="getTooltipText(item.title, 20)">
        {{ truncateText(item.title, 20) }}
      </ToolTip>
    </template>
    <template #assumptions_scenario_title="{ item }">
      <ToolTip
        :tooltip-text="getTooltipText(item.assumptions_scenario_title, 35)"
      >
        {{ truncateText(item.assumptions_scenario_title, 35) }}
      </ToolTip>
    </template>
    <template #demographic_development_yearly="{ item }">
      {{ item.demographic_development_yearly * 100 }} % p.a.
    </template>
    <template #economic_development_yearly="{ item }">
      {{ item.economic_development_yearly * 100 }} % p.a.
    </template>
    <template #is_default="{ item }">
      <div class="flex justify-center items-center">
        <VRadio
          v-if="!defaultScenarioLoading"
          :disabled="item.progress !== 0 || !userStore.uc1CanChangeScenario"
          :model-value="item.id === defaultScenarioId"
          @click="setAsDefaultScenario(item)"
        />
        <VProgressCircular
          v-else
          class="text-title-color1"
          size="10"
          width="2"
          indeterminate
        />
      </div>
    </template>
    <template #created_at="{ item }">
      <div>
        {{ new Date(item.created_at).toLocaleDateString('de-DE') }}
      </div>
    </template>
    <template #progress="{ item }">
      <div class="flex justify-center items-center">
        <TaskProgress
          :item
          :status-map="{
            SUCCESS: 0,
            ERROR: 1,
            PROGRESS: 2,
          }"
        />
      </div>
    </template>
    <template #functions="{ item }">
      <div class="flex justify-start items-center gap-4 min-h-8">
        <DeleteDialog
          v-if="item.progress !== 2"
          :id="item.id"
          :name-deleted-item="item.title"
          :dto="ScenarioDto"
          :disabled="item.id === defaultScenarioId"
          @instance-removed="emit('instance-removed')"
        >
          <IconWrapper
            v-if="userStore.uc1CanDeleteScenario"
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="delete_forever"
            class="cursor-pointer"
            type="round"
          />
        </DeleteDialog>
        <template v-if="item.progress === 0">
          <IconWrapper
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="map"
            class="cursor-pointer"
            type="round"
            @click="
              router.push({
                name: 'mapView1',
                params: { projectId: props.projectId, scenarioId: item.id },
              })
            "
          />
        </template>
      </div>
    </template>
  </VuetifyDataTable>
</template>

<script setup>
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import { ScenarioDto } from '@/apps/usecase-1/scenario/scenario-dto';
import { computed } from 'vue';
import { headers as ScenarioListHeaders } from '@/apps/usecase-1/scenario/scenario-list-headers';
import { useRouter } from 'vue-router';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { useUsers } from '@/apps/auth/users-store';
import TaskProgress from '@/apps/features/task-progress/TaskProgress.vue';

const router = useRouter();
const userStore = useUsers();

const emit = defineEmits(['set-default-scenario', 'instance-removed']);
const props = defineProps({
  scenarios: {
    type: Array,
    default: () => [],
  },
  defaultScenarioId: {
    type: Number,
    default: null,
  },
  defaultScenarioLoading: {
    type: Boolean,
    default: false,
  },
  projectId: {
    type: Number,
    required: true,
  },
});

const sortedScenariosByCreatedAt = computed(() => {
  return [...props.scenarios].sort(
    (a, b) => new Date(a.created_at) - new Date(b.created_at),
  );
});

// Set default scenario
function setAsDefaultScenario(item) {
  emit('set-default-scenario', item.id);
}

// Utility function to truncate text with a given length
function truncateText(text, maxLength) {
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}

// Utility function to provide tooltip text if needed
function getTooltipText(text, maxLength) {
  return text.length > maxLength ? text : null;
}
</script>

<style lang="scss" scoped>
:deep(.v-selection-control) {
  display: flex;
  justify-content: center;
}
</style>
