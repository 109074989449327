import { fetchData } from './utils';
import { mergeFeatureDataAndLayerConfig } from '@/apps/features/map/info-panel/panel-data/panel-data-calculator';
import axios from '@/utils/axiosHelper';

/**
 * A class to manage the operations related to a simple panel for map layers.
 * It handles metadata requests, feature data retrieval, and panel data calculations.
 */
export class SimplePanel {
  metaData = null;

  /**
   * Constructor to initialize the SimplePanel.
   * @param {string} url - The base URL for API requests.
   * @param {string[]} layerIds - An array of layer IDs that the panel is associated with.
   * @param {string} [pkKey='id'] - The primary key used to identify features (default is 'id').
   */
  constructor(url, layerIds, pkKey = 'id') {
    this.pkKey = pkKey;
    this.url = url;
    this.layerIds = layerIds;
  }
  /**
   * Requests feature data for a given feature and updates metadata for the layer if needed.
   * @param {Object} feature - The feature object from the map, containing properties and layer information.
   * @param {Object} mapStore - The map store that contains metadata and layer configurations.
   * @returns {Promise<Object|null>} The feature data object or null if the request fails.
   */
  async requestFeatureData(feature, mapStore) {
    const featureId = feature.properties[this.pkKey];
    const layerId = feature.layer.id;

    if (!mapStore.metaDataLayers[layerId]) {
      const metaData = {};
      const options = await axios({
        url: this.url,
        method: 'OPTIONS',
      });

      const metaDataResponse = options.data.actions.POST;

      for (const [key, value] of Object.entries(metaDataResponse)) {
        if (value.type === 'nested object') {
          for (const [keyNested, valueNested] of Object.entries(
            value.children,
          )) {
            metaData[keyNested] = valueNested;
          }
        } else {
          metaData[key] = value;
        }
      }
      mapStore.setLayerMetaData(layerId, metaData);
    }
    const resp = await fetchData(`${this.url}${featureId}/`, mapStore);

    if (resp === null) return resp;

    const featureData = resp.data;
    featureData.layerId = layerId;
    return featureData;
  }
  /**
   * Calculates the panel data for a given feature based on feature data, scenario year, and map store configuration.
   * @param {Object} featureData - The feature data object containing information about the feature.
   * @param {number|null} newScenarioYear - The scenario year (or null) for context-specific calculations.
   * @param {Object} mapStore - The map store containing layer configurations and metadata.
   * @returns {Object} The processed panel data.
   */
  calculatePanelData(featureData, newScenarioYear, mapStore) {
    const panelConfig = mapStore.layerConfigs[featureData.layerId].sidebar;
    const metaData = mapStore.metaDataLayers[featureData.layerId];
    return mergeFeatureDataAndLayerConfig(
      featureData,
      null,
      null,
      panelConfig,
      null,
      metaData,
    );
  }
}
