import { isNullOrUndefined } from './generalUtils';

export class RESTRequestValidator {
  constructor(fields) {
    this.fields = fields;
    this.validationErrors = [];
  }

  isNotDefined(value) {
    return value === null || typeof value === 'undefined' || value === '';
  }

  isTooLong(value, maxLength) {
    return value !== null && value.length > maxLength;
  }

  isBelowMinimum(value, minimum) {
    return value < minimum;
  }

  isAboveMaximum(value, maximum) {
    return value > maximum;
  }

  isWrongType(value, type) {
    if (type === 'integer' || type === 'float') {
      return typeof value !== 'number';
    }
    return typeof value !== type;
  }

  get isValid() {
    let valid = true;
    this.validationErrors = [];

    for (const field of Object.values(this.fields)) {
      if (
        !field.required ||
        field.read_only ||
        field.type === 'field' ||
        (field.type === 'list' && Object.values(field.value).some((v) => v))
      )
        continue;

      const value = field.value;

      if (this.isWrongType(value, field.type)) valid = false;
      if (this.isNotDefined(value)) {
        valid = false;
        this.validationErrors.push(`${field.label} muss angegeben werden`);
      }
      if (field.max_length && this.isTooLong(value, field.max_length)) {
        valid = false;
        this.validationErrors.push(
          `${field.label} länger als ${field.max_length} Zeichen`,
        );
      }
      if (
        !isNullOrUndefined(field.min_value) &&
        this.isBelowMinimum(value, field.min_value)
      ) {
        valid = false;
        this.validationErrors.push(`${field.label} unter ${field.min_value}`);
      }
      if (
        !isNullOrUndefined(field.max_value) &&
        this.isAboveMaximum(value, field.max_value)
      ) {
        valid = false;
        this.validationErrors.push(`${field.label} über ${field.max_value}`);
      }
    }
    return valid;
  }
}
