<template>
  <VSnackbar
    v-model="toast.show"
    :timeout="toast.timeout"
    :color="toast.color"
    :value="true"
    :max-width="toast.maxWidth"
    location-strategy="connected"
    rounded="md"
    content-class="bottom-[48px]"
  >
    <div v-if="toast.title">
      <h4 class="text-white mb-2">{{ toast.title }}</h4>
    </div>
    <div
      class="subtitle-2 flex flex-col gap-1.5"
      :class="toast.textColor ? 'text-' + toast.textColor : 'text-white'"
    >
      <div
        v-for="(paragraph, index) in toastMessage"
        :key="index"
        class="text-center pt-0.5"
      >
        {{ paragraph }}
      </div>
    </div>
    <template v-if="toast.timeout === -1 && toast.clearable" #actions>
      <VBtn color="white" text @click="toast.show = false">
        <VIcon icon="mdi-close" data-test="close-toast" />
      </VBtn>
    </template>
  </VSnackbar>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useToastStore } from '@/stores/toast-store';

const { toast } = storeToRefs(useToastStore());

const toastMessage = computed(() =>
  toast.value.message.split('\n\n').map((paragraph) => paragraph.trim()),
);
</script>
