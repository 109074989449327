<template>
  <div ref="activator" class="relative">
    <div
      class="agg-levels-toggle"
      :class="{ open: open }"
      @click.stop="open = true"
    >
      <IconWrapper
        :size="16"
        :icon="aggregationLayerIcon"
        fill="text-title-neutral"
      />
      <h6 class="text-title-neutral">
        {{ selectedAggLevelName }}
      </h6>
      <IconWrapper :size="16" icon="more_horiz" fill="text-neutral" />
    </div>

    <!--  agg levels-->
    <Teleport to="body">
      <transition name="fade">
        <div
          v-if="open"
          ref="aggContainer"
          :style="{ top: `${top}px`, left: `${calcXPos}px` }"
          class="absolute standard-elevation-0-dark bg-white rounded-[6px] z-[100]"
        >
          <h6 class="text-title-neutral px-2.5 py-2 select-none">
            Aggregationsebenen
          </h6>

          <div
            v-for="(level, index) in aggLevels"
            :key="level.layer"
            :class="{ 'border-t': index === 0 }"
            class="flex caption-1 cursor-pointer hover:bg-active-area text-neutral pl-2.5 pr-1.5 py-1 border-b border-l-line-disabled-neutral justify-between items-center w-full"
            @click="mapStore.setAggregationLevel(level.layer)"
          >
            {{ level.name }}

            <VRadioGroup v-model="aggLevelComputed" class="max-w-fit">
              <VRadio :value="level.layer" />
            </VRadioGroup>
          </div>

          <div class="flex flex-col gap-2.5 p-2.5 select-none">
            <ToolTip
              class="w-full justify-between cursor-pointer"
              custom-content-class="p-1.5"
              @click="resetAggregation"
            >
              <h6 class="text-title-neutral">Darstellung</h6>
              <IconWrapper :size="16" icon="border_style" fill="text-color1" />
              <template #text>
                <div class="subtitle-4 text-neutral">
                  {{
                    'Klicken, um Karte auf die\neinfache Anzeige der\n' +
                    'Gebietsgrenzen zu setzen.'
                  }}
                </div>
              </template>
            </ToolTip>

            <div
              class="flex justify-items-start items-center gap-1 bg-title-neutral text-white rounded-[4px] p-1.5"
            >
              <IconWrapper :size="16" :icon="aggregationLayerIcon" />
              <div class="caption-1 pt-0.5">
                {{ selectedAggLevelName || 'Gebietsgrenzen' }}
              </div>
            </div>
          </div>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { onClickOutside, onKeyStroke, useElementBounding } from '@vueuse/core';
import { useMapStore } from '@/apps/usecase-1/map/map-store';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import IconBoltBox from '@/assets/icons/custom/misc/IconBoltBox.vue';

const mapStore = useMapStore();

const props = defineProps({
  aggLevels: {
    type: Array,
    required: true,
  },
});

const aggLevelComputed = computed({
  get: () => mapStore.aggregationLevel,
  set: (value) => {
    mapStore.setAggregationLevel(value);
  },
});
// find name to selected layer
const selectedAggLevelName = computed(() => {
  return props.aggLevels.find((e) => e.layer === aggLevelComputed.value).name;
});

// aggregation layer logic
const aggregationLayer = computed(() => mapStore.aggregationLevel);
const aggregationLayerIcon = computed(() => {
  return aggregationLayer.value ? IconBoltBox : 'border_style';
});

function resetAggregation() {
  mapStore.setAggregationStyle('default');
  mapStore.setAggregationLevel(aggLevelComputed.value);
}

// layout logic
const open = ref(false);
const activator = ref(null);
const aggContainer = ref(null);

const { width: aggContainerWidth } = useElementBounding(aggContainer);
const { top, right } = useElementBounding(activator);
const calcXPos = computed(() => {
  return aggContainerWidth.value && right.value - aggContainerWidth.value;
});

// utils
onClickOutside(aggContainer, (e) => {
  e.stopPropagation();
  open.value = false;
});

onKeyStroke('Escape', () => {
  open.value = false;
});
</script>

<style lang="scss" scoped>
@use '@/assets/styles';

.agg-levels-toggle {
  @apply flex items-center gap-1 border border-line-disabled-neutral
  rounded-[4px] p-1.5 transition-all cursor-pointer;
  &.open {
    @apply border-white;
  }

  &:hover {
    @apply bg-active-area;
    @extend .standard-elevation-0-dark;
  }
}

.selected-agg-level {
  @apply rounded-[2px] px-1 py-0.5 flex items-center gap-0.5 text-white
  bg-color1 select-none transition-all;
  &.inactive {
    @apply bg-button-primary-disabled-color1;
  }
}
</style>
