<template>
  <div class="flex justify-between items-center gap-5">
    <!-- scenario selection -->
    <div class="grid grid-flow-col auto-cols-fr gap-2.5 w-full">
      <DropDownMultiple
        v-model="scenariosSelected"
        label="Auswahl Szenarien"
        item-type-all="Szenarien"
        all-selected-label="Alle"
        class="w-[312px]"
        :items="scenarios"
        :ensure-one-selected="true"
        placeholder="Bitte auswählen"
        :init-select-all="true"
      />
    </div>
  </div>
  <ExpansionPanel
    :show-circle-number="false"
    :panels="['Nachfrage Strom', 'Nachfrage Wärme']"
    :multiple="true"
    :all-panels-open="true"
  >
    <template #panel1>
      <VRadioGroup v-model="demandChartTheme">
        <div class="flex justify-start ml-5 mb-3">
          <VRadio
            value="demandDriver"
            @click="demandChartTheme = 'demandDriver'"
          >
            <template #label>
              <h5 class="ml-1 mr-2 text-title-neutral">Verbrauchstreiber</h5>
            </template>
          </VRadio>
          <VRadio value="sector" @click="demandChartTheme = 'sector'">
            <template #label>
              <h5 class="ml-1 text-title-neutral">Sektor</h5>
            </template>
          </VRadio>
        </div>
      </VRadioGroup>
      <div class="chart-container">
        <BarChartBasic
          v-if="demandChartTheme === 'demandDriver'"
          legend-bottom="-5px"
          :stacks="electricityLoadByDriversMwh"
          :title="`Strombedarf im Zieljahr ${resultStore.activeProject.target_year}`"
          :x-axis-data="scenariosSelected"
          :y-axis-name="'MWh(el)/a'"
          :y-axis-max="getAxisMax('electricity_demand_kwh_a')"
          chart-unit="MWh(el)/a"
          class="min-h-[300px] shadow-none"
          bar-width="42px"
          grid-left="18px"
          :y-axis-label-custom-fixed-width="75"
          custom-title-color="#000099"
          title-top="0"
          :tool-tip-show-totals="true"
        />
        <BarChartBasic
          v-if="demandChartTheme === 'demandDriver'"
          legend-bottom="-5px"
          :stacks="electricityLoadByDriversMw"
          :title="`Stromlast im Zieljahr ${resultStore.activeProject.target_year}`"
          :x-axis-data="scenariosSelected"
          :y-axis-name="'MW(el)'"
          :y-axis-max="getAxisMax('electricity_demand_kw')"
          chart-unit="MW(el)"
          class="min-h-[300px] shadow-none"
          bar-width="42px"
          grid-left="18px"
          :y-axis-label-custom-fixed-width="75"
          custom-title-color="#000099"
          title-top="0"
          :tool-tip-show-totals="true"
        />
        <BarChartBasic
          v-if="demandChartTheme === 'sector'"
          legend-bottom="-5px"
          :stacks="electricityLoadBySectorMwh"
          :title="`Strombedarf im Zieljahr ${resultStore.activeProject.target_year}`"
          :x-axis-data="scenariosSelected"
          :y-axis-name="'MWh(el)/a'"
          :y-axis-max="getAxisMax('electricity_demand_kwh_a')"
          :legend-data="SECTOR_LEGENDS_ORDERED"
          chart-unit="MWh(el)/a"
          class="min-h-[300px] shadow-none"
          bar-width="42px"
          grid-left="18px"
          :y-axis-label-custom-fixed-width="75"
          custom-title-color="#000099"
          title-top="0"
          :tool-tip-show-totals="true"
        />
        <BarChartBasic
          v-if="demandChartTheme === 'sector'"
          legend-bottom="-5px"
          :stacks="electricityLoadBySectorMw"
          :title="`Stromlast im Zieljahr ${resultStore.activeProject.target_year}`"
          :x-axis-data="scenariosSelected"
          :y-axis-name="'MW(el)'"
          :y-axis-max="getAxisMax('electricity_demand_kw')"
          :legend-data="SECTOR_LEGENDS_ORDERED"
          chart-unit="MW(el)"
          class="min-h-[300px] shadow-none"
          bar-width="42px"
          grid-left="18px"
          :y-axis-label-custom-fixed-width="75"
          custom-title-color="#000099"
          title-top="0"
          :tool-tip-show-totals="true"
        />
      </div>
    </template>
    <template #panel2>
      <div class="chart-container">
        <BarChartBasic
          legend-bottom="-5px"
          :stacks="heatLoadBySectorMwh"
          :title="`Wärmebedarf im Zieljahr ${resultStore.activeProject.target_year}`"
          :x-axis-data="scenariosSelected"
          :y-axis-name="'MWh(th)/a'"
          :y-axis-max="getAxisMax('heat_demand_kwh_a')"
          :legend-data="SECTOR_NO_MOBILITY_LEGENDS_ORDERED"
          chart-unit="MW(th)"
          class="min-h-[300px] shadow-none"
          bar-width="42px"
          grid-left="18px"
          :y-axis-label-custom-fixed-width="75"
          custom-title-color="#000099"
          title-top="0"
          :tool-tip-show-totals="true"
        />
        <BarChartBasic
          legend-bottom="-5px"
          :stacks="heatLoadBySectorMw"
          :title="`Wärmelast im Zieljahr ${resultStore.activeProject.target_year}`"
          :x-axis-data="scenariosSelected"
          :y-axis-name="'MW(th)'"
          :y-axis-max="getAxisMax('heat_demand_kw')"
          :legend-data="SECTOR_NO_MOBILITY_LEGENDS_ORDERED"
          chart-unit="MW(th)"
          class="min-h-[300px] shadow-none"
          bar-width="42px"
          grid-left="18px"
          :y-axis-label-custom-fixed-width="75"
          custom-title-color="#000099"
          title-top="0"
          :tool-tip-show-totals="true"
        />
      </div>
    </template>
  </ExpansionPanel>
  <SourcesItem item="scenario-comparison" />
</template>

<script setup>
import SourcesItem from '@/components/sourcesItem/SourcesItem.vue';
import DropDownMultiple from '@/components/dropDownMultiple/DropDownMultiple.vue';
import ExpansionPanel from '@/components/ExpansionPanel/ExpansionPanel.vue';
import { useProjectResult } from '@/apps/usecase-1/projects/project-result-detail/project-result-store';
import { computed, ref } from 'vue';
import BarChartBasic from '@/components/charts/BarChartBasic.vue';
import {
  LIND_GREEN_60,
  POWER_DEMAND,
  SECTOR_BY_ENUM,
} from '@/configs/color-constants';
import { sum } from 'lodash';
import {
  SECTOR_LEGENDS_ORDERED,
  SECTOR_NO_MOBILITY_LEGENDS_ORDERED,
} from '@/utils/chart-utils';

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: String,
    required: true,
  },
});

const resultStore = useProjectResult();
await resultStore.fetchProjectScenarioComparison(props.projectId);

const scenarios = computed(() =>
  resultStore.projectScenarioComparison.target_year_by_drivers.map(
    (e) => e.scenario_title,
  ),
);
const scenariosSelected = ref(scenarios.value || []);
const demandChartTheme = ref('demandDriver');

function getMarkLineForBaseYear(yAxis) {
  return {
    symbol: 'none',
    silent: true,
    animation: false,
    data: [
      {
        yAxis,
        label: {
          show: true,
          formatter: 'Status Quo',
          fontFamily: 'mark',
          fontWeight: 400,
          fontSize: 12,
          offset: [-70, -10],
        },
        lineStyle: {
          color: LIND_GREEN_60,
          width: 1,
          type: 'dashed',
        },
      },
    ],
  };
}

// --------- Demand driver charts
const generateElectricityDriverChartData = (unit) => {
  const targetYearTotal =
    resultStore.projectScenarioComparison.target_year_total;
  const data = resultStore.projectScenarioComparison;
  const values = data.target_year_by_drivers.filter((e) =>
    scenariosSelected.value.includes(e.scenario_title),
  );

  const chartData = {
    [`electricity_demand_base_${unit}`]: {
      name: 'Konventionell',
      itemStyle: { color: POWER_DEMAND.CONVENTIONAL },
      data: [],
      markLine: getMarkLineForBaseYear(
        data.base_year_sum[`electricity_demand_${unit}`] / 1000,
      ),
    },
    [`electricity_demand_bev_${unit}`]: {
      name: 'Elektrifizierung E-Mobilität',
      itemStyle: { color: POWER_DEMAND.ELECTRIFICATION_MOBILITY },
      data: [],
    },
    [`electricity_demand_heat_pump_${unit}`]: {
      name: 'Elektrifizierung Wärme Einzelversorgung',
      itemStyle: { color: POWER_DEMAND.ELECTRIFICATION_HEAT_SINGLE },
      data: [],
    },
    [`electricity_demand_heat_network_${unit}`]: {
      name: 'Elektrifizierung Wärmenetz',
      itemStyle: { color: POWER_DEMAND.ELECTRIFICATION_HEAT_NETWORK },
      data: [],
    },
  };
  for (const value of values) {
    for (const [driver, series] of Object.entries(chartData)) {
      series.data.push(value[driver] / 1000);
    }
  }
  return Object.values(chartData);
};

const electricityLoadByDriversMwh = computed(() =>
  generateElectricityDriverChartData('kwh_a'),
);
const electricityLoadByDriversMw = computed(() =>
  generateElectricityDriverChartData('kw'),
);

// ---------- Sector chart data
function processYearlySectorData(valueFieldName) {
  const targetYearBySector =
    resultStore.projectScenarioComparison.target_year_by_sector;
  const baseYearSum = resultStore.projectScenarioComparison.base_year_sum;
  const sectors = Object.keys(SECTOR_BY_ENUM);
  const scenarios = scenariosSelected.value;

  const processedData = [];

  for (const sector of sectors) {
    const sectorId = parseInt(sector);
    const sectorLabel = SECTOR_BY_ENUM[sector].label;
    const sectorColor = SECTOR_BY_ENUM[sector].color;

    const sectorData = scenarios.map((scenario) => {
      const filteredData = targetYearBySector
        .filter((e) => e.scenario_title === scenario && e.sector === sectorId)
        .map((e) => e[valueFieldName] / 1000);
      return sum(filteredData);
    });
    processedData.push({
      name: sectorLabel,
      itemStyle: { color: sectorColor },
      data: sectorData,
    });
  }
  processedData[0].markLine = getMarkLineForBaseYear(
    baseYearSum[valueFieldName] / 1000,
  );
  return processedData;
}

const electricityLoadBySectorMwh = computed(() =>
  processYearlySectorData('electricity_demand_kwh_a'),
);
const electricityLoadBySectorMw = computed(() =>
  processYearlySectorData('electricity_demand_kw'),
);
const heatLoadBySectorMwh = computed(() =>
  processYearlySectorData('heat_demand_kwh_a'),
);
const heatLoadBySectorMw = computed(() =>
  processYearlySectorData('heat_demand_kw'),
);

function getAxisMax(valueFieldName) {
  const targetYearTotal =
    resultStore.projectScenarioComparison.target_year_total;
  const baseYearSum = resultStore.projectScenarioComparison.base_year_sum;
  const compareMax = Math.max(
    ...targetYearTotal.map((e) => e[valueFieldName] / 1000),
  );
  const max = Math.max(baseYearSum[valueFieldName] / 1000, compareMax);
  return Math.ceil(max / 10) * 10;
}
</script>

<style lang="scss" scoped>
.chart-container {
  @apply rounded-[8px] p-2.5 flex flex-col gap-6;
  h3,
  h5 {
    @apply text-title-color1;
  }
}
</style>
