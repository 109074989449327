<template>
  <div class="cursor-pointer">
    <img
      v-show="imageLoaded"
      class="overflow-hidden rounded-[8px]"
      :style="{ width: `${avatarSize}px`, height: `${avatarSize}px` }"
      :src="avatarURL"
      alt="avatar"
      @load="imageLoaded = true"
      @error="imageLoaded = false"
    />
    <IconWrapper
      v-if="!imageLoaded"
      :size="avatarSize"
      fill="text-core-color2"
      :icon="IconInfrastrukturKundInnen"
    />
  </div>
</template>

<script setup>
import { useUsers } from '@/apps/auth/users-store';
import IconInfrastrukturKundInnen from '@/assets/icons/custom/misc/IconInfrastrukturKundInnen.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { axiosInstance } from '@/utils/axiosHelper';
import { computed, ref } from 'vue';

const userStore = useUsers();
const imageLoaded = ref(false);

const avatarURL = computed(
  () =>
    userStore.profile?.avatar &&
    `${axiosInstance.defaults.baseURL}${userStore.profile.avatar}/`,
);

const props = defineProps({
  avatarSize: {
    type: Number,
    default: 44,
  },
});
</script>
