<template>
  <div class="auth-layout flex flex-col justify-center">
    <Transition name="fade" mode="out-in">
      <slot />
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.auth-layout {
  min-height: calc(100vh - 98px);
}
</style>
