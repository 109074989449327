export class WeightingValuesDto {
  static BASE_URL = '/api/usecase-2/weighting-values/';

  id = null;
  minimum_area_ha = null;
  weighting_minimum_area = 1;
  form_factor_width_m = null;
  area_share_bbox = null;
  weighting_form_factors = 1;
  area_share_eligible = null;
  weighting_share_eligible = 1;
  max_specific_investment_eur_kw = null;
  weighting_specific_investment = 1;
  // weighting_availability_grid_station = 1;
  area_share_field = 1;
  weighting_share_field = 1;
}
