<template>
  <h5 class="text-title-neutral">Flurstück Suche</h5>
  <InputSearch
    v-model="district"
    :items="districtItems"
    placeholder="Gemarkung"
    :search-keys="['name']"
    item-value="name"
  />
  <InputEl
    v-model="estate"
    label="Flur"
    placeholder="Bitte eingeben"
    data-test="state-name"
  />
  <InputEl
    v-model="numerator"
    label="Zähler"
    placeholder="Bitte eingeben"
    data-test="numerator"
  />
  <InputEl
    v-model="denominator"
    label="Nenner"
    placeholder="Bitte eingeben"
    data-test="denominator"
  />
  <div class="grid grid-cols-2 gap-2.5">
    <ButtonEl
      :full-width="true"
      color="color2"
      text="Abbrechen"
      @click="abort"
    />

    <ButtonEl :full-width="true" text="Suchen" class="pr-4" @click="search" />
  </div>
</template>

<script setup>
import { ref, toRefs } from 'vue';
import { draw } from '@/apps/features/map/draw/draw';
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import InputSearch from '@/components/inputSearch/InputSearch.vue';
import axios from '@/utils/axiosHelper';
import { useToastStore } from '@/stores/toast-store';
import { flyTo, getMap, setMarker } from '@/mapbox/main';

const { showToast } = useToastStore();

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const nameData = await axios({
  method: 'GET',
  url: `/api/basemodule/gemarkung-names/`,
});

const district = ref(null);
const districtItems = ref(nameData.data.district_names);
const estate = ref();
const numerator = ref();
const denominator = ref();

async function search() {
  const estateData = await axios({
    method: 'GET',
    url: '/api/basemodule/estate-search/',
    params: {
      district: district.value,
      parcel_nr: estate.value,
      parcel_numerator: numerator.value,
      parcel_denominator: denominator.value,
    },
  });
  if (estateData.data.id === null) {
    showToast({
      color: 'warning',
      message:
        'Es konnte kein Flurstück gefunden werden,' +
        ' bitte überprüfen Sie ihre Eingaben',
    });
  } else {
    try {
      const map = getMap();
      const config = {
        zoom: 16.5,
        lng: estateData.data.centroid[0],
        lat: estateData.data.centroid[1],
      };
      flyTo(config, map);
      setMarker(estateData.data.centroid, map);
      abort();
    } catch (e) {
      throw Error('coordinates not valid, check data');
    }
  }
}

function abort() {
  props.mapStore.resetMapAction(draw);
}
</script>
