<template>
  <VChart
    :option="chartOptions"
    :style="{ 'min-height': '0px', 'min-width': '0px' }"
    autoresize
    class="h-full w-full standard-elevation-0-dark"
  />
</template>

<script setup>
import { computed } from 'vue';
import { formatChartAxisLabel, formatChartTooltip } from '@/utils/chart-utils';

const props = defineProps({
  stacks: {
    type: Array,
    default: () => [],
  },
  yAxisName: {
    type: String,
    default: '',
  },
  xAxisData: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: '',
  },
  titleLeft: {
    type: String,
    default: 'center',
  },
  gridTop: {
    type: String,
    default: '13%',
  },
  gridLeft: {
    type: String,
    default: '3%',
  },
  gridRight: {
    type: String,
    default: '3%',
  },
  gridBottom: {
    type: String,
    default: '6%',
  },
  titleTop: {
    type: String,
    default: '12px',
  },
  showLegend: {
    type: Boolean,
    default: true,
  },
  showYAxisLabel: {
    type: Boolean,
    default: true,
  },
  yAxisMax: {
    type: Number || null,
    default: null,
  },
  barWidth: {
    type: String,
    default: '75px',
  },
  legendBottom: {
    type: String,
    default: '4px',
  },
  yAxisLabelCustomFixedWidth: {
    type: Number,
    default: null,
  },
  customTitleColor: {
    type: String,
    default: '#7E7570',
  },
  chartUnit: {
    type: String,
    default: null,
  },
  legendData: {
    type: Array,
    default: () => [],
  },
  toolTipShowTotals: {
    type: Boolean,
    default: false,
  },
});

const chartOptions = computed(() => ({
  backgroundColor: '#FFF',
  title: {
    text: props.title,
    left: props.titleLeft,
    top: props.titleTop,
    textStyle: {
      fontFamily: 'mark',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 20,
      color: props.customTitleColor,
    },
  },
  grid: {
    top: props.gridTop,
    left: props.gridLeft,
    right: props.gridRight,
    bottom: props.gridBottom,
    containLabel: true,
  },
  legend: {
    ...(props.legendData.length && { data: props.legendData }),
    show: props.showLegend,
    bottom: props.legendBottom,
    itemWidth: 12,
    itemHeight: 12,
    left: 'center',
    selectedMode: false,
    textStyle: {
      fontFamily: 'enbw',
      fontWeight: 600,
      fontSize: 12,
      color: '#322A26',
    },
    icon: 'rect',
    itemStyle: {
      borderWidth: 0,
    },
    //   make a formatter that allows no line breaks
    formatter: (value) => {
      return value.split('\n').join(' ');
    },
  },
  tooltip: {
    trigger: props.toolTipShowTotals ? 'axis' : 'item',
    axisPointer: {
      type: 'shadow',
    },
    formatter: (params) => {
      if (!props.toolTipShowTotals) {
        const param = params; // For single item case
        return `
      <div style='display: flex; align-items: center;'>
        <span style='display:inline-block; border-radius: 0; width:12px; height:12px; background-color:${param.color}; margin-right:5px;'></span>
        <span>${param.seriesName}: ${formatChartTooltip(param.value, props.chartUnit)}</span>
      </div>`;
      }

      // For the case where toolTipShowTotals is true
      let tooltipContent = '';
      let totalValue = 0;

      const validParams = params.filter(
        (param) => param.value && !isNaN(param.value) && param.value !== 0,
      );

      validParams.forEach((param) => {
        tooltipContent += `
      <div style='display: flex; align-items: center;'>
        <span style='display: inline-block; border-radius: 0; width: 12px; height: 12px; background-color: ${param.color}; margin-right: 5px;'></span>
        <div style='display: flex; justify-content: space-between; width: 100%; gap: 10px'>
          <span>${param.seriesName}:</span>
          <span style='margin-left: auto'>${formatChartTooltip(param.value, props.chartUnit)}</span>
        </div>
      </div>`;
        totalValue += param.value;
      });

      if (validParams.length > 1) {
        tooltipContent += `
      <div style='display: flex; justify-content: space-between; border-top: 1px solid #C3BCB6; margin-top: 5px; padding-top: 5px;'>
        <span>Gesamt:</span>
        <span style='margin-left: auto'>${formatChartTooltip(totalValue, props.chartUnit)}</span>
      </div>`;
      }

      return `<div>${tooltipContent}</div>`;
    },

    backgroundColor: 'rgba(255, 255, 255)',
    borderWidth: 0,
    borderRadius: 0,
    boxShadow: '0 0.5px 8px 0 rgba(207, 216, 220, 0.50)',
    padding: 8,
    textStyle: {
      fontSize: 12,
      color: 'black',
      fontWeight: 500,
      fontFamily: 'mark',
      align: 'center',
      style: 'normal',
    },
  },
  yAxis: {
    type: 'value',
    name: props.yAxisName,
    nameGap: 16,
    ...(props.yAxisMax && { max: props.yAxisMax }),
    nameTextStyle: {
      fontFamily: 'mark',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 16,
      color: '#322A26',
      align: 'right',
    },
    axisLabel: {
      show: props.showYAxisLabel,
      fontFamily: 'mark',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 16,
      color: '#322A26',
      formatter: (value) => {
        if (props.yAxisLabelCustomFixedWidth) {
          return `{fixedWidth|${formatChartAxisLabel(value)}}`;
        }
        return formatChartAxisLabel(value);
      },
      rich: {
        fixedWidth: {
          width: props.yAxisLabelCustomFixedWidth,
          fontFamily: 'mark',
          fontWeight: 500,
          fontSize: 14,
          lineHeight: 16,
          color: '#322A26',
        },
      },
    },
  },
  xAxis: {
    type: 'category',
    data: props.xAxisData,
    axisLabel: {
      interval: 0,
      fontFamily: 'mark',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 16,
      color: '#322A26',
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#C3BCB6',
        width: 3,
      },
    },
  },
  series: props.stacks.map((stack) => {
    return {
      ...stack,
      type: 'bar',
      stack: 'Total',
      barWidth: props.barWidth,
      label: {
        show: false,
      },
    };
  }),
}));
</script>
