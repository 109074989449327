<template>
  <h5 class="text-title-neutral">Projektcluster erstellen</h5>

  <InputEl
    v-model="name"
    label="Bezeichnung"
    placeholder="Bitte eingeben"
    data-test="location-name"
  />

  <div class="standard-container rounded-sm p-2.5">
    <TabHeaderCards v-model="activeTab" :tabs />

    <template v-if="activeTab === 0">
      <h5 class="text-title-color1 text-center">
        Bitte klicken Sie auf Projekte, um sie auszuwählen.
      </h5>
      <div class="flex flex-col gap-2.5">
        <div
          class="text-neutral flex flex-col items-center gap-0.5 standard-elevation-0-dark rounded-xs p-1.5"
        >
          <CheckboxEl v-model="considerBattery" class="ml-1">
            <div class="body-3 pt-[2px] ml-1">
              mit Berücksichtigung Batteriespeicher
            </div>
          </CheckboxEl>
        </div>
        <div
          class="text-neutral flex flex-col items-center gap-0.5 standard-elevation-0-dark rounded-xs p-1.5"
        >
          <span class="caption-1">Anzahl Einzelprojekte</span>
          <h6>
            {{ countSelectedProjects.toLocaleString('de-DE') ?? 0 }}
          </h6>
        </div>

        <div
          class="text-neutral flex flex-col items-center gap-0.5 standard-elevation-0-dark rounded-xs p-1.5"
        >
          <span class="caption-1">Summe Anschlussleistungen</span>
          <h6>
            {{ powerSelectedProjects }}
            MW
          </h6>
        </div>
      </div>
    </template>
    <template v-else-if="activeTab === 1">
      <h5 class="text-title-color1 text-center">
        Bitte setzen Sie auf der Karte einen Punkt auf die Hochspannungstrasse.
      </h5>

      <div
        class="text-neutral flex flex-col items-center gap-0.5 standard-elevation-0-dark rounded-xs p-1.5"
      >
        <span class="caption-1">Anzahl Umspannwerke</span>
        <h6>
          {{ clusterSubstationPointsDraw.length.toLocaleString('de-DE') ?? 0 }}
        </h6>
      </div>
      <InputEl
        v-model="maxPowerMw"
        label="Maximale Anschlussleistung Umspannwerk"
        placeholder="Bitte eingeben"
        data-test="max-power-grid-station"
        suffix="MW"
      />
    </template>
    <template v-else-if="activeTab === 2">
      <h5 class="text-title-color1">Route Netzanschluss</h5>

      <div class="flex flex-col gap-3">
        <VRadioGroup v-model="route">
          <div class="flex flex-col gap-2">
            <VRadio :value="0">
              <template #label
                ><span class="body-3 ml-1.5"
                  >Geringste Netzanschlusskosten gemäß Trassenkategorien</span
                ></template
              >
            </VRadio>
            <VRadio :value="1">
              <template #label
                ><span class="body-3 ml-1.5">Kürzester Weg</span></template
              >
            </VRadio>
          </div>
        </VRadioGroup>
      </div>
    </template>
  </div>
  <div class="grid grid-cols-2 gap-2.5">
    <ButtonEl
      :full-width="true"
      color="color2"
      text="Abbrechen"
      @click="abort"
    />

    <ButtonEl
      v-if="activeTab === 2"
      :full-width="true"
      text="Jetzt Berechnen"
      class="whitespace-nowrap"
      :disabled="!isValid"
      @click="save"
    />

    <ButtonEl
      v-else
      :full-width="true"
      text="Weiter"
      :icon-right="true"
      color="color2"
      variant="secondary"
      icon="arrow_forward"
      @click="activeTab++"
    />
  </div>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue';
import { draw } from '@/apps/features/map/draw/draw';
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import axios from '@/utils/axiosHelper';
import cookie from 'vue-cookies';
import { useToastStore } from '@/stores/toast-store';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import TabHeaderCards from '@/components/TabHeaderCards/TabHeaderCards.vue';

const { showToast } = useToastStore();

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const activeTab = ref(0);
const maxPowerMw = ref(100);
const tabs = ref([
  {
    id: 0,
    icon: 'select_all',
  },
  {
    id: 1,
    icon: 'timeline',
  },
  {
    id: 2,
    icon: 'signpost',
  },
]);

const {
  clusterProjectIdsSelected,
  clusterExternalProjectIdsSelected,
  clusterProjectSelectedPower,
  clusterSubstationPointsDraw,
} = toRefs(props.mapStore);

const name = ref();
const route = ref(0);
const considerBattery = computed({
  get() {
    return props.mapStore.clusterWithBattery;
  },
  set(value) {
    props.mapStore.changeClusterBatteryOption(value);
  },
});

const countSelectedProjects = computed(() => {
  const projects = clusterProjectIdsSelected.value.size || 0;
  const externalProjects = clusterExternalProjectIdsSelected.value.size || 0;
  return projects + externalProjects;
});

const powerSelectedProjects = computed(() => {
  const power = clusterProjectSelectedPower.value;
  return power ? (power / 1000).toLocaleString('de-DE') : 0;
});

const isValid = computed(() => {
  return (
    countSelectedProjects.value > 0 &&
    name.value &&
    route.value !== null &&
    considerBattery.value !== null &&
    maxPowerMw &&
    clusterSubstationPointsDraw.value?.length
  );
});

async function save() {
  const data = {
    external_project_ids: Array.from(clusterExternalProjectIdsSelected.value),
    pv_project_ids: Array.from(clusterProjectIdsSelected.value),
    transformer_station_coords:
      clusterSubstationPointsDraw.value[0].geometry.coordinates,
    analysis_id: props.mapStore.selectedAnalysis.id,
    routing_option: route.value,
    max_transformer_load_kw: maxPowerMw.value * 1000,
    title: name.value,
    consider_battery: considerBattery.value,
  };
  let message;
  let color;
  try {
    await axios({
      url: '/api/usecase-2/selectable-clusters/',
      method: 'POST',
      data,
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    });
    message = 'Analyse wird erstellt. Dies kann einige Zeit dauern.';
    color = 'success';
    abort();
  } catch (err) {
    message =
      'Ein Fehler ist aufgetreten, bitte überprüfen Sie ihre' +
      ' Eingaben oder wenden Sie sich an den Support.';
    color = 'error';
  } finally {
    showToast({
      color,
      message,
      timeout: 3000,
    });
  }
}

function abort() {
  props.mapStore.resetMapAction(draw);
}
</script>
