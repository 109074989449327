import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';

function checkDataClassValidity(dataClass, metaData) {
  const dataObj = new dataClass();
  for (const field in dataObj) {
    if (!Object.prototype.hasOwnProperty.call(metaData, field)) {
      throw new Error(
        'Data class has a field that is is not in meta data: ' + field,
      );
    }
  }
}

export async function restGetOrFetchMetaData(dataClass) {
  if (dataClass.METADATA != null) {
    return dataClass.METADATA;
  }
  const resp = await axios({
    method: 'OPTIONS',
    url: dataClass.BASE_URL,
  });
  const metaData = resp.data.actions.POST;
  checkDataClassValidity(dataClass, metaData);
  dataClass.METADATA = metaData;
  return dataClass.METADATA;
}

export async function restDeleteById(dataClass, id) {
  return await axios({
    method: 'DELETE',
    url: dataClass.BASE_URL + `${id}/`,
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  });
}

export async function restDelete(dataObject) {
  const dataClass = dataObject.constructor;
  await restDeleteById(dataClass, dataObject.id);
}

export async function restPut(dataClass, args) {
  return await axios({
    method: 'PUT',
    url: dataClass.BASE_URL + `${args.id}/`,
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data: args,
  });
}

export async function restPatch(dataClass, id, args) {
  return await axios({
    method: 'PATCH',
    url: dataClass.BASE_URL + `${id}/`,
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data: args,
  });
}

export async function restPost(dataObject, fieldsToPost) {
  const jsonObject = {};
  for (const fieldToPost of fieldsToPost) {
    jsonObject[fieldToPost] = dataObject[fieldToPost];
  }
  const baseUrl = dataObject.constructor.BASE_URL;

  return await axios({
    method: 'POST',
    url: baseUrl,
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data: jsonObject,
  });
}

/**
 * Perform a GET request to fetch all data from a specified class.
 *
 * @async
 * @function restFetchAll
 * @param {object} dataClass - The data class that the function will use to perform the request. It should have a BASE_URL property.
 * @returns {Promise<object[]>} A promise that resolves to an array of objects, which represent the data fetched from the server.
 * @throws {Error} When the request fails due to network issues, or issues on the server.
 */
export async function restFetchAll(dataClass) {
  const resp = await axios({
    method: 'GET',
    url: dataClass.BASE_URL,
  });
  return fromJsonObjects(dataClass, resp.data.results);
}

export async function restFetchAllWithParam(dataClass, params) {
  const resp = await axios({
    method: 'GET',
    url: dataClass.BASE_URL,
    params,
  });
  return fromJsonObjects(dataClass, resp.data.results);
}

export async function restFetch(dataClass, id) {
  const resp = await axios({
    method: 'GET',
    url: dataClass.BASE_URL + id + '/',
  });
  return fromJsonObject(dataClass, resp.data);
}

function fromJsonObject(dataClass, jsonObject) {
  const dataObject = new dataClass();
  Object.assign(dataObject, jsonObject);
  return dataObject;
}

function fromJsonObjects(dataClass, jsonObjects = []) {
  return jsonObjects.map((project) => fromJsonObject(dataClass, project));
}

export function convertMetaDataToFormRules(metaData) {
  const formRules = {};
  for (const field in metaData) {
    const fieldMetaData = metaData[field];
    const fieldFormRules = {};
    for (const fieldMetaDataKey in fieldMetaData) {
      switch (fieldMetaDataKey) {
        case 'required':
          fieldFormRules[fieldMetaDataKey] = fieldMetaData[fieldMetaDataKey];
          break;
        case 'max_length':
          fieldFormRules.maxLength = fieldMetaData[fieldMetaDataKey];
          break;
        case 'min_value':
          fieldFormRules.min = fieldMetaData[fieldMetaDataKey];
          break;
        case 'max_value':
          fieldFormRules.max = fieldMetaData[fieldMetaDataKey];
          break;
      }
    }
    formRules[field] = fieldFormRules;
  }
  return formRules;
}

export function getWritableFields(metaData) {
  return Object.keys(metaData).filter((field) => !metaData[field].read_only);
}

export function getFieldType(field) {
  if (field.unit?.includes('%')) {
    return 'percent';
  }
  return field.type;
}

export function getValidationRules(field, titles, type = 'integer') {
  let rules = {};

  const minExists = field.min !== undefined;
  const maxExists = field.max !== undefined;
  const minMaxExists = minExists && maxExists;

  if (minMaxExists) rules.range = [field.min, field.max, type];
  else if (minExists) rules.min = [field.min, type];
  else if (maxExists) rules.max = [field.max, type];

  // Update: Only add maxLength if field.maxLength exists
  if (field.maxLength) {
    rules.maxLength = field.maxLength;
  }

  rules.required = field.required;

  if (titles) {
    rules.unique = [titles];
  }

  return rules;
}
