import MapboxDraw from '@mapbox/mapbox-gl-draw';
const { CommonSelectors } = MapboxDraw.lib;

const SelectFeature = {};

SelectFeature.onSetup = function (opts) {
  let state = {};
  state.drawCreateCallback = opts.drawCreateCallback;
  state.layerIds = opts.layerIds;
  state.hoveredFeatureId = null;
  return state;
};

SelectFeature.onKeyUp = function (state, e) {
  if (CommonSelectors.isEscapeKey(e)) {
    this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT);
  }
};

SelectFeature.toDisplayFeatures = function (state, geojson, display) {
  return display(geojson);
};

SelectFeature.onClick = function (state, e) {
  const features = this.map.queryRenderedFeatures(e.point, {
    layers: state.layerIds,
  });
  if (features.length) {
    state.drawCreateCallback(features[0]);
  }
};

export default SelectFeature;
