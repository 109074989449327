import { axios } from '@/utils/axiosHelper';
import { useToastStore } from '@/stores/toast-store';

let signal;
let ongoingRequest = 0;
let controller = new AbortController();

export async function fetchData(url, mapStore) {
  // Move the useToastStore call inside the function
  const { showToast } = useToastStore();

  if (ongoingRequest > 0) {
    controller.abort(signal);
    controller = new AbortController();
  }
  signal = controller.signal;
  ongoingRequest += 1;
  try {
    const resp = await axios({
      url,
      method: 'GET',
      signal,
    });
    ongoingRequest -= 1;
    return resp;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      mapStore.setInfoPanelVisibility(false);
      showToast({
        color: 'rgb(var(--spot-error))',
        message:
          'Zum angeforderten Datenpunkt konnte kein Eintrag gefunden werden. Bitte versuchen Sie es nach einem Karten refresh erneut, oder überprüfen Sie ihre Daten.',
        timeout: 3000,
      });
    }
    ongoingRequest -= 1;
    return null;
  }
}
