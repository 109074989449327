import { BUILDINGS } from '@/configs/color-constants';
import { fillExtrusionClickEnabledStyle } from '@/apps/features/map/utils';
import {
  CONSTRUCTION_YEAR_CLASS_LEGEND,
  CONSTRUCTION_YEAR_STYLING,
  SECTOR_LEGEND,
  SECTOR_STYLING,
} from '@/apps/usecase-1/map/layer-config/utils';

export const LAYER_KEY__BUILDINGS = 'building';

export default {
  [LAYER_KEY__BUILDINGS]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      subType: 'fillExtrusion',
      id: LAYER_KEY__BUILDINGS,
      sourceLayer: LAYER_KEY__BUILDINGS,
      layerId: LAYER_KEY__BUILDINGS,
      visibility: 'none',
      tilesDirectory: 'basemodule',
      dependsOnScenario: false,
    },
    sidebar: {
      name: 'Gebäude',
      subtitles: [
        'key',
        'street_with_number',
        'postal_code',
        'district_name',
        'municipality_name',
        'municipality_key',
      ],
      chips: [
        {
          title: 'Gebäudestruktur',
          active: true,
          items: [
            {
              title: 'Gebäudeinformationen',
              active: true,
              items: [
                {
                  key: 'function',
                  unit: '',
                },
                {
                  key: 'iwu_type',
                  unit: '',
                },
                {
                  key: 'sector',
                  unit: '',
                },
                {
                  key: 'roof_type',
                  unit: '',
                },
                {
                  key: 'construction_method',
                  unit: '',
                },
                {
                  key: 'construction_year_class',
                  unit: '',
                  forceString: true,
                },
                {
                  key: 'height_m',
                  unit: 'm',
                },
                {
                  key: 'floor_count',
                  unit: '',
                },
                {
                  key: 'usable_area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: fillExtrusionClickEnabledStyle(['#888888']),
      sector: fillExtrusionClickEnabledStyle([SECTOR_STYLING]),
      constructionYearClass: fillExtrusionClickEnabledStyle([
        CONSTRUCTION_YEAR_STYLING,
      ]),
    },
    legend: {
      default: {
        name: 'Gebäude',
        unit: '',
        items: [
          {
            color: BUILDINGS.BUILDING,
            name: 'Gebäude',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      sector: SECTOR_LEGEND('Gebäude nach Sektoren'),
      constructionYearClass: CONSTRUCTION_YEAR_CLASS_LEGEND(
        'Gebäude nach Baualtersklasse',
      ),
    },
  },
};
