import { getSidebarSimple } from './geographical-level';
import { polygonClickEnabledStyle } from '@/apps/features/map/utils';

export const LAYER_KEY__ESTATE = 'estate';

export default {
  [LAYER_KEY__ESTATE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__ESTATE,
      sourceLayer: LAYER_KEY__ESTATE,
      layerId: LAYER_KEY__ESTATE,
      visibility: 'none',
      tilesDirectory: 'uc1',
    },
    sidebar: getSidebarSimple('Flurstück', [
      'scenario_name',
      'id',
      'parcel_nr',
      'parcel_numerator',
      'parcel_denominator',
      'district_name',
      'municipality_name',
      'municipality_key',
    ]),
    style: {
      default: polygonClickEnabledStyle(['rgba(255,255,255,0)']),
    },
  },
};
