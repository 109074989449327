<template>
  <div class="relative h-full standard-elevation-0-dark w-full">
    <VChart :option="chartOptions" autoresize class="min-h-[inherit]" />
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { formatChartAxisLabel, formatChartTooltip } from '@/utils/chart-utils';

const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  labels: {
    type: Object,
    default: () => {},
  },
  legend: {
    type: [Array, Object],
    default: () => [],
  },
  title: {
    type: String,
    default: null,
  },
  gridTop: {
    type: String,
    default: '5%',
  },
  gridLeft: {
    type: String,
    default: '5%',
  },
  gridRight: {
    type: String,
    default: '3%',
  },
  gridBottom: {
    type: String,
    default: '3%',
  },
  titleTop: {
    type: String,
    default: '10px',
  },
  showYAxisLine: {
    type: Boolean,
    default: false,
  },
  showYAxisLabel: {
    type: Boolean,
    default: true,
  },
  unit: {
    type: String,
    default: null,
  },
  showTooltip: {
    type: Boolean,
    default: true,
  },
  yAxisLabelCustomFixedWidth: {
    type: Number,
    default: null,
  },
  yAxisName: {
    type: String,
    default: '',
  },
});

const chartOptions = computed(() => {
  const option = {
    backgroundColor: '#FFF',
    title: {
      text: props.title,
      left: 'center',
      top: props.titleTop,
      textStyle: {
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: 20,
        color: '#7E7570',
      },
    },
    grid: {
      top: props.gridTop,
      left: props.gridLeft,
      right: props.gridRight,
      bottom: props.gridBottom,
      containLabel: true,
    },
    tooltip: {
      show: props.showTooltip,
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      textStyle: {
        fontSize: 12,
        color: 'black',
        fontWeight: 500,
        fontFamily: 'mark',
        align: 'left',
        style: 'normal',
      },
      backgroundColor: 'rgba(255, 255, 255)',
      borderWidth: 0,
      borderRadius: 0,
      boxShadow: '0 0.5px 8px 0 rgba(207, 216, 220, 0.50)',
      formatter: (params) => {
        // Helper function to filter valid params
        const validParams = params.filter(
          (param) => param.value > 0 && param.seriesIndex !== 0,
        );

        // Clean seriesName by removing hyphen followed by newline and trimming excess spaces
        const cleanSeriesName = (name) => name.replace(/-\n/g, '').trim();

        // Calculate total sum of all stacked values
        const totalValue = validParams.reduce(
          (acc, param) => acc + param.value,
          0,
        );

        // Sort params by their component index
        const sortedParams = validParams.sort(
          (a, b) => b.componentIndex - a.componentIndex,
        );

        // Reusable function to build tooltip item
        const buildTooltipItem = (name, value, color) => `
      <div style='display: flex; align-items: center;'>
        <span style='display:inline-block;width:12px;height:12px;background-color:${color};margin-right:5px;'></span>
        <div style='display: flex; justify-content: space-between; width: 100%; padding-bottom: 1px;'><span style='margin-right:5px;'>${name}:</span> <span style='margin-left: auto'>${formatChartTooltip(value, props.unit)}</span></div>
      </div>`;

        // Map through params and generate tooltip content
        const tooltipContent = sortedParams
          .map((param) => {
            const name =
              param.seriesName === 'series\u00001'
                ? cleanSeriesName(param.name)
                : cleanSeriesName(param.seriesName);

            return buildTooltipItem(name, param.value, param.color);
          })
          .join('');

        // Handle the case where no valid values are present
        if (tooltipContent === '') return null;

        // Add total only if multiple values exist
        const totalTooltip =
          sortedParams.length > 1
            ? `
      <div style='display: flex; justify-content: space-between; border-top: 1px solid #C3BCB6; margin-top: 5px; padding-top: 5px;'>
        <span>Gesamt:</span><span>${formatChartTooltip(
          totalValue,
          props.unit,
        )}</span>
      </div>`
            : '';

        // Return the full tooltip
        return `<div>${tooltipContent}${totalTooltip}</div>`;
      },
    },

    xAxis: {
      type: 'category',
      data: props.labels,
      axisLine: {
        show: true,
        lineStyle: {
          color: '#C3BCB6',
          width: 3,
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        interval: 0,
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: 16,
        color: '#322A26',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#E0E6F1'],
        },
      },
    },
    yAxis: {
      type: 'value',
      axisTick: {
        show: false,
      },
      name: props.yAxisName,
      nameGap: 16,
      nameTextStyle: {
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 16,
        color: '#322A26',
        align: 'right',
      },
      axisLabel: {
        show: props.showYAxisLabel,
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 16,
        color: '#322A26',
        formatter: (value) => {
          if (props.yAxisLabelCustomFixedWidth) {
            return `{fixedWidth|${formatChartAxisLabel(value)}}`;
          }
          return formatChartAxisLabel(value);
        },
        rich: {
          fixedWidth: {
            width: props.yAxisLabelCustomFixedWidth,
            fontFamily: 'mark',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 16,
            color: '#322A26',
          },
        },
      },
      splitLine: {
        show: props.showYAxisLine,
        lineStyle: {
          color: ['#E0E6F1'],
        },
      },
    },
    legend: {
      selectedMode: false,
      show: true,
      itemWidth: 12,
      itemHeight: 12,
      textStyle: {
        fontFamily: 'enbw',
        fontWeight: 700,
        fontSize: 12,
        color: '#322A26',
      },
      icon: 'rect',
      itemStyle: {
        borderWidth: 0,
      },
      ...props.legend,
    },
    series: [
      {
        type: 'bar',
        stack: 'Total',
        barWidth: '32px',
        data: props.data.hiddenStacks,
        itemStyle: {
          color: 'transparent',
        },
      },
    ],
  };

  if (props.data.visibleDataIsStacked) {
    for (const stack of props.data.visibleStacks) {
      option.series.push({
        ...stack,
        type: 'bar',
        stack: 'Total',
        label: {
          show: false,
        },
      });
    }
  } else {
    option.series.push({
      type: 'bar',
      stack: 'Total',
      label: {
        show: false,
      },
      data: props.data.visibleStacks,
    });
  }

  return option;
});
</script>
