import { computed, ref, toRefs } from 'vue';
import {
  restFetchAll,
  restGetOrFetchMetaData,
  restPost,
} from '@/utils/rest-utils';
import { useToastStore } from '@/stores/toast-store';
import { PotentialAnalysisDto } from '../potential-analysis-dto';
import { usePotentialAnalysisStore } from '../potential-analysis-store';

export function usePotentialAnalysis() {
  const { showToast } = useToastStore();
  const potentialsStore = usePotentialAnalysisStore();
  const { potentialsData } = toRefs(potentialsStore);
  const { fetchPotentials } = potentialsStore;
  const loading = ref(false);
  const showForm = ref(false);
  const potentialAnalysis = ref(null);
  const metaData = ref(null);

  const potentialsNames = computed(() => {
    return potentialsData.value?.map((potential) => potential.title) ?? [];
  });

  const isClusterAnalysisComplete = computed(() => {
    if (!potentialAnalysis.value.cluster_apply_analysis) return true;
    const fields = [
      'cluster_max_transformer_load_kw',
      'cluster_min_connected_load_kw',
      'cluster_max_connected_load_kw',
      'cluster_max_number_projects',
      'cluster_max_distance_grid_m',
      'cluster_max_distance_projects_m',
      'cluster_consider_battery',
      'cluster_only_unconnected_projects',
      'cluster_consider_external_projects',
    ];
    return fields.every((field) => potentialAnalysis.value[field] !== null);
  });

  async function getPotentials(itemId = null) {
    if (itemId && potentialAnalysis.value?.id === itemId) {
      showForm.value = false;
    }
    loading.value = true;
    await fetchPotentials();
    loading.value = false;
  }

  async function fetchMetaData() {
    metaData.value = await restGetOrFetchMetaData(PotentialAnalysisDto);
  }

  async function create(doFetchPotentials = true) {
    loading.value = true;
    let fieldsToPost = [
      'title',
      'description',
      'municipalities',
      'estates',
      'is_manual',
      'min_area_m2',
      'max_estate_density',
      'orientation_value_greater_equal_than',
      'orientation_value_smaller_equal_than',
      'slope_degree_value_greater_equal_than',
      'slope_degree_value_smaller_equal_than',
      'min_slope_degree_to_consider_orientation',
      'max_load_for_grid_connection_point_kw',
      'max_load_for_substation_kw',
      'max_load_for_high_voltage_kw',
      'connection_radius_m',
      'battery_capacity_proportion',
      'routing_option',
      'disadvantage_area_list',
      'agricultural_plots_list',
      'specific_plant_size_kw_m2',
      'dc_ac_ratio',
      'inverter_efficiency',
      'performance_ratio',
      'mounting_slope_degree',
      'configrestrictions_set',
    ];
    if (potentialAnalysis.value.cluster_apply_analysis) {
      fieldsToPost = [
        ...fieldsToPost,
        'cluster_apply_analysis',
        'cluster_max_transformer_load_kw',
        'cluster_min_connected_load_kw',
        'cluster_max_connected_load_kw',
        'cluster_consider_battery',
        'cluster_only_unconnected_projects',
        'cluster_max_number_projects',
        'cluster_max_distance_grid_m',
        'cluster_max_distance_projects_m',
        'cluster_consider_external_projects',
      ];
    }
    let message;
    let color;
    let success = true;
    try {
      await restPost(potentialAnalysis.value, fieldsToPost);
      if (doFetchPotentials) {
        await getPotentials();
      }
      showForm.value = false;
      message = 'Analyse wird erstellt. Dies kann einige Zeit dauern.';
      color = 'success';
    } catch {
      message =
        'Ein Fehler ist aufgetreten, bitte überprüfen Sie ihre' +
        ' Eingaben oder wenden Sie sich an den Support.';
      color = 'error';
      success = false;
    } finally {
      showToast({
        color,
        message,
        timeout: 3000,
      });
      loading.value = false;
    }
    return success;
  }

  return {
    create,
    getPotentials,
    fetchMetaData,
    loading,
    showForm,
    potentialsData,
    potentialAnalysis,
    metaData,
    potentialsNames,
    isClusterAnalysisComplete,
  };
}
