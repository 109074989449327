<template>
  <div
    class="flex items-center bg-white rounded-[8px] standard-elevation-2 w-full p-2"
  >
    <input
      v-model="location"
      type="text"
      placeholder="Suchbegriff"
      class="bg-infra-$gray-highlight focus:outline-none w-full"
      @input="toggleSearch"
    />
    <div
      v-if="!location"
      class="h-6 w-6 pointer-events-none flex items-center justify-center"
    >
      <ZoomIcon />
    </div>
    <div
      v-else
      class="h-6 w-6 cursor-pointer flex items-center justify-center"
      @click="closeSearchbar"
    >
      <VIcon>mdi-close</VIcon>
    </div>
  </div>
</template>

<script setup>
import { useDebounceFn } from '@vueuse/core';
import { removeMarker } from '@/mapbox/main';
import ZoomIcon from '@/assets/icons/ZoomIcon.vue';
import { ref, watch } from 'vue';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const location = ref(null);

watch(
  () => props.mapStore.showSearch,
  (newVal) => {
    if (!newVal) {
      location.value = null;
    }
  },
);

function toggleSearch() {
  if (!props.mapStore.showSearch) {
    props.mapStore.setSearchbar(true);
  }
  searchLocation();
}

const searchLocation = useDebounceFn(() => {
  props.mapStore.fetchSearchResults(location.value);
}, 500);

function closeSearchbar() {
  props.mapStore.resetSearch();
  location.value = null;
  removeMarker();
}
</script>
