import { defineStore } from 'pinia';
import axios from 'axios';

export const useBaseStore = defineStore('baseStore', {
  state: () => ({
    municipalityGeoData: [],
    isFetching: false,
    fetchPromise: null, // To store the current fetch promise
  }),
  actions: {
    async fetchSimplifiedMuniData() {
      // If already fetching, return the existing promise to avoid duplicate calls
      if (this.isFetching) return this.fetchPromise;

      // Return data if already fetched
      if (this.municipalityGeoData.length) {
        return this.municipalityGeoData;
      }

      // Create and store the fetch promise
      this.isFetching = true;
      this.fetchPromise = (async () => {
        try {
          const response = await axios.get(
            '/api/basemodule/simplified-municipality-data/',
          );
          this.municipalityGeoData = response.data.results;
          return this.municipalityGeoData;
        } catch (error) {
          console.error(error);
          throw error; // Allow error to propagate to await calls
        } finally {
          this.isFetching = false;
          this.fetchPromise = null; // Clear the promise after fetching completes
        }
      })();

      return this.fetchPromise; // Return the fetch promise to await
    },
  },
});
