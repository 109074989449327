import {
  SECTOR,
  CONSTRUCTION_YEAR,
  hexToRgba,
  HEATING_TYPE,
} from '@/configs/color-constants';

export const CONSTRUCTION_YEAR_STYLING = [
  'match',
  ['get', 'construction_year_class'],
  0,
  CONSTRUCTION_YEAR.UNTIL_1948,
  1,
  CONSTRUCTION_YEAR.BETWEEN_1949_1968,
  2,
  CONSTRUCTION_YEAR.BETWEEN_1969_2001,
  3,
  CONSTRUCTION_YEAR.AFTER_2001,
  CONSTRUCTION_YEAR.UNKNOWN,
];

export const SECTOR_STYLING = [
  'match',
  ['get', 'sector'],
  0,
  SECTOR.GHD,
  1,
  SECTOR.PUBLIC,
  2,
  SECTOR.HOUSEHOLD,
  3,
  SECTOR.UNKNOWN,
  4,
  SECTOR.INDUSTRY,
  SECTOR.UNKNOWN,
];

export const TECHNOLOGY_STYLING = (year) => [
  'match',
  ['get', `heating_type_${year}`],
  'GAS',
  HEATING_TYPE.GAS,
  'OIL',
  HEATING_TYPE.OIL,
  'REN',
  HEATING_TYPE.REN,
  'DIS',
  HEATING_TYPE.DIS,
  'COA',
  HEATING_TYPE.COA,
  'WOD',
  HEATING_TYPE.WOD,
  'ELE',
  HEATING_TYPE.ELE,
  'BGA',
  HEATING_TYPE.BGA,
  'OTH',
  HEATING_TYPE.OTH,
  '-',
  HEATING_TYPE['-'],
  'UNK',
  HEATING_TYPE.UNK,
  HEATING_TYPE.UNK,
];

export const HEATING_TECHNOLOGY_LEGEND = (name) => ({
  name,
  unit: '',
  items: [
    {
      color: HEATING_TYPE.GAS,
      name: 'Fossiles Gas',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.OIL,
      name: 'Heizöl',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.REN,
      name: 'Wärmepumpen,\nSolar-/Geothermie',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.DIS,
      name: 'Fernwärme',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.COA,
      name: 'Kohle',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.WOD,
      name: 'Holz/Holzpellets',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.ELE,
      name: 'Strom\n(ohne Wärmepumpen)',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.BGA,
      name: 'Biomasse/Biogas',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.OTH,
      name: 'Sonstige',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE['-'],
      name: 'Keine Heizung',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.UNK,
      name: 'Unbekannt',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const SECTOR_LEGEND = (name) => ({
  name,
  unit: '',
  items: [
    {
      color: SECTOR.HOUSEHOLD,
      name: 'Private Haushalte',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.PUBLIC,
      name: 'Öffentlich',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.GHD,
      name: 'Gewerbe, Handel,\nDienstleistungen',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.INDUSTRY,
      name: 'Industrie',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.UNKNOWN,
      name: 'Sonstige',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const CONSTRUCTION_YEAR_CLASS_LEGEND = (name) => ({
  name,
  unit: '',
  items: [
    {
      color: CONSTRUCTION_YEAR.UNTIL_1948,
      name: 'vor 1949',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: CONSTRUCTION_YEAR.BETWEEN_1949_1968,
      name: '1949 bis 1968',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: CONSTRUCTION_YEAR.BETWEEN_1969_2001,
      name: '1969 bis 2001',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: CONSTRUCTION_YEAR.AFTER_2001,
      name: 'nach 2001',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: CONSTRUCTION_YEAR.UNKNOWN,
      name: 'unbekannt',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const powerStyleType = (fieldName, min, max) => [
  ['!', ['has', fieldName]],
  hexToRgba(min.color, 1),
  [
    'interpolate',
    ['linear'],
    ['get', fieldName],
    min.value,
    hexToRgba(min.color, 1),
    max.value,
    hexToRgba(max.color, 1),
  ],
];

export const powerLegendType = (legendName, unit, min, max) => ({
  type: 'gradient',
  name: legendName,
  unit,
  items: {
    min: {
      value: min.value,
      color: min.color,
    },
    max: {
      value: max.value,
      color: max.color,
    },
  },
});
