import { defineStore } from 'pinia';
import { restFetchAll, restFetchAllWithParam } from '@/utils/rest-utils';
import { ProjectDto } from '@/apps/usecase-1/projects/project-dto';
import { ScenarioDto } from '@/apps/usecase-1/scenario/scenario-dto';

export const useProjectStore = defineStore('projectStore', {
  state: () => ({
    projects: [],
    scenarios: [],
  }),
  actions: {
    async fetchProjects() {
      try {
        this.projects = await restFetchAll(ProjectDto);
        return this.projects;
      } catch (error) {
        console.error('Error fetching projects:', error);
        throw error;
      }
    },
    async fetchScenarios() {
      try {
        this.scenarios = await restFetchAll(ScenarioDto);
        return this.scenarios;
      } catch (error) {
        console.error('Error fetching scenarios:', error);
        throw error;
      }
    },

    async fetchProjectsAndScenarios() {
      try {
        await Promise.all([this.fetchProjects(), this.fetchScenarios()]);
      } catch (error) {
        console.error('Error fetching projects and scenarios:', error);
        throw error;
      }
    },
  },
});
