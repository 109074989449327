export const contentStructureAssumptionsScenario = [
  {
    tab: {
      title: 'Basisparameter',
      icon: 'text_snippet',
    },
    blocks: [
      {
        width: '33%',
        children: [
          {
            fields: ['title', 'is_released'],
          },
        ],
      },
    ],
  },
  {
    tab: {
      title: 'Wärme',
      icon: 'microwave',
    },
    blocks: [
      {
        title: 'Sanierung',
        children: [
          {
            subTitle: 'Sanierungsquote je Sektor',
            flow: 'horizontal',
            fields: [
              'refurbishment_rate_2',
              'refurbishment_rate_0',
              'refurbishment_rate_4',
              'refurbishment_rate_1',
              'refurbishment_rate_3',
            ],
          },
          {
            subTitle: 'Sanierungstiefe je Sektor',
            flow: 'horizontal',
            fields: [
              'refurbishment_kwh_m2_2',
              'refurbishment_kwh_m2_0',
              'refurbishment_kwh_m2_4',
              'refurbishment_kwh_m2_1',
              'refurbishment_kwh_m2_3',
            ],
          },
        ],
      },
      {
        title: 'Volllaststunden',
        children: [
          {
            subTitle: 'Gleichzeitigkeit je Sektor',
            flow: 'horizontal',
            fields: [
              'heat_simultaneity_2',
              'heat_simultaneity_0',
              'heat_simultaneity_4',
              'heat_simultaneity_1',
              'heat_simultaneity_3',
            ],
          },
          {
            subTitle: 'Volllaststunden vor Gleichzeitigkeit je Sektor',
            flow: 'horizontal',
            fields: [
              'heat_full_load_hours_h_a_2',
              'heat_full_load_hours_h_a_0',
              'heat_full_load_hours_h_a_4',
              'heat_full_load_hours_h_a_1',
              'heat_full_load_hours_h_a_3',
            ],
          },
        ],
      },
      {
        title: 'Klimaneutrale Versorgungsoptionen',
        children: [
          {
            subTitle: 'Zentral (Wärmenetze)',
            width: '40%',
            fields: [
              'heat_line_density_kwh_m',
              'share_electrification_heat_network',
              'annual_performance_factor_large_heat_pump',
              'cop_large_heat_pump',
              'connection_rate_heat_network',
            ],
          },
          {
            subTitle: 'Dezentrale elektrische Einzelversorgung (Wärmepumpen)',
            width: '40%',
            fields: [
              'annual_performance_factor_heat_pump',
              'cop_heat_pump',
              'connection_rate_heat_pump',
              'minimum_heat_demand_for_heat_pump_kwh_a',
            ],
          },
        ],
      },
    ],
  },
  {
    tab: {
      title: 'E-Mobilität',
      icon: 'electric_car',
    },
    blocks: [
      {
        title: 'Elektroauto mit Batterie (BEV)',
        width: '50%',
        children: [
          {
            fields: [
              'average_mileage_per_bev',
              'average_factor_mileage_per_year',
              'average_consumption_kwh_100km',
              'efficiency_factor_per_year_bev',
            ],
          },
        ],
      },
      {
        title: 'Entwicklung BEV Bestand',
        flow: 'horizontal',
        children: [
          {
            fields: ['factor_existing_bev_per_year', 'years'],
          },
          {
            fields: [
              { tag: 'div', wrapperStyle: 'min-height: 54px;' },
              {
                tag: 'h6',
                wrapperClass: 'flex',
                wrapperStyle: 'min-height: 54px;',
                class: 'text-title-neutral mt-auto mb-3',
                content: 'Stützjahre',
              },
            ],
          },
        ],
      },
      {
        title: 'Ladeinfrastruktur',
        flow: 'horizontal',
        children: [
          {
            subTitle: 'Verhältnis Ladepunkt (LP)\nzu E-Mobil (BEV)',
            removeFromLabel: 'Verhältnis Ladepunkt (LP) zu E-Mobil (BEV)',
            fields: [
              'charging_point_ratio_home_charge',
              'charging_point_ratio_fleet_charge',
              'charging_point_ratio_ac_charge',
              'charging_point_ratio_dc_charge',
            ],
          },
          {
            subTitle: 'Spezifische Leistung\nvor Gleichzeitigkeit',
            removeFromLabel: 'Spezifische Leistung vor Gleichzeitigkeit',
            fields: [
              'specific_output_home_charge_kw',
              'specific_output_fleet_charge_kw',
              'specific_output_ac_charge_kw',
              'specific_output_dc_charge_kw',
            ],
          },
          {
            subTitle: 'Gleichzeitigkeitsfaktor\nnach Ladetyp',
            removeFromLabel: 'Gleichzeitigkeitsfaktor',
            fields: [
              'simultaneity_home_charge',
              'simultaneity_fleet_charge',
              'simultaneity_ac_charge',
              'simultaneity_dc_charge',
            ],
          },
        ],
      },
    ],
  },
  {
    tab: {
      title: 'Strom',
      icon: 'bolt',
    },
    blocks: [
      {
        title: 'Stromeffizienz',
        children: [
          {
            subTitle: 'Effizienzfaktor je Sektor',
            flow: 'horizontal',
            fields: [
              'power_efficiency_factor_per_year_2',
              'power_efficiency_factor_per_year_0',
              'power_efficiency_factor_per_year_4',
              'power_efficiency_factor_per_year_1',
              'power_efficiency_factor_per_year_3',
            ],
          },
        ],
      },
      {
        title: 'Stromlast',
        children: [
          {
            subTitle: 'Gleichzeitigkeit',
            flow: 'horizontal',
            fields: [
              'power_simultaneity_2',
              'power_simultaneity_0',
              'power_simultaneity_4',
              'power_simultaneity_1',
              'power_simultaneity_3',
            ],
          },
          {
            subTitle: 'Volllaststunden vor Gleichzeitigkeit',
            flow: 'horizontal',
            fields: [
              'power_full_load_hours_h_a_2',
              'power_full_load_hours_h_a_0',
              'power_full_load_hours_h_a_4',
              'power_full_load_hours_h_a_1',
              'power_full_load_hours_h_a_3',
            ],
          },
        ],
      },
    ],
  },
  {
    tab: {
      title: 'Wasserstoff',
      icon: 'water_drop',
    },
    blocks: [
      {
        width: '33%',
        children: [
          {
            title: 'Elektrolyseur',
            fields: ['hydrogen_efficiency', 'hydrogen_full_load_hours_h_a'],
          },
        ],
      },
    ],
  },
  {
    tab: {
      title: 'EE-Bestand',
      icon: 'energy_savings_leaf',
    },
    blocks: [
      {
        title: 'Volllaststunden EE-Stromerzeugung (Bestand)',
        width: '33%',
        children: [
          {
            removeFromLabel: 'Vollbenutzungsstunden',
            fields: [
              'pv_roof_full_load_hours_h_a',
              'pv_free_full_load_hours_h_a',
              'wind_full_load_hours_h_a',
              'biomass_full_load_hours_h_a',
              'water_full_load_hours_h_a',
            ],
          },
        ],
      },
    ],
  },
  {
    tab: {
      title: 'EE-Potenzial',
      icon: 'stacked_line_chart',
    },
    blocks: [
      {
        title: 'PV-Aufdach',
        children: [
          {
            width: '50%',
            fields: ['pv_roof_include_orientations'],
          },
          {
            width: '33%',
            fields: [
              'pv_roof_yield_per_area_kw_m2',
              'potential_pv_roof_full_load_hours_h_a',
            ],
          },
        ],
      },
      {
        title: 'PV-Freifläche',
        width: '33%',
        children: [
          {
            removeFromLabel: 'Vollbenutzungsstunden',
            fields: [
              'pv_free_yield_per_area_kw_m2',
              'potential_pv_free_full_load_hours_h_a',
            ],
          },
        ],
      },
      {
        title: 'Wind',
        width: '33%',
        children: [
          {
            fields: [
              'required_area_per_windpowerplant_m2',
              'wind_yield_per_powerplant_kw',
              'potential_wind_full_load_hours_h_a',
            ],
          },
        ],
      },
    ],
  },
];
