import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useMapStore as MapUsecase1 } from '@/apps/usecase-1/map/map-store';
import { useMapStore as MapUsecase2 } from '@/apps/usecase-2/map/map-store';

export const usePanelStore = defineStore('panel', () => {
  const route = useRoute();
  const currentPath = computed(() => route.path);

  // State
  const openPanels = ref([]);
  const PANEL_WIDTH = ref(360);
  const PANEL_GAP = ref(20);
  const DEFAULT_LEFT_GAP = ref(20);
  const TRANSITION = ref('left 0.3s ease');

  // Getters
  const baseOffset = computed(() => {
    const activeStore = (() => {
      switch (true) {
        case currentPath.value.includes('uc1'):
          return MapUsecase1();
        case currentPath.value.includes('uc2'):
          return MapUsecase2();
        default:
          return MapUsecase1();
      }
    })();
    return activeStore.showMainMenu ? PANEL_WIDTH.value + PANEL_GAP.value : 0;
  });

  const panelPositions = computed(() =>
    openPanels.value.reduce((acc, panelId, index) => {
      acc[panelId] = {
        left: `${DEFAULT_LEFT_GAP.value + baseOffset.value + index * (PANEL_WIDTH.value + PANEL_GAP.value)}px`,
        transition: TRANSITION.value,
      };
      return acc;
    }, {}),
  );

  const isPanelOpen = (panelId) => openPanels.value.includes(panelId);

  // Actions
  function addPanel(panelId) {
    if (!openPanels.value.includes(panelId)) {
      openPanels.value.push(panelId);
    }
  }

  function removePanel(panelId) {
    openPanels.value = openPanels.value.filter((id) => id !== panelId);
  }

  function togglePanel(panelId) {
    if (isPanelOpen(panelId)) {
      removePanel(panelId);
    } else {
      addPanel(panelId);
    }
  }

  // Optional actions
  function clearPanels() {
    openPanels.value = [];
  }

  function movePanelToFront(panelId) {
    if (isPanelOpen(panelId)) {
      openPanels.value = [
        ...openPanels.value.filter((id) => id !== panelId),
        panelId,
      ];
    }
  }

  function updatePanelDimensions({ width, gap, leftGap, transition }) {
    if (width) PANEL_WIDTH.value = width;
    if (gap) PANEL_GAP.value = gap;
    if (leftGap) DEFAULT_LEFT_GAP.value = leftGap;
    if (transition) TRANSITION.value = transition;
  }

  return {
    panelPositions,
    addPanel,
    removePanel,
    togglePanel,
  };
});
