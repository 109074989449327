<template>
  <div
    v-if="panelData.chips.length"
    class="flex flex-col standard-elevation-0-dark rounded-t-[6px]"
  >
    <div v-show="panelData.chips.length > 1" style="width: 340px">
      <div class="flex flex-col p-2.5 gap-2.5">
        <div
          class="flex w-fit rounded-[4px] overflow-hidden border border-color1"
        >
          <div
            v-for="(chip, index) in panelData.chips"
            :key="chip.title"
            class="custom-chip border-l border-color1 first:border-l-0"
            @click="activeChipIndex = index"
          >
            <VTooltip
              :model-value="tooltipActive === chip.title"
              location="top"
              activator="parent"
              offset="-5"
            >
              {{ chip.title }}
            </VTooltip>
            <ButtonEl
              class="rounded-none w-10 h-10"
              :icon="iconsMap[chip.title]"
              :color="activeChipIndex === index ? 'color1' : 'color2'"
            />
          </div>
        </div>
        <h6 class="bg-subtle text-title-color1 rounded-[4px] px-2.5 py-3">
          {{ panelData.chips[activeChipIndex].title }}
        </h6>
      </div>
    </div>

    <InfoPanelData
      v-if="panelData"
      :panel-data="panelData"
      :active-chip-index="activeChipIndex"
      :feature="feature"
      @close-info-panel="emit('close-info-panel')"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import InfoPanelData from '@/apps/features/map/info-panel/InfoPanelData.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';

const emit = defineEmits(['close-info-panel']);
const props = defineProps({
  panelData: {
    type: Object,
    default: null,
  },
  feature: {
    type: Object,
    default: null,
  },
});

const iconsMap = ref({
  Gebäude: 'house_siding',
  Gebäudestruktur: 'house_siding',
  Nachfrage: 'multiple_stop',
  Erzeugung: 'solar_power',
  Fläche: 'layers',
  'Technische Auslegung': 'miscellaneous_services',
  Investitionen: 'euro',
});

const activeChipIndex = ref(0);
const tooltipActive = ref(null);

watch(
  () => props.panelData,
  (newPanelData, oldPanelData) => {
    if (newPanelData.name !== oldPanelData.name) {
      activeChipIndex.value = 0;
    }
  },
);
</script>

<style scoped lang="scss">
.custom-scrollbar-width {
  scrollbar-width: thin;
}
</style>
