<template>
  <div class="absolute right-5">
    <ButtonEl
      icon="format_list_bulleted"
      data-test="legend-icon"
      :color="showLegend ? 'color1' : 'color2'"
      :custom-bg-hover-color="showLegend ? 'bg-default' : null"
      :class="[
        'ml-auto absolute z-10 right-0 h-10 rounded-[8px]',
        {
          'standard-elevation-2': !showLegend,
        },
      ]"
      @click="toggleLegend"
    />
    <LegendMap v-if="showLegend" v-bind="$attrs" :map-store />
  </div>
</template>
<script setup>
import LegendMap from '@/apps/features/map/legend/LegendMap.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';

const props = defineProps({
  showLegend: {
    type: Boolean,
    default: false,
  },
  mapStore: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['toggleLegendState']);

function toggleLegend() {
  emit('toggleLegendState');
}
</script>
