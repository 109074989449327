<template>
  <div
    class="rounded-[8px] z-[1] top-0 w-52 relative overflow-hidden bg-white standard-elevation-1"
  >
    <div class="h-full">
      <h5 class="p-2.5 w-full text-title-neutral">Legende</h5>
      <div
        :style="{ maxHeight: containerHeight + 'px' }"
        class="overflow-y-auto hide-scrollbar"
      >
        <div class="p-2.5">
          <div class="flex flex-col gap-2.5">
            <div
              v-for="parentItem in getLegend"
              :key="parentItem.key"
              class="standard-elevation-0-dark p-2.5 rounded flex flex-col gap-1"
            >
              <div class="subtitle-3 text-title-color1">
                {{ parentItem.name }}
              </div>
              <!-- Non-gradient elements without categoryId -->
              <div
                v-for="item in getFilteredItems(
                  false,
                  parentItem.items,
                  'icon',
                )"
                :key="item.key"
              >
                <div class="flex flex-col gap-1">
                  <LegendItem
                    :item="item"
                    :icon-styles="iconStyles"
                    :icon-styles-child="iconStylesChild"
                  />
                </div>
              </div>

              <!-- Non-gradients with subcategories: Loop over subcategories -->
              <div
                v-for="subtitle in parentItem.categoryIdArray"
                :key="subtitle.key"
                class="pt-2"
              >
                <div>
                  <!-- Render a subtitle -->
                  <div
                    v-for="titleObj in parentItem.titleMap"
                    :key="Object.keys(titleObj)[0]"
                  >
                    <div v-if="subtitle in titleObj">
                      <div class="body-2 text-gray-500">
                        {{ titleObj[subtitle] }}
                      </div>
                      <!-- Render items associated with the subtitle -->
                      <div
                        v-for="item in getFilteredItems(
                          true,
                          parentItem.items,
                          'icon',
                        )"
                        :key="item.key"
                      >
                        <div v-if="item.id === subtitle">
                          <LegendItem
                            :item="item"
                            :icon-styles="iconStyles"
                            :icon-styles-child="iconStylesChild"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <!-- Gradients without subcategories -->
                <div
                  v-for="item in getFilteredItems(
                    false,
                    parentItem.items,
                    'max',
                  )"
                  :key="item.key"
                >
                  <GradientItem
                    :unit="parentItem.unit"
                    :min-value="item.min.value"
                    :max-value="item.max.value"
                    :min-color="item.min.color"
                    :max-color="item.max.color"
                  />
                </div>

                <!-- Gradients with subcategories: Loop over subtitles -->
                <div
                  v-for="subtitle in parentItem.gradientTitles"
                  :key="subtitle.key"
                >
                  <div>
                    <!-- Render subtitle -->
                    <div class="body-2 text-gray-500 pt-2">
                      {{ subtitle }}
                    </div>

                    <!-- Render items associated with subtitle -->
                    <div
                      v-for="item in getFilteredItems(
                        true,
                        parentItem.items,
                        'max',
                      )"
                      :key="item.key"
                    >
                      <div v-if="item.id === subtitle">
                        <GradientItem
                          :unit="item.unit"
                          :min-value="item.min.value"
                          :max-value="item.max.value"
                          :min-color="item.min.color"
                          :max-color="item.max.color"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import LegendItem from './LegendItem.vue';
import GradientItem from './GradientItem.vue';
import cloneObject from '@/utils/objectUtils';
import { useWindowSize } from '@vueuse/core';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { height } = useWindowSize();
const containerHeight = computed(
  () => height.value - props.mapStore.mapControlsHeight - 98,
);

const emit = defineEmits(['setLegendIconState', 'showLegend']);

const getLegendEntries = computed(() => props.mapStore.getLegendEntries);

const getLegend = computed(() => {
  const legendEntries = setLegendData();
  setLegendIconState(legendEntries.length > 0);
  showLegend(legendEntries.length > 0);
  return legendEntries;
});

const getFilteredItems = (withIds, array, field) => {
  return withIds
    ? array.filter((item) => 'id' && field in item)
    : array.filter((item) => !('id' in item) && field in item);
};

const setLegendIconState = (entriesExist) => {
  emit('setLegendIconState', !!entriesExist);
};

const showLegend = (entriesExist) => {
  emit('showLegend', entriesExist);
};

const processGradientLegend = (legend, elem) => {
  const updatedLegend = { ...legend };

  if (elem.subTitle) {
    updatedLegend.items = [...updatedLegend.items, ...elem.items];
    updatedLegend.gradientTitles = updatedLegend.gradientTitles
      ? [...updatedLegend.gradientTitles, elem.subTitle]
      : [elem.subTitle];
  } else {
    updatedLegend.items = [elem.items];
  }

  return updatedLegend;
};

const processCategoryLegend = (legend, elem) => {
  const updatedLegend = { ...legend };

  if (!updatedLegend.categoryIdArray.includes(elem.categoryId)) {
    updatedLegend.categoryIdArray = [
      ...updatedLegend.categoryIdArray,
      elem.categoryId,
    ];
    updatedLegend.titleMap = [
      ...updatedLegend.titleMap,
      { [elem.categoryId]: elem.subTitle },
    ];
  }

  updatedLegend.items = [...updatedLegend.items, ...elem.items];
  return updatedLegend;
};

const initializeNewLegend = (elem) => {
  const { type, subTitle, categoryId, items } = elem;
  const newLegend = {
    ...cloneObject(elem),
    items: [],
    gradientTitles:
      type === 'gradient' ? (subTitle ? [subTitle] : []) : undefined,
    categoryIdArray: categoryId ? [categoryId] : undefined,
    titleMap: categoryId ? [{ [categoryId]: subTitle }] : undefined,
  };

  if (type === 'gradient') {
    newLegend.items = subTitle ? [...items] : [items];
  } else if (items[0]?.type === 'gradient') {
    newLegend.items = [items[0].items];
    if (subTitle) {
      newLegend.gradientTitles = [subTitle];
    }
  } else {
    newLegend.items = [...items];
  }

  delete newLegend.subTitle;
  delete newLegend.type;
  delete newLegend.categoryId;

  return newLegend;
};

const setLegendData = () => {
  const outLegends = {};

  getLegendEntries.value.forEach((elem) => {
    const { name, type, categoryId, items } = elem;

    if (!outLegends[name]) {
      outLegends[name] = initializeNewLegend(elem);
    } else {
      const legend = outLegends[name];

      if (type === 'gradient') {
        outLegends[name] = processGradientLegend(legend, elem);
      } else if (categoryId) {
        outLegends[name] = processCategoryLegend(legend, elem);
      } else {
        outLegends[name].items = [...legend.items, ...items];
      }
    }
  });

  return Object.values(outLegends);
};

const iconStyles = (icon) => {
  if (icon === 'mdi-checkbox-blank-circle') {
    return 'rounded-full';
  }
  if (icon === 'mdi-minus') {
    return 'rounded mt-[5px]';
  }
  if (icon === 'mdi-square') {
    return 'rounded-[2px]';
  }
};

const iconStylesChild = (icon) => {
  if (icon === 'mdi-checkbox-blank-circle') {
    return 'rounded-full w-3 h-3';
  }
  if (icon === 'mdi-minus') {
    return 'rounded w-3 h-0.5';
  }
  if (icon === 'mdi-square') {
    return 'rounded-[2px] w-3 h-3';
  }
};
</script>
