import {
  AQUAMARIN_60,
  CONSTRUCTION_YEAR,
  DEEP_BLUE_LIGHT,
  TAROCCO_40,
} from '@/configs/color-constants';
import { polygonClickEnabledStyle } from '@/apps/features/map/utils';

export const LAYER_KEY__NETWORK_AREA = 'network_area';

export default {
  [LAYER_KEY__NETWORK_AREA]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__NETWORK_AREA,
      sourceLayer: LAYER_KEY__NETWORK_AREA,
      layerId: LAYER_KEY__NETWORK_AREA,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    style: {
      default: polygonClickEnabledStyle([
        [
          'case',
          ['==', ['get', 'operator'], 'Netze BW GmbH'],
          DEEP_BLUE_LIGHT,
          TAROCCO_40,
        ],
      ]),
    },
    legend: {
      default: {
        name: 'Netzgebiete',
        unit: '',
        items: [
          {
            color: DEEP_BLUE_LIGHT,
            name: 'Netzgebiet Netze BW',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: TAROCCO_40,
            name: 'Netzgebiet Andere',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
    sidebar: {
      name: 'Netzgebiet',
      subtitles: ['plz_code', 'plz_name'],
      chips: [
        {
          title: 'Netzgebiet',
          active: true,
          items: [
            {
              title: 'Netzgebiet',
              active: true,
              items: [
                {
                  key: 'operator_location',
                },
                {
                  key: 'operator',
                  unit: '',
                },
              ],
            },
          ],
        },
      ],
    },
  },
};
