import { useToastStore } from '@/stores/toast-store';
import { axiosInstance } from '@/utils/axiosHelper';

let pendingAxiosCalls = 0;

export function setupAxiosInterceptors() {
  const { showToast } = useToastStore();

  axiosInstance.interceptors.response.use(
    (response) => {
      pendingAxiosCalls -= 1;
      if (pendingAxiosCalls === 0) {
        showToast({ message: '', show: false }); // Hide loading
      }

      if (response.status === 200 && response.data.message) {
        showToast({
          color: 'success',
          message: response.data.message,
          timeout: 3000,
          multiLine: true,
          outlined: true,
        });
      }

      if (response.status === 400 && response.data.message) {
        showToast({
          color: 'warning',
          message: response.data.message,
          timeout: 3000,
          multiLine: true,
          outlined: true,
        });
      }
      return response;
    },
    (error) => {
      pendingAxiosCalls -= 1;
      if (pendingAxiosCalls === 0) {
        showToast({ message: '', show: false }); // Hide loading
      }

      const errorResponses = {
        504: 'Die Operation ist zu aufwändig, tut uns leid. Für umfassende Analysen kontaktieren Sie uns bitte.',
        400: 'Die Daten konnten nicht verarbeitet werden. Bitte überprüfen Sie ihre Eingaben.',
        500: 'Ein interner Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
      };

      if (error.response && errorResponses[error.response.status]) {
        showToast({
          color: 'error',
          message: errorResponses[error.response.status],
          multiLine: true,
          outlined: true,
        });
      }

      return Promise.reject(error);
    },
  );
}
