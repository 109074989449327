import building from './buildings';
import potential from './potential';
import geographicalLevel from './geographical-level';
import estates from './estates';
import infrastructure from './infrastructure';
import marker from '@/apps/features/map/layer-config/marker';
import baseData from '@/apps/features/map/layer-config/base-data';
import existingPowerPlants from '@/apps/usecase-1/map/layer-config/existing-power-plants';

export default {
  ...baseData,
  ...geographicalLevel,
  ...existingPowerPlants,
  ...potential,
  ...estates,
  ...infrastructure,
  ...building,
  ...marker,
};
