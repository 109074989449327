<template>
  <div
    v-if="mapActionActive"
    class="bg-white rounded-[8px] top-[80px] h-fit w-[360px] absolute z-10 p-2.5 flex flex-col gap-2.5 standard-elevation-2 overflow-y-auto hide-scrollbar"
    :style="panelPositions['mapActionActive']"
  >
    <PvProjectForm v-if="mapStore.showCustomPvProjectForm" :map-store />
    <MeasuringToolForm v-else-if="mapStore.showMeasureToolForm" :map-store />
    <ExternalProjectForm
      v-else-if="mapStore.showExternalProjectForm"
      :map-store
    />
    <ClusterForm v-else-if="mapStore.showClusterForm" :map-store />
    <EstateSearchForm v-else-if="mapStore.showEstateSearchForm" :map-store />
  </div>
</template>

<script setup>
import { usePanel } from '@/composables/usePanel';
import ClusterForm from '../ClusterForm.vue';
import EstateSearchForm from '../EstateSearchForm.vue';
import ExternalProjectForm from '../ExternalProjectForm.vue';
import MeasuringToolForm from '../MeasuringToolForm.vue';
import PvProjectForm from '../PvProjectForm.vue';
import { toRefs, watch } from 'vue';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { mapActionActive } = toRefs(props.mapStore);

const { panelPositions, addPanel, removePanel } = usePanel(
  'mapActionActive',
  true,
);

watch(mapActionActive, (newValue) => {
  if (newValue) {
    addPanel();
  } else {
    removePanel();
  }
});
</script>
