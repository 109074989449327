import {
  AZUR_BLUE_30,
  LIND_GREEN_60,
  TAROCCO_60,
} from '@/configs/color-constants';

export const LAYER_KEY__EXTERNAL_PROJECT = 'external_project';

export default {
  [LAYER_KEY__EXTERNAL_PROJECT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__EXTERNAL_PROJECT,
      geoJSONSource: true,
      layerId: LAYER_KEY__EXTERNAL_PROJECT,
      visibility: 'none',
      dependsOnScenario: false,
    },
    sidebar: {
      name: 'Externes Projekt',
      subtitles: ['id', 'name', 'project_developer'],
      chips: [
        {
          title: 'Projektinformationen',
          active: true,
          items: [
            {
              title: 'Projektinformationen',
              active: true,
              items: [
                { key: 'type' },
                {
                  key: 'power_kw',
                  unit: 'kWh(el)/a',
                  decimal: 0,
                },
                {
                  key: 'power_connection_kw',
                  unit: 'kWh(el)/a',
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: {
        circleColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          AZUR_BLUE_30,
          TAROCCO_60,
        ],
        circleStrokeWidth: 2,
        circleStrokeColor: [
          'case',
          ['boolean', ['feature-state', 'marked'], false],
          LIND_GREEN_60,
          TAROCCO_60,
        ],
        circleRadius: 8,
      },
    },
    legend: {
      default: {
        name: 'Externe Projekte',
        unit: '',
        items: [
          {
            color: TAROCCO_60,
            name: 'Projekt',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
};
