<template>
  <div
    class="flex justify-between w-full panelItem py-1 border-b last:border-b-0 border-line-disabled-neutral singleItemcustom"
  >
    <div class="flex items-center relative body-3" :style="{ width: '60%' }">
      {{ item.label }}
    </div>

    <div
      class="flex items-center"
      :style="{ width: '38%' }"
      :class="item.type !== 'boolean' ? 'justify-start' : 'justify-end'"
    >
      <div class="flex w-full caption-1">
        <span class="whitespace-nowrap">{{ item.unit }}</span>

        <ToolTip
          class="flex justify-end w-fit ml-auto white-space-normal"
          :class="[
            item.color &&
              `${item.color(props.item.value)} p-2.5 rounded-md standard-elevation-0-dark`,
          ]"
          :tooltip-text="
            formattedValue.length > 15 &&
            (typeof item.value === 'number'
              ? toLocaleStr(item.value)
              : item.value)
          "
        >
          {{ formattedValue.length <= 15 ? formattedValue : truncatedValue }}
        </ToolTip>
      </div>
    </div>
  </div>
</template>

<script setup>
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { toLocaleStr } from '@/utils/formatUtils';
import { computed } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

// Computed property for formatted value
const formattedValue = computed(() => {
  return props.item.value ? props.item.value : '';
});

// Computed property to return truncated string if too long
const truncatedValue = computed(() => {
  if (!props.item.value) return '';
  const formatted = formattedValue.value;
  return formatted.length > 15 ? `${formatted.slice(0, 14)}...` : formatted;
});
</script>
