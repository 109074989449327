<template>
  <ButtonEl
    :text="text"
    :icon="icon"
    :color="color"
    :variant="variant"
    @click="downloadCsv"
  />
</template>

<script setup>
import ButtonEl from './button/ButtonEl.vue';

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  filename: {
    type: String,
    default: 'export',
  },
  text: {
    type: String,
    default: 'Liste exportieren',
  },
  icon: {
    type: String,
    default: 'download',
  },
  color: {
    type: String,
    default: 'color2',
  },
  variant: {
    type: String,
    default: 'secondary',
  },
});

function downloadCsv() {
  // Convert data to CSV format
  const headers = Object.keys(props.data[0]);
  const csvContent = [
    headers.join(','),
    ...props.data.map((row) =>
      headers
        .map((header) => {
          const value = row[header];
          // Handle special cases like arrays, objects, or values with commas
          if (typeof value === 'object') {
            return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
          }
          return typeof value === 'string' ? `"${value}"` : value;
        })
        .join(','),
    ),
  ].join('\n');

  // Create and trigger download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', props.filename + '.csv');
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
</script>
