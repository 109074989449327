import { createApp } from 'vue';
import { createPinia } from 'pinia';

import VueCookies from 'vue-cookies';
import App from '@/App.vue';
import router from '@/router';

// UTILS
import {
  clickOnMapInTest,
  clickOnMapInTestRight,
  setQueryRenderedFeatures,
} from '@/utils/cypress-utils';
import { setupAxiosInterceptors } from '@/utils/axios-interceptors';

// CONFIGS
import { initSentry } from '@/configs/sentry-config';

// PLUGINS
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/vue-i18n';
import ECharts from '@/plugins/echarts';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';

// STYLE
import '@/assets/styles/index.scss';
import 'material-icons/iconfont/material-icons.css';
import 'material-symbols';

const app = createApp(App);
const pinia = createPinia();

app.component('VChart', ECharts);

app
  .use(i18n)
  .use(vuetify)
  .use(router)
  .use(VueCookies, { expires: '7d' })
  .use(pinia)
  .use(autoAnimatePlugin);

setupAxiosInterceptors();
initSentry(app, router);

// Assign Vue instance to window object for Cypress testing
if (window.Cypress) {
  window.__vue_app__ = app;
  window.cypress_utils = {
    setQueryRenderedFeatures,
    clickOnMapInTest,
    clickOnMapInTestRight,
  };
}

router.isReady().then(() => app.mount('#app'));
