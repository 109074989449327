export const LAYER_KEY__AGRICULTURAL_PLOT = 'agricultural_plot';
export const LAYER_KEY__DISADVANTAGE_AREA = 'disadvantage_area';

import {
  LIND_GREEN_60,
  IMPULSE_ORGANGE_LIGHT,
  TAROCCO_40,
  GREY_BEIGE_90,
} from '@/configs/color-constants';

export default {
  [LAYER_KEY__DISADVANTAGE_AREA]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__DISADVANTAGE_AREA,
      sourceLayer: LAYER_KEY__DISADVANTAGE_AREA,
      layerId: LAYER_KEY__DISADVANTAGE_AREA,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    style: {
      default: {
        fillColor: [
          'match',
          ['get', 'disadvantage_category'],
          0,
          TAROCCO_40,
          1,
          IMPULSE_ORGANGE_LIGHT,
          'rgba(255,255,255,0)',
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
    },
    legend: {
      default: {
        name: 'Besondere Flächenqualifikationen',
        unit: '',
        items: [
          {
            color: TAROCCO_40,
            name: 'Vollständig benachteiligtes Gebiet',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: IMPULSE_ORGANGE_LIGHT,
            name: 'Mit benachteiligten Teilgebieten',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
  [LAYER_KEY__AGRICULTURAL_PLOT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__AGRICULTURAL_PLOT,
      sourceLayer: LAYER_KEY__AGRICULTURAL_PLOT,
      layerId: LAYER_KEY__AGRICULTURAL_PLOT,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    style: {
      default: {
        fillColor: [
          'match',
          ['get', 'landcover'],
          0,
          GREY_BEIGE_90,
          1,
          LIND_GREEN_60,
          'rgba(255,255,255,0)',
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
    },
    legend: {
      default: {
        name: 'Besondere Flächenqualifikationen',
        unit: '',
        items: [
          {
            color: GREY_BEIGE_90,
            name: 'Ackerland',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: LIND_GREEN_60,
            name: 'Grünland',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
};
