import MapboxDraw from '@mapbox/mapbox-gl-draw';
import FreehandMode from './free-hand';
const { CommonSelectors, isEventAtCoordinates } = MapboxDraw.lib;
const FeaturesWithin = Object.assign({}, FreehandMode);

FeaturesWithin.onSetup = function (opts) {
  const onSetup = FreehandMode.onSetup.bind(this);
  const state = onSetup();
  state.drawCreateCallback = opts.drawCreateCallback;
  return state;
};

FeaturesWithin.onKeyUp = function (state, e) {
  if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.polygon.id], { silent: true });
    this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT);
  } else if (CommonSelectors.isEnterKey(e)) {
    if (typeof state.drawCreateCallback === 'function') {
      state.drawCreateCallback(state.polygon.toGeoJSON());
    }
    this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id],
    });
    this.deleteFeature([state.polygon.id], { silent: true });
  }
};

FeaturesWithin.onMouseUp = function (state, e) {
  if (typeof state.drawCreateCallback === 'function') {
    state.drawCreateCallback(state.polygon.toGeoJSON());
  }
  if (state.dragMoving) {
    this.simplify(state.polygon);
    this.fireUpdate();
    this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id],
    });
    this.deleteFeature([state.polygon.id], { silent: true });
  }
};

FeaturesWithin.clickAnywhere = function (state, e) {
  if (
    state.currentVertexPosition > 0 &&
    isEventAtCoordinates(
      e,
      state.polygon.coordinates[0][state.currentVertexPosition - 1],
    )
  ) {
    this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id],
    });
    this.deleteFeature([state.polygon.id], { silent: true });
  }
  this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat,
  );
  state.currentVertexPosition++;
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat,
  );
};

FeaturesWithin.clickOnVertex = function (state) {
  this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
    featureIds: [state.polygon.id],
  });
  this.deleteFeature([state.polygon.id], { silent: true });
};

FeaturesWithin.clickOnVertex = function (state) {
  this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
    featureIds: [state.polygon.id],
  });
  this.deleteFeature([state.polygon.id], { silent: true });
};

export default FeaturesWithin;
