export function getScalingFactorAssumptionsForDisplay(data) {
  return [
    {
      title: 'Elektrifizierung Mobilität',
      item: {
        label: 'Skalierungsfaktor',
        value: String(data.electrification_mobility),
        unit: '',
      },
    },
    {
      title: 'Elektrifizierung Wärme Einzelversorgung',
      item: {
        label: 'Skalierungsfaktor',
        value: String(data.electrification_heat_single),
        unit: '',
      },
    },
    {
      title: 'Elektrifizierung Wärmenetze',
      item: {
        label: 'Skalierungsfaktor',
        value: String(data.electrification_heat_network),
        unit: '',
      },
    },
  ];
}

export function getTotalIncreaseAssumptionsForDisplay(
  selectedAssumption,
  potentials,
  scenarioCountYears,
) {
  return [
    {
      title: 'PV-Aufdach',
      items: createItems(
        selectedAssumption.pv_roof_mw,
        potentials.potentialPvRoof,
        scenarioCountYears,
      ),
    },
    {
      title: 'PV-Freifläche',
      items: createItems(
        selectedAssumption.pv_free_mw,
        potentials.potentialPvFree,
        scenarioCountYears,
      ),
    },
    {
      title: 'Wind',
      items: createItems(
        selectedAssumption.wind_mw,
        potentials.potentialWind,
        scenarioCountYears,
      ),
    },
  ];
}

function createItems(selectedValue, potentialValue, scenarioCountYears) {
  console.log(potentialValue);
  console.log(selectedValue);
  return [
    {
      label: 'Potenzial',
      value:
        potentialValue > 0
          ? formatValue((selectedValue / potentialValue) * 100)
          : '0',
      unit: '%',
      width: 'w-[20%]',
    },
    {
      label: 'Jährlicher Zubau',
      value: formatValue(selectedValue / scenarioCountYears),
      unit: 'MW/a',
      width: 'w-[35%]',
    },
    {
      label: 'Absoluter Zubau bis Zieljahr',
      value: formatValue(selectedValue),
      unit: 'MW',
      width: 'w-[40%]',
    },
  ];
}

function formatValue(val) {
  const noDecimals = val % 1 === 0;
  if (noDecimals) return val.toString();
  return val
    .toFixed(2)
    .replace(/\.?0+$/, '')
    .replace('.', ',');
}
