export const contentStructureAssumptionsGeneral = [
  {
    tab: {
      title: 'Basisparameter',
    },
    blocks: [
      {
        flow: 'horizontal',
        gap: 10,
        children: [
          {
            subTitle: 'Investitionskosten',
            subTitleTag: 'h2',
            subTitleColor: 'text-color1',
            gap: 5,
            fields: [
              'invest_pv_roof_eur_kw',
              'invest_pv_free_eur_kw',
              'invest_wind_eur_kw',
              'invest_biomass_eur_kw',
            ],
          },
          {
            subTitle: 'Netzplanung',
            subTitleTag: 'h2',
            subTitleColor: 'text-color1',
            gap: 5,
            fields: [
              'specific_power_substation_mw',
              'area_per_substation_ha',
              'specific_power_trafo_mw',
              'dc_ac_ratio',
            ],
          },
          {
            subTitle: 'Sonstige',
            subTitleTag: 'h2',
            subTitleColor: 'text-color1',
            gap: 5,
            fields: ['eeg_municipality_levy_ct_kwh'],
          },
        ],
      },
    ],
  },
];
