<template>
  <div ref="profileOptionsContainer" class="flex gap-5 w-fit relative">
    <PersonalAvatar data-test="profile-options" @click="toggleProfileOptions" />
    <transition name="fade" mode="out-in">
      <div
        v-if="isOpen"
        class="absolute top-0 standard-elevation-2 right-0 rounded-[8px] overflow-hidden bg-default z-[1000]"
      >
        <div class="p-2 cursor-pointer" @click="toggleProfileOptions">
          <div
            class="flex flex-col items-center text-neutral bg-subtle p-2.5 rounded-[6px] body-3"
          >
            <span>Angemeldet als:</span>
            <span>{{ profile?.username }}</span>
          </div>
        </div>
        <RouterLink to="/profile" class="more-options-classes">
          <div class="body-2 text-neutral mt-0.5">Profil</div>
          <IconWrapper
            icon="account_box"
            fill="text-neutral"
            type="filled"
            :size="20"
          />
        </RouterLink>
        <RouterLink to="/" class="more-options-classes">
          <div class="body-2 text-neutral mt-0.5">Einstellungen</div>
          <IconWrapper
            :size="20"
            icon="settings"
            fill="text-neutral"
            type="filled"
          />
        </RouterLink>
        <div class="more-options-classes" data-test="logout" @click="logout">
          <div class="body-2 text-neutral mt-[1px]">Logout</div>
          <IconWrapper :size="20" icon="logout" fill="text-neutral" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup>
import PersonalAvatar from '@/components/global/PersonalAvatar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { computed, onMounted, ref } from 'vue';
import { useAuth } from '@/apps/auth/auth-store';
import { useUsers } from '@/apps/auth/users-store';
import { useRouter } from 'vue-router';
import { onClickOutside } from '@vueuse/core';

const authStore = useAuth();
const usersStore = useUsers();
const router = useRouter();

const profile = computed(() => usersStore.profile);
const isOpen = ref(false);
const profileOptionsContainer = ref(null);

onClickOutside(profileOptionsContainer, () => {
  isOpen.value = false;
});

onMounted(() => {
  router.afterEach(() => {
    isOpen.value = false;
  });
});

function toggleProfileOptions() {
  isOpen.value = !isOpen.value;
}

function logout() {
  authStore.logoutUser().then(() => {
    router.push('/login');
  });
}
</script>

<style lang="scss" scoped>
.more-options-classes {
  @apply flex gap-2 items-center justify-between cursor-pointer p-2 bg-default hover:bg-active-area;
}
</style>
