<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="2" fill="#000099" />
    <path
      d="M10.6352 12C10.1465 11.5045 9.8155 10.8754 9.68381 10.192C9.55212 9.50868 9.62565 8.80163 9.89513 8.16C10.1646 7.51837 10.618 6.97086 11.1981 6.58648C11.7783 6.20209 12.4592 5.99803 13.1551 6.00001H19.5001V8.11501H13.1551C12.7812 8.11501 12.4226 8.26356 12.1581 8.52799C11.8937 8.79241 11.7451 9.15105 11.7451 9.52501C11.7451 9.89896 11.8937 10.2576 12.1581 10.522C12.4226 10.7864 12.7812 10.935 13.1551 10.935H17.3701V13.05H13.1551C12.97 13.05 12.7866 13.0865 12.6156 13.1573C12.4445 13.2282 12.2891 13.332 12.1581 13.463C12.0272 13.5939 11.9233 13.7493 11.8525 13.9204C11.7816 14.0915 11.7451 14.2748 11.7451 14.46C11.7451 14.6452 11.7816 14.8285 11.8525 14.9996C11.9233 15.1706 12.0272 15.3261 12.1581 15.457C12.2891 15.5879 12.4445 15.6918 12.6156 15.7627C12.7866 15.8335 12.97 15.87 13.1551 15.87H19.5001V18H13.1551C12.4592 18.002 11.7783 17.7979 11.1981 17.4135C10.618 17.0291 10.1646 16.4816 9.89513 15.84C9.62565 15.1984 9.55212 14.4913 9.68381 13.808C9.8155 13.1246 10.1465 12.4955 10.6352 12Z"
      fill="white"
    />
    <path
      d="M7.75499 12.8996V11.0996H3.9C3.6613 11.0996 3.43239 11.1944 3.2636 11.3632C3.09482 11.532 3 11.7609 3 11.9996C3 12.2383 3.09482 12.4672 3.2636 12.636C3.43239 12.8048 3.6613 12.8996 3.9 12.8996H7.75499Z"
      fill="#FF9900"
    />
  </svg>
</template>
