import locales from './locales';
import constraints from './map.constraints';

export default {
  // product display information
  product: {
    name: 'PadE',
    version: '1.0.0',
  },

  // locales configs
  locales,

  // constraints configs for map view
  constraints,
};
