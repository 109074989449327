import { sum } from 'lodash';
import { POWER_DEMAND, RENEWABLE_GENERATION } from '@/configs/color-constants';
import { useProjectResult } from '@/apps/usecase-1/projects/project-result-detail/project-result-store';

export function useSeriesChart() {
  const resultStore = useProjectResult();

  function seriesChart(
    generationPotentialTotal,
    demandTotal,
    demandStatusQuo,
    demandElectrificationMobility,
    demandElectrificationHeatSingle,
    demandElectrificationHeatNetwork,
    baseDemandDelta,
    generationPvRoof,
    generationPvFree,
    generationWind,
    generationWater,
    generationBiomass,
    generationTotal,
    totalIncreasePvRoof,
    totalIncreasePvFree,
    totalIncreaseWind,
  ) {
    let delta = generationPotentialTotal - demandTotal;
    let hiddenDelta = demandTotal;
    let deltaColor = '#70A638';
    if (delta < 0) {
      hiddenDelta = generationPotentialTotal;
      delta = delta * -1;
      deltaColor = '#f32f2f';
    }
    let hiddenStackDemandDeltaBase = sum([
      demandStatusQuo,
      demandElectrificationMobility,
      demandElectrificationHeatSingle,
      demandElectrificationHeatNetwork,
    ]);
    if (baseDemandDelta > 0) {
      hiddenStackDemandDeltaBase -= baseDemandDelta;
    }
    const hiddenStacks = [
      0,
      demandStatusQuo,
      demandStatusQuo + demandElectrificationMobility,
      demandStatusQuo +
        demandElectrificationMobility +
        demandElectrificationHeatSingle,
      hiddenStackDemandDeltaBase,
      0,
      0,
      generationTotal,
      0,
      hiddenDelta,
    ];
    return {
      visibleDataIsStacked: true,
      visibleStacks: [
        {
          name: 'Status Quo',
          itemStyle: {
            color: POWER_DEMAND.TOTAL,
          },
          data: [demandStatusQuo, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          markLine: {
            symbol: 'none',
            silent: true,
            animation: false,
            data: [
              [
                {
                  coord: [5, demandTotal],
                  lineStyle: {
                    color: 'red',
                  },
                  label: { show: false },
                },
                { coord: [9, demandTotal] },
              ],
              [
                {
                  coord: [5, generationPotentialTotal],
                  lineStyle: {
                    color: 'red',
                  },
                  label: { show: false },
                },
                { coord: [9, generationPotentialTotal] },
              ],
            ],
          },
        },
        {
          name: 'Elektrifizierung Mobilität',
          itemStyle: {
            color: POWER_DEMAND.ELECTRIFICATION_MOBILITY,
          },
          data: [0, demandElectrificationMobility, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: 'Elektrifizierung Wärme Einzeilvers.',
          itemStyle: {
            color: POWER_DEMAND.ELECTRIFICATION_HEAT_SINGLE,
          },
          data: [0, 0, demandElectrificationHeatSingle, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: 'Elektrifizierung Wärmenetz',
          itemStyle: {
            color: POWER_DEMAND.ELECTRIFICATION_HEAT_NETWORK,
          },
          data: [0, 0, 0, demandElectrificationHeatNetwork, 0, 0, 0, 0, 0, 0],
        },
        {
          name:
            baseDemandDelta > 0
              ? 'Effizienzsteigerung Strom konv.'
              : 'Zuwachs Strom konv. Demogr./Wirtschaft',
          itemStyle: {
            color: POWER_DEMAND.EFFICIENCY,
          },
          data: [0, 0, 0, 0, Math.abs(baseDemandDelta), 0, 0, 0, 0, 0],
        },
        {
          name: `Nachfrage ${resultStore.activeProject.target_year}`,
          itemStyle: {
            color: POWER_DEMAND.TOTAL,
          },
          data: [0, 0, 0, 0, 0, demandTotal, 0, 0, 0, 0],
        },
        {
          name: 'PV-Aufdach',
          itemStyle: {
            color: RENEWABLE_GENERATION.PV_ROOF,
          },
          data: [0, 0, 0, 0, 0, 0, generationPvRoof, totalIncreasePvRoof, 0, 0],
        },
        {
          name: 'PV-Freifläche',
          itemStyle: {
            color: RENEWABLE_GENERATION.PV_FREE,
          },
          data: [0, 0, 0, 0, 0, 0, generationPvFree, totalIncreasePvFree, 0, 0],
        },
        {
          name: 'Wind',
          itemStyle: {
            color: RENEWABLE_GENERATION.WIND,
          },
          data: [0, 0, 0, 0, 0, 0, generationWind, totalIncreaseWind, 0, 0],
        },
        {
          name: 'Wasser',
          itemStyle: {
            color: RENEWABLE_GENERATION.HYDRO,
          },
          data: [0, 0, 0, 0, 0, 0, generationWater, 0, 0, 0],
        },
        {
          name: 'Biomasse',
          itemStyle: {
            color: RENEWABLE_GENERATION.BIOMASS,
          },
          data: [0, 0, 0, 0, 0, 0, generationBiomass, 0, 0, 0],
        },
        {
          name: 'Gesamterzeugung',
          itemStyle: {
            color: RENEWABLE_GENERATION.TOTAL,
          },
          data: [0, 0, 0, 0, 0, 0, 0, 0, generationPotentialTotal, 0, 0],
        },
        {
          name: 'Delta',
          itemStyle: {
            color: deltaColor,
          },
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, delta],
        },
      ],
      hiddenStacks,
    };
  }

  return { seriesChart };
}
