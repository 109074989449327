<template>
  <div class="flex flex-col h-fit">
    <div class="flex items-end">
      <TheLabel :label :for-input="id" :disabled />
      <div v-if="tooltipText" class="relative">
        <div class="absolute z-10 bottom-0 left-0.5">
          <ToolTipAdvanced
            v-model="tooltipVisible"
            custom-tooltip-classes="text-inverted-color1 bg-button-primary-default-color1 p-2 w-[280px] rounded mr-1"
            :offset-x="-170"
            :offset-y="-20"
            @mouseenter="openTooltip"
            @mouseleave="closeTooltip"
          >
            <template #activator>
              <IconWrapper
                icon="info"
                type="round"
                fill="text-button-primary-default-neutral"
              />
            </template>
            <template #content> {{ tooltipText }}</template>
          </ToolTipAdvanced>
        </div>
      </div>
    </div>

    <div class="relative" :class="['input-field-parent', inputWrapperClasses]">
      <div class="flex relative items-center gap-[6px] input-field-wrapper">
        <div class="relative w-full flex items-center">
          <input
            :id="id"
            ref="inputField"
            :value="displayValue"
            :type="type"
            class="input-field"
            :class="inputClasses"
            :placeholder="placeholder"
            @keydown="restrictInput"
            @input="displayValue = $event.target.value"
            @focus="inputActive = true"
            @blur="clickOutside"
          />
          <!-- suffix-->
          <div
            v-if="suffix"
            class="input-suffix"
            :class="suffixClasses"
            @click="emit('iconClick')"
          >
            <span v-if="!suffixIcon">{{ suffix }}</span>
            <IconWrapper
              v-else
              :icon="suffix"
              :size="size === 'm' || size === 's' ? 18 : 24"
            />
          </div>
        </div>

        <!-- error text -->
        <span
          v-if="errorText"
          class="absolute bottom-[-17px] right-0 text-xxs text-spot-error whitespace-nowrap"
        >
          {{ errorText }}
        </span>
      </div>
      <div
        v-if="inputActive && searchSuggestions.length > 0"
        class="max-h-[135px] w-full bg-default overflow-auto absolute standard-elevation-1 z-50"
      >
        <p
          v-for="item in searchSuggestions"
          :key="item"
          class="py-2 px-3 search-item cursor-pointer"
          @pointerdown="
            (e) => {
              currentInput = e.target.textContent;
              emit('clicked:searchSuggestion', currentInput);
            }
          "
        >
          {{ item }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import TheLabel from '../label/TheLabel.vue';
import { useValidation } from '@/composables/formElementValidation';
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue';
import ToolTipAdvanced from '../toolTipAdvanced/ToolTipAdvanced.vue';
import IconWrapper from '../IconWrapper/IconWrapper.vue';

const { isValid, errorText, checkValidationRules, mapValidationRules } =
  useValidation();

const props = defineProps({
  size: {
    type: String,
    default: 'l',
    validate: (value) => ['s', 'm', 'l', 'xl'].includes(value),
  },
  rules: {
    type: Object,
    default: null,
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: [String, Number],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  suffix: {
    type: [String, Object],
    default: null,
  },
  suffixClickable: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  tooltipIconFill: {
    type: String,
    default: 'currentColor',
  },
  tooltipText: {
    type: String,
    default: null,
  },
  tooltipIcon: {
    type: [String, Object],
    default: 'HelpIcon',
  },
  customBackground: {
    type: String,
    default: null,
  },
  initialInput: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  inputType: {
    type: String,
    default: 'string',
    validator: (value) =>
      ['string', 'integer', 'float', 'percent'].includes(value),
  },
  decimals: {
    type: Number,
    default: 6,
  },
  suffixIcon: {
    type: Boolean,
    default: false,
  },
  suffixColor: {
    type: String,
    default: 'text-disabled-neutral',
  },
  suffixLeft: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: null,
  },
  searchSuggestions: {
    type: Array,
    default: () => [],
  },
  positiveOnly: {
    type: Boolean,
    default: false,
  },
  borderBottomStaticColor: {
    type: String,
    default: null,
  },
});

const emit = defineEmits([
  'showDropdown',
  'update:modelValue',
  'iconClick',
  'clicked:searchSuggestion',
  'blur',
]);

const currentInput = ref(props.modelValue);
const isMounted = ref(false);
const inputActive = ref(false);
const keyBoardVals = [
  'Delete',
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Tab',
];

const inputWrapperClasses = computed(() => {
  return {
    'input-size-s': props.size === 's',
    'input-size-m': props.size === 'm',
    'input-size-l': props.size === 'l',
    'input-size-xl': props.size === 'xl',
    disabled: props.disabled,
  };
});

const inputClasses = computed(() => {
  return {
    [props.customBackground]: props.customBackground,
    invalid: errorText.value,
    'indent-6': props.suffixLeft,
    [`border-b-2 ${props.borderBottomStaticColor} static-border`]:
      props.borderBottomStaticColor,
  };
});

const suffixClasses = computed(() => {
  return {
    'pointer-events-none': !props.suffixClickable,
    'cursor-pointer': props.suffixClickable,
    'pt-[3px] right-0': !props.suffixLeft,
    'left-0': props.suffixLeft,
    [props.suffixColor]: true,
  };
});

const displayValue = computed({
  get() {
    if (currentInput.value === null) {
      return '';
    }
    if (
      (props.inputType === 'float' || props.inputType === 'percent') &&
      typeof currentInput.value === 'number'
    ) {
      return currentInput.value;
    }
    return currentInput.value;
  },
  set(value) {
    if (value === '') {
      // If input is empty, set currentInput to empty string and emit null
      currentInput.value = '';
      emit('update:modelValue', null);
    } else if (
      (props.inputType === 'float' || props.inputType === 'percent') &&
      value !== '-' && // Ensure '-' is not accepted as a value
      (!props.positiveOnly || parseFloat(value.replace(',', '.')) >= 0) // Check for positive value if positiveOnly is true
    ) {
      if (value.startsWith(',')) {
        currentInput.value = '0,'; // Handle case where input starts with a comma
      } else {
        currentInput.value = value;
      }

      // Convert the currentInput into a float and emit
      const floatValue = props.inputType === 'percent' ? 100 : 1;
      emit(
        'update:modelValue',
        formattedStringToNumber(currentInput.value) / floatValue,
      );
    } else if (
      props.inputType === 'integer' &&
      value !== '-' && // Ensure '-' is not accepted as a value
      (!props.positiveOnly || parseInt(value, 10) >= 0) // Check for positive value if positiveOnly is true
    ) {
      currentInput.value = value;

      // Convert the currentInput into an integer and emit
      emit('update:modelValue', formattedStringToNumber(currentInput.value));
    } else {
      // For string or other input types, directly assign and emit the value
      currentInput.value = value;
      emit('update:modelValue', currentInput.value);
    }
  },
});

function formattedStringToNumber(formattedString) {
  // Remove the thousands separator
  let stringWithoutThousandSeparator = formattedString.replace(/\./g, '');

  // Replace decimal comma with a decimal point
  stringWithoutThousandSeparator = stringWithoutThousandSeparator.replace(
    /,/,
    '.',
  );

  // Parse the string to a number
  return parseFloat(stringWithoutThousandSeparator);
}

function removeTrailingDotOrComma(input, inputType) {
  if (
    inputType === 'float' ||
    inputType === 'percent' ||
    inputType === 'floatPercent'
  ) {
    return (
      input
        // Remove leading zeros, taking into account all thousand separators
        .replace(/^0+(?=(?:\d{3}\.)*\d+,)/, '')
        // Remove trailing dot or comma
        .replace(/[.,]$/, '')
        // Remove trailing minus
        .replace(/-$/, '')
    );
  } else {
    return (
      input
        // Remove trailing dot or comma
        .replace(/[.,]$/, '')
    );
  }
}

function restrictInput(event) {
  const char = event.key;
  const currentValue = event.target.value;
  const cursorPosition = event.target.selectionStart;
  const selectionEnd = event.target.selectionEnd;
  const isTextSelected = cursorPosition !== selectionEnd;
  const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey; // Checks for Ctrl or Command key
  let regex;

  // Allow control keys like Delete, Backspace, Arrow keys, etc.
  // Also allow cut (Ctrl+X), copy (Ctrl+C), and paste (Ctrl+V) operations.
  if (
    keyBoardVals.includes(char) ||
    (isCtrlOrCmdPressed &&
      (char === 'c' ||
        char === 'C' ||
        char === 'v' ||
        char === 'V' ||
        char === 'x' ||
        char === 'X' ||
        char === 'a' ||
        char === 'A'))
  ) {
    return; // Allow the default action
  }

  if (props.inputType === 'float' || props.inputType === 'percent') {
    // Prevent negative sign input if positiveOnly is true
    if (props.positiveOnly && char === '-') {
      event.preventDefault();
      return;
    }

    // If a comma already exists in the current value, disallow another comma
    if (char === ',' && currentValue.includes(',') && !isTextSelected) {
      event.preventDefault();
      return;
    }

    const parts = currentValue.split(',');

    // Check if the cursor is after the comma
    const commaPosition = currentValue.indexOf(',');

    if (
      cursorPosition > commaPosition && // If cursor is after comma
      parts[1] && // And there is a decimal part
      parts[1].length >= props.decimals && // And the decimal part's length has reached the limit
      !isTextSelected // And no text is selected
    ) {
      event.preventDefault(); // Prevent further input
      return;
    }

    // Allow both numbers and a comma, disallow negative sign if positiveOnly is true
    regex = props.positiveOnly ? /^[0-9,]+$/ : /^[-0-9,]+$/;
  } else if (props.inputType === 'integer') {
    // Prevent negative sign input if positiveOnly is true
    if (props.positiveOnly && char === '-') {
      event.preventDefault();
      return;
    }

    // Allow multiple digits for integer input, disallow negative sign if positiveOnly is true
    regex = props.positiveOnly ? /^[0-9]+$/ : /^[-0-9]+$/;
  } else if (props.inputType === 'string') {
    regex = /.*/; // Allow any character for string input
  }

  // Prevent the default action if the character doesn't match the regex
  if (!regex.test(char)) {
    event.preventDefault();
  }
}

function validate(setErrorText) {
  checkValidationRules(
    mapValidationRules(props.rules),
    props.modelValue,
    setErrorText,
  );
  return isValid.value;
}

function clickOutside() {
  emit('blur');
  if (typeof displayValue.value === 'string') {
    displayValue.value = displayValue.value.trim();
    if (props.inputType === 'float' || props.inputType === 'percent') {
      displayValue.value = removeTrailingDotOrComma(
        displayValue.value,
        'floatPercent',
      );
    }
  }
  inputActive.value = false;
}

defineExpose({ focusInput });
const inputField = ref(null);

function focusInput() {
  if (inputField.value) {
    inputField.value.focus();
  }
}

const registerValidator = inject('registerValidator', null);
const unregisterValidator = inject('unregisterValidator', null);
const uniqueId = Math.random().toString(36).substring(2, 11);

onMounted(() => {
  isMounted.value = true;
});

onUnmounted(() => {
  if (unregisterValidator) {
    unregisterValidator(uniqueId);
  }
});

function registerValidatorAction() {
  if (registerValidator) {
    registerValidator(uniqueId, validate(isMounted.value));
  } else {
    validate(isMounted.value);
  }
}

const tooltipVisible = ref(false);

function openTooltip() {
  tooltipVisible.value = true;
}

function closeTooltip() {
  tooltipVisible.value = false;
}

watch(
  () => currentInput.value,
  () => {
    if (
      props.inputType === 'string' &&
      typeof currentInput.value === 'string'
    ) {
      currentInput.value = currentInput.value.replace(/ {2,}/g, ' ');
    } else if (props.inputType === 'integer') {
      currentInput.value = removeTrailingDotOrComma(
        currentInput.value,
        'integer',
      );
    }
  },
);

watch(
  () => props.modelValue,
  (newValue) => {
    registerValidatorAction();
    if (newValue === null || typeof newValue === 'undefined') {
      currentInput.value = '';
      return;
    }

    if (props.inputType === 'float' || props.inputType === 'percent') {
      if (typeof newValue === 'number') {
        if (props.inputType === 'percent') {
          currentInput.value = (newValue * 100).toLocaleString('de-DE', {
            maximumFractionDigits: props.decimals,
          });
        } else {
          currentInput.value = newValue.toLocaleString('de-DE', {
            maximumFractionDigits: props.decimals,
          });
        }
      }
    } else if (props.inputType === 'integer') {
      currentInput.value = newValue.toLocaleString('de-DE');
    } else {
      currentInput.value = newValue;
    }
  },
  { immediate: true },
);
</script>

<style scoped lang="scss">
@use '@/assets/styles';

.input-field-parent {
  @apply text-neutral;

  &.disabled {
    pointer-events: none;
    @apply text-disabled-neutral;
    .input-field {
      @apply bg-subtle text-disabled-neutral border-line-disabled-neutral;
      &::placeholder {
        @apply text-disabled-neutral;
      }
    }
  }

  &.input-size-s {
    @extend .body-3;

    .input-field-wrapper .input-field {
      height: 24px;
      padding: 9px 6px 6px 6px;

      &:hover:not(.static-border) {
        padding-top: 10px;
      }

      &:focus:not(.static-border) {
        padding-top: 11px;
      }
    }
  }

  &.input-size-m {
    @extend .body-3;

    .input-field-wrapper .input-field {
      height: 32px;
      padding: 11px 8px 8px 8px;

      &:hover:not(.static-border) {
        padding-top: 12px;
      }

      &:focus:not(.static-border) {
        padding-top: 13px;
      }
    }
  }

  &.input-size-l {
    @extend .body-2;

    .input-field-wrapper .input-field {
      height: 40px;
      padding: 11px 10px 8px 10px;

      &:hover:not(.static-border) {
        padding-top: 12px;
      }

      &:focus:not(.static-border) {
        padding-top: 13px;
      }
    }
  }

  &.input-size-xl {
    @extend .body-2;

    .input-field-wrapper .input-field {
      height: 48px;
      padding: 11px 10px 8px 10px;

      &:hover:not(.static-border) {
        padding-top: 12px;
      }

      &:focus:not(.static-border) {
        padding-top: 13px;
      }
    }
  }

  .input-field {
    width: 100%;
    outline: none;
    @apply bg-active-area;
    &::placeholder {
      @apply text-neutral;
      margin-left: 20px;
    }

    &.invalid {
      @apply border-spot-error;
    }

    &:hover {
      border-bottom-width: 1px;
      @apply border-b-line-color1 transition-all duration-100;
    }

    &:focus {
      border-bottom-width: 2px;
      @apply border-b-line-color1 transition-all duration-100;
    }
  }

  .input-suffix {
    @apply absolute flex items-center justify-end px-2;
  }
}
</style>
