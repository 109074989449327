<template>
  <h5 class="text-title-neutral">Externes Projekt anlegen</h5>

  <InputEl
    v-model="projectDto.name"
    label="Bezeichnung"
    placeholder="Bitte eingeben"
    data-test="location-name"
  />
  <InputEl
    v-model="projectDto.project_developer"
    label="Projektentwickler"
    placeholder="Bitte eingeben"
    data-test="project-developer"
  />

  <div class="flex flex-col gap-2.5 standard-elevation-0-dark rounded-sm p-2.5">
    <VRadioGroup v-model="projectDto.type">
      <div class="flex justify-start">
        <VRadio value="PV">
          <template #label>PV</template>
        </VRadio>
        <VRadio value="Wind" class="ml-2">
          <template #label>Wind</template>
        </VRadio>
      </div>
    </VRadioGroup>
    <InputEl
      v-model="projectDto.power_kw"
      label="Leistung"
      placeholder="Bitte eingeben"
      data-test="power"
      input-type="float"
      suffix="kWp"
    />
    <InputEl
      v-model="projectDto.power_connection_kw"
      label="Relevante Anschlussleistung"
      placeholder="Bitte eingeben"
      data-test="power-relevant"
      input-type="float"
      suffix="kW"
    />
  </div>

  <div class="grid grid-cols-2 gap-2.5">
    <ButtonEl
      :full-width="true"
      color="color2"
      text="Abbrechen"
      @click="abort"
    />

    <ButtonEl
      :full-width="true"
      text="Speichern"
      :disabled="!isValid"
      class="pr-4"
      @click="save"
    />
  </div>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue';
import { draw } from '@/apps/features/map/draw/draw';
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';

import { ExternalProjectDto } from '@/apps/usecase-2/map/external-project-dto';
import { restPost } from '@/utils/rest-utils';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { externalProjectPointDraw } = toRefs(props.mapStore);

const projectDto = ref(new ExternalProjectDto());

async function save() {
  const geometries = externalProjectPointDraw.value;
  projectDto.value.geometry = geometries[0].geometry;
  await restPost(projectDto.value, [
    'geometry',
    'name',
    'project_developer',
    'type',
    'power_kw',
    'power_connection_kw',
  ]);
  await props.mapStore.fetchExternalProjects();
  abort();
}

function abort() {
  props.mapStore.resetMapAction(draw);
}

const isValid = computed(() => {
  const { name, type, power_kw, power_connection_kw } = projectDto.value;
  return (
    externalProjectPointDraw.value.length &&
    name &&
    type &&
    power_kw &&
    power_connection_kw
  );
});
</script>
