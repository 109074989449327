import { defineStore } from 'pinia';
import axios from '@/utils/axiosHelper';
import { assignWith } from 'lodash';
import { restFetchAll, restGetOrFetchMetaData } from '@/utils/rest-utils';
import { FiltersetDto } from './filterset-dto';

export const useResultEvaluationStore = defineStore('resultEvaluation', {
  state: () => ({
    page: 1,
    itemsPerPage: 10,
    projectData: null,
    clusterData: null,
    projectAggregationData: null,
    filtersetOptions: null,
    filterSets: null,
    filterSet: null,
    activeResultType: 'pv-project',
    applyFilter: false,
  }),
  getters: {
    filterParams: (state) => {
      if (!state.applyFilter || !state.filterSet) return {};
      const { id, name, ...params } = state.filterSet;
      return params;
    },
    resultsCountActive: (state) => {
      const item =
        state.activeResultType === 'pv-project'
          ? state.projectData
          : state.clusterData;
      return item?.count || 0;
    },
  },
  actions: {
    async initResults() {
      this.page = 1;
      this.itemsPerPage = 10;
      this.filterSet = null;
      this.applyFilter = false;
      await this.getEvaluationResults(null);
      await this.getEvaluationResults(null, 'cluster');
      await this.getProjectAggregationData();
    },
    async getEvaluationResults(
      options = null,
      resultType = 'pv-project',
      filterAggregated = false,
      noPagination = false,
    ) {
      if (filterAggregated) {
        this.getProjectAggregationData();
      }

      if (options) {
        this.page = options.page;
        this.itemsPerPage = options.itemsPerPage;
      }

      const params = {
        page: this.page,
        page_size: this.itemsPerPage,
        ...this.filterParams,
      };

      if (noPagination) {
        params.no_pagination = true;
      }

      try {
        const response = await axios({
          url: `/api/usecase-2/${resultType}/`,
          method: 'GET',
          params: params,
        });
        if (resultType === 'pv-project') {
          this.projectData = response.data;
        } else if (resultType === 'cluster') {
          this.clusterData = response.data;
        }
      } catch (error) {
        console.error('Failed to fetch data: ', error);
      }
    },

    async getProjectAggregationData() {
      try {
        const response = await axios({
          method: 'GET',
          url: '/api/usecase-2/pv-project-aggregated/',
          params: this.filterParams,
        });
        this.projectAggregationData = response.data;
        return this.projectAggregationData;
      } catch (error) {
        console.error('Failed to fetch project aggregation data: ', error);
      }
    },

    async getFiltersetOptions() {
      if (this.filtersetOptions) {
        return this.filtersetOptions;
      }

      try {
        this.filtersetOptions = await restGetOrFetchMetaData(FiltersetDto);
      } catch (error) {
        console.error('Failed to fetch filterset options: ', error);
      }
    },

    async getFilterSets() {
      try {
        this.filterSets = await restFetchAll(FiltersetDto);
        this.filterSet =
          this.filterSets.find((set) => set.is_default) ||
          this.filterSets[0] ||
          new FiltersetDto();
      } catch (error) {
        console.error('Failed to fetch filtersets: ', error);
      }
    },

    async resetFilter() {
      this.filterSet = this.filterSets.find((set) => set.is_default);
      this.filterSet.municipality = null;
      this.filterSet.analysis = null;
      this.applyFilter = false;
      this.page = 1;
      this.itemsPerPage = 10;
      await this.getEvaluationResults(null);
      await this.getEvaluationResults(null, 'cluster');
      await this.getProjectAggregationData();
    },
  },
});
