<template>
  <ButtonEl
    text="Quellenangaben"
    icon="info"
    variant="secondary"
    color="color2"
    class="ml-auto mt-auto"
    icon-type="round"
    :icon-right="true"
    @click="open = true"
  />
  <VuetifyDialog
    v-model="open"
    title="Quellenangaben"
    :activator-full-width="false"
    width="620px"
    :over-flow-hidden-for-scroll="true"
  >
    <template #content>
      <div class="flex flex-col gap-2.5" v-html="markdownContent" />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import MarkdownIt from 'markdown-it';
import { ref, watchEffect } from 'vue';

// Initialize MarkdownIt instance
const markdown = new MarkdownIt();

const props = defineProps({
  item: {
    type: String,
    default: null,
    required: true,
  },
});

const open = ref(false);

// Ref to store the markdown content
const markdownContent = ref('');

// Function to dynamically load markdown files
async function loadMarkdown(item) {
  try {
    // Dynamically import the markdown file based on the item prop
    const markdownModule = await import(`./sources/${item}.md?raw`);

    // Convert the markdown content to HTML using MarkdownIt
    markdownContent.value = markdown.render(markdownModule.default);
  } catch (error) {
    console.error('Failed to load markdown:', error);
    markdownContent.value = 'Fehler beim Laden der Quellenangaben.';
  }
}

// Watch the item prop and load the corresponding markdown file
watchEffect(() => {
  loadMarkdown(props.item);
});
</script>
