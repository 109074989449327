export const headers = [
  {
    title: 'Name',
    key: 'title',
    align: 'left',
    width: '235px',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    align: 'start',
    width: '145px',
  },
  {
    title: 'Szenariorahmen',
    key: 'assumptions_scenario_title',
    align: 'start',
  },
  {
    title: 'Demografie-\nentwicklung',
    key: 'demographic_development_yearly',
    align: 'start',
    width: '150px',
  },
  {
    title: 'Wirtschafts-\nwachstum',
    key: 'economic_development_yearly',
    align: 'start',
    width: '150px',
  },
  {
    title: 'Default',
    key: 'is_default',
    width: '110px',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Status',
    key: 'progress',
    width: '110px',
    align: 'center',
    sortable: false,
  },
  {
    title: '',
    key: 'functions',
    width: '150px',
    align: 'end',
    sortable: false,
  },
];
