<template>
  <div>
    <ButtonEl
      class="rounded-[2px] p-0.5"
      size="m"
      :icon="icon"
      :color="
        activeFilterId === 'restriction'
          ? 'color1'
          : isFilterActive
            ? 'success'
            : 'color2'
      "
      @click="toggleFilterModal()"
    />
  </div>
</template>

<script setup>
import { computed, ref, toRefs, watch } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { LAYER_KEY__RESTRICTION } from '@/apps/features/map/layer-config/base-data';

const props = defineProps({
  toggleOption: {
    type: Object,
    required: true,
  },
  layerState: {
    type: String,
    required: true,
  },
  parentId: {
    type: String || null,
    default: null,
  },
  grandParentId: {
    type: String || null,
    default: null,
  },
  mapStore: {
    type: Object,
    required: true,
  },
  icon: {
    type: String,
  },
});

const { filterContainerOpen, showMainMenu, activeFilterId } = toRefs(
  props.mapStore,
);

const isActive = ref(false);

function toggleFilterModal() {
  isActive.value = !isActive.value;
  props.mapStore.toggleFilterContainer('restriction');
}

const isFilterActive = computed(() => {
  const restrictionFilter = props.mapStore.filters[LAYER_KEY__RESTRICTION];
  if (typeof restrictionFilter === 'undefined') return false;
  return Object.keys(restrictionFilter).includes('restrictionFilter');
});

watch(showMainMenu, () => {
  if (activeFilterId.value === 'restriction') {
    props.mapStore.toggleFilterContainer();
  }
});
</script>

<style lang="scss" scoped></style>
