<template>
  <div class="flex flex-col gap-5">
    <div class="standard-container">
      <div class="flex items-center gap-2">
        <h5 class="text-title-color1">
          Anschlussbereiche der relevanten Anschlussleistung für
          PV-Potenzialflächen
        </h5>
        <ToolTip :tooltip-text="tooltipBatteryCapacity" />
      </div>

      <div class="flex flex-col gap-2.5">
        <div class="flex justify-between items-center gap-5">
          <div class="subtitle-2 text-neutral">
            Mittelspannung – Netzverknüpfungspunkt (mit freier Netzkapazität)
          </div>
          <ToolTip :tooltip-text="tooltipMediumVoltageConnection" />
        </div>
        <div class="grid grid-cols-2 gap-2.5 w-[490px]">
          <InputEl
            :model-value="0"
            :disabled="true"
            label="Anschlussleistung von"
            input-type="float"
            class="w-full"
            suffix="kW"
          />
          <InputEl
            v-model="maxLoadGridConnection"
            label="bis"
            input-type="float"
            class="w-full"
            suffix="kW"
            :disabled="readOnly"
            :rules="
              getValidationRules(
                formRules.max_load_for_grid_connection_point_kw,
              )
            "
          />
        </div>
      </div>
      <div class="flex flex-col gap-2.5">
        <div class="flex justify-between items-center gap-5">
          <div class="subtitle-2 text-neutral">
            Mittelspannung - Umspannwerk Netzbetreiber (mit freier
            Netzkapazität)
          </div>
          <ToolTip :tooltip-text="tooltipSubstationNoCapacity" />
        </div>
        <div class="grid grid-cols-2 gap-2.5 w-[490px]">
          <InputEl
            v-model="maxLoadGridConnection"
            :disabled="true"
            label="Anschlussleistung von"
            input-type="float"
            suffix="kW"
            class="w-full"
          />
          <InputEl
            v-model="maxLoadSubstation"
            label="bis"
            input-type="float"
            suffix="kW"
            class="w-full"
            :disabled="readOnly"
            :rules="getValidationRules(formRules.max_load_for_substation_kw)"
          />
        </div>
      </div>

      <div class="flex flex-col gap-2.5">
        <div class="flex justify-between items-center gap-5">
          <div class="subtitle-2 text-neutral">Hochspannung</div>
          <ToolTip :tooltip-text="tooltipHighVoltageConnection" />
        </div>
        <div class="grid grid-cols-2 gap-2.5 w-[490px]">
          <InputEl
            v-model="maxLoadSubstation"
            :disabled="true"
            label="Anschlussleistung von"
            input-type="float"
            suffix="kW"
            class="w-full"
          />
          <InputEl
            v-model="maxLoadHighVoltageConnection"
            label="bis"
            input-type="float"
            suffix="kW"
            class="w-full"
            :disabled="readOnly"
            :rules="getValidationRules(formRules.max_load_for_high_voltage_kw)"
          />
        </div>
      </div>

      <div class="flex flex-col gap-2.5">
        <div class="flex justify-between items-center gap-5">
          <div class="subtitle-2 text-neutral">
            Radius für Netzanschlusssuche NVP/Umspannwerk
          </div>
          <ToolTip :tooltip-text="tooltipBatteryCapacity" />
        </div>
        <div class="grid grid-cols-2 gap-5">
          <InputEl
            v-model="connectionRadius"
            input-type="integer"
            suffix="m"
            class="w-[240px]"
            :disabled="readOnly"
            :rules="getValidationRules(formRules.connection_radius_m)"
          />
        </div>
      </div>
    </div>

    <div class="standard-container">
      <h5 class="text-title-color1">Dimensionierung Energiespeicher</h5>

      <div class="flex items-center gap-3">
        <InputEl
          v-model="batteryProportion"
          input-type="percent"
          class="w-[240px]"
          suffix="%"
          :disabled="readOnly"
          :rules="getValidationRules(formRules.battery_capacity_proportion)"
        />
        <span class="body-2 pt-0.5"
          >Auslegungsleistung (anteilig an installierter PV-Leistung)</span
        >
        <ToolTip :tooltip-text="tooltipBatteryCapacity" class="ml-auto" />
      </div>
    </div>

    <div class="standard-container">
      <h5 class="text-title-color1">Projektcluster</h5>
      <div class="flex justify-between items-center">
        <CheckboxEl v-model="clusterApplyAnalysis" :disabled="readOnly">
          <div class="subtitle-2 text-neutral pt-[3px]">
            Projektcluster berücksichtigen (eigenes USW an HS-Leitung)
          </div>
        </CheckboxEl>

        <ToolTip :tooltip-text="tooltipBatteryCapacity" />
      </div>
      <template v-if="clusterApplyAnalysis">
        <InputEl
          v-model="clusterTransformerMax"
          label="Definiere max. Leistung Umspannwerk"
          input-type="float"
          suffix="MW"
          :disabled="readOnly"
          class="w-[240px]"
          :rules="{ required: true }"
        />

        <div
          class="flex flex-col gap-2.5 standard-elevation-0-dark rounded-md p-5"
        >
          <div class="subtitle-2 text-neutral">
            Kriterien zur Zusammenlegung relevanter Potenzialflächen
            (Einzelprojekten)
          </div>
          <div
            class="flex flex-col gap-2.5 standard-elevation-0-dark rounded-sm p-5"
          >
            <div class="subtitle-2 text-color1">Einzelprojektgröße</div>
            <div class="grid grid-cols-2 gap-2.5 w-[490px]">
              <InputEl
                v-model="clusterConnectedLoadMin"
                label="Anschlussleistung von"
                input-type="float"
                suffix="MW"
                class="w-full"
                :disabled="readOnly"
                :rules="{ required: true }"
              />
              <InputEl
                v-model="clusterConnectedLoadMax"
                label="bis"
                input-type="float"
                suffix="MW"
                class="w-full"
                :disabled="readOnly"
                :rules="{ required: true }"
              />
            </div>
            <div class="flex items-center gap-2">
              <IconWrapper
                fill="text-title-neutral"
                :size="32"
                icon="battery_charging_full"
              />
              <CheckboxEl v-model="clusterConsiderBattery" :disabled="readOnly">
                <div class="body-2 text-neutral pt-[2px]">
                  mit Berücksichtigung Batteriespeicher
                </div>
              </CheckboxEl>
            </div>
          </div>

          <div
            class="flex flex-col gap-2.5 standard-elevation-0-dark rounded-sm px-5 py-4"
          >
            <div class="flex justify-between items-center gap-5">
              <div class="subtitle-2 text-color1">Anschluss Einzelprojekte</div>
              <ToolTip :tooltip-text="tooltipSubstationNoCapacity" />
            </div>
            <VRadioGroup
              v-model="clusterOnlyUnconnectedProjects"
              class="max-w-fit"
            >
              <div class="flex flex-col gap-2">
                <div
                  v-for="choice in singleProjectChoices"
                  :key="choice.value"
                  class="flex items-center gap-2"
                >
                  <IconWrapper
                    :icon="choice.icon"
                    :size="32"
                    fill="text-title-neutral"
                  />
                  <VRadio :value="choice.value" :disabled="readOnly">
                    <template #label>
                      <div class="body-2 pt-0.5 my-2 pl-2">
                        {{ choice.label }}
                      </div>
                    </template>
                  </VRadio>
                </div>
              </div>
            </VRadioGroup>
          </div>

          <div
            class="flex flex-col gap-3 px-5 py-4 standard-elevation-0-dark rounded-sm"
          >
            <div class="subtitle-2 text-color1">Anzahl Einzelprojekte</div>
            <div class="flex items-end gap-2.5">
              <IconWrapper
                :size="32"
                fill="text-title-neutral"
                icon="numbers"
                class="mb-1"
              />
              <InputEl
                v-model="clusterNumberProjectMax"
                label="Max. Anzahl an Einzelprojekten"
                input-type="integer"
                class="w-[240px]"
                :disabled="readOnly"
                :rules="{ required: true }"
              />
            </div>
          </div>

          <div
            class="flex flex-col gap-3 px-5 py-4 standard-elevation-0-dark rounded-sm"
          >
            <div class="subtitle-2 text-color1">Distanzen Einzelprojekte</div>
            <div class="grid grid-cols-2 gap-3">
              <div class="flex flex-col gap-3">
                <div class="flex items-end gap-2.5">
                  <div
                    class="min-w-8 h-8 flex items-center justify-center mb-1"
                  >
                    <IconWrapper
                      :size="26"
                      fill="text-title-neutral"
                      icon="shape_line"
                    />
                  </div>
                  <InputEl
                    v-model="clusterDistanceGridMax"
                    label="Max. Distanz Einzelprojekte zur nächsten HS-Leitung (Suchräume)"
                    input-type="integer"
                    class="w-full"
                    suffix="m"
                    :disabled="readOnly"
                    :rules="{ required: true }"
                  />
                </div>
                <div class="flex items-end gap-2.5">
                  <IconWrapper
                    :size="32"
                    fill="text-title-neutral"
                    icon="scatter_plot"
                    class="mb-1.5"
                  />
                  <InputEl
                    v-model="clusterDistanceProjectsMax"
                    label="Räumliche Nähe Einzelprojekte zueinander"
                    input-type="integer"
                    suffix="m"
                    class="w-full"
                    :disabled="readOnly"
                    :rules="{ required: true }"
                  />
                </div>
              </div>
              <ToolTip
                :tooltip-text="tooltipSubstationNoCapacity"
                class="mb-2 ml-auto mt-auto"
              />
            </div>
          </div>

          <div
            class="flex justify-between items-center px-5 py-4 standard-elevation-0-dark rounded-sm"
          >
            <div class="flex items-center gap-2.5">
              <IconWrapper
                :size="32"
                fill="text-title-neutral"
                icon="device_hub"
              />
              <CheckboxEl
                v-model="clusterConsiderExternalProjects"
                :disabled="readOnly"
                :rules="{ required: true }"
              >
                <div class="body-2 text-neutral pt-[2px]">
                  Drittprojekte berücksichtigen
                </div>
              </CheckboxEl>
            </div>

            <ToolTip :tooltip-text="tooltipSubstationNoCapacity" />
          </div>
        </div>
      </template>
    </div>

    <div class="standard-container">
      <h5 class="text-title-color1">Route Netzanschluss</h5>
      <VRadioGroup v-model="gridConnectionChoice" class="max-w-fit">
        <VRadio
          v-for="(choice, index) in gridConnectionChoices"
          :key="choice.value"
          :value="choice.value"
          :disabled="readOnly"
        >
          <template #label>
            <div class="body-2 pt-0.5 my-2">
              {{ choice.display_name }}
            </div>
            <ToolTip
              v-if="index === 1"
              :tooltip-text="tooltipBatteryConsideration"
              class="ml-2"
            />
          </template>
        </VRadio>
      </VRadioGroup>
    </div>
  </div>
</template>

<script setup>
import IconTrafo from '@/assets/icons/custom/energy-grid/IconTrafo.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import InputEl from '@/components/input/InputEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { getValidationRules } from '@/utils/rest-utils';
import { toRefs, useVModel } from '@vueuse/core';
import { ref } from 'vue';

const props = defineProps({
  formRules: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Object,
    required: true,
  },
  metaData: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const {
  routing_option: { choices: gridConnectionChoices },
} = props.metaData;

const singleProjectChoices = [
  {
    label: 'Alle Einzelprojekte',
    value: false,
    icon: 'schema',
  },
  {
    label:
      'Nur Einzelprojekte mit Netzanschluss ohne ausreichend freie Kapazität',
    value: true,
    icon: 'share_location',
  },
];

const {
  max_load_for_grid_connection_point_kw: maxLoadGridConnection,
  max_load_for_substation_kw: maxLoadSubstation,
  max_load_for_high_voltage_kw: maxLoadHighVoltageConnection,
  battery_capacity_proportion: batteryProportion,
  connection_radius_m: connectionRadius,
  routing_option: gridConnectionChoice,
  cluster_apply_analysis: clusterApplyAnalysis,
  cluster_max_transformer_load_kw: clusterTransformerMax,
  cluster_min_connected_load_kw: clusterConnectedLoadMin,
  cluster_max_connected_load_kw: clusterConnectedLoadMax,
  cluster_consider_battery: clusterConsiderBattery,
  cluster_only_unconnected_projects: clusterOnlyUnconnectedProjects,
  cluster_max_number_projects: clusterNumberProjectMax,
  cluster_max_distance_grid_m: clusterDistanceGridMax,
  cluster_max_distance_projects_m: clusterDistanceProjectsMax,
  cluster_consider_external_projects: clusterConsiderExternalProjects,
} = toRefs(useVModel(props, 'modelValue', emit));

// TODO: Validation dass grid nicht größer als substation

// tooltip texts
const tooltipBatteryCapacity =
  'Die tatsächliche Speicherleistung ist ein Vielfaches des hinterlegten Speichermoduls (siehe allgemeine Berechnungsparameter) und kann dementsprechend minimal von der prozentualen Auslegungsleistung abweichen.';

const tooltipMediumVoltageConnection = `Der Anschluss erfolgt an einen Netzverknüpfungspunkt der Mittelspannung mit freier Netzkapazität (der Bau eines Umspannwerkes wird kostenseitig nicht berücksichtigt).
  Ist kein Netzverknüpfungspunkt verfügbar, erfolgt der Anschluss an den Netzverknüpfungspunkt, der die höchste verfügbare Netzkapazität aufweist(auf das Delta wird in der Auswertung hingewiesen).`;

const tooltipHighVoltageConnection =
  'Der Anschluss erfolgt direkt an die Hochspannungstrasse (Freileitung), so dass der Bau eines Umspannwerkes im Kontext Netzanschluss kostenseitig mit berücksichtigt wird.';

const tooltipSubstationNoCapacity =
  'Der Anschluss erfolgt an ein Umspannwerk des Netzbetreibers in der Mittelspannung ohne Berücksichtigung freier Netzkapazität (der Bau eines Umspannwerkes wird kostenseitig nicht berücksichtigt).';

const tooltipBatteryConsideration = `Bei Berücksichtigung von Batteriespeichern wird über eine Routenplanung der nächstgelegene Netzanschlusspunkt ermittelt, der über einen minimale freie Kapazität verfügt (einzustellen in den allgemeinen Berechnungsparametern).

Die verbleibende Kapazitätslücke wird mit einem Batteriespeicher gedeckt. Dieser Anschluss-Case wird hinsichtlich der gesamten Netzanschlusskosten mit dem Anschluss-Case verglichen, bei dem ein Netzanschluss an ausreichend freie Netzkapazitäten erfolgt.

Es wird der Anschluss-Case gewählt, bei dem die gesamten Netzanschlusskosten geringer ausfallen.`;
</script>
