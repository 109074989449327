import axiosBase from 'axios';

const axiosConfig = {
  withCredentials: true,
};

const location = window.location.hostname;
if (!window.location.hostname.split('.').includes('localhost')) {
  axiosConfig.baseURL = `https://${location}`;
  window['TILESERVER_URL'] = `https://${location}`;
} else {
  axiosConfig.baseURL = '';
  window['TILESERVER_URL'] = `http://${location}:8080`;
}

const axiosInstance = axiosBase.create(axiosConfig);

function axios(config) {
  return axiosInstance.request(config);
}

export { axios };
export { axiosInstance };

export default axios;
