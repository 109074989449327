import { AZUR_BLUE_30, hexToRgba } from '@/configs/color-constants';

export const fillExtrusionClickEnabledStyle = (fillStyle) => ({
  fillExtrusionColor: [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    AZUR_BLUE_30,
    ...fillStyle,
  ],
  fillExtrusionHeight: ['get', 'height_m'],
  fillExtrusionOpacity: 0.8,
});

export const polygonClickEnabledStyle = (fillStyle) => ({
  fillColor: [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    AZUR_BLUE_30,
    ...fillStyle,
  ],
  fillOpacity: 1,
  fillOutlineColor: '#000000',
});

export const pointClickEnabledStyle = (circleStyle) => ({
  circleColor: [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    AZUR_BLUE_30,
    ...circleStyle,
  ],
  circleStrokeWidth: 1,
  circleStrokeColor: 'black',
  circleRadius: 8,
});

export const gradientLayerStyle = (fieldName, min, max) => [
  ['!', ['has', fieldName]],
  hexToRgba(min.color, 1),
  [
    'interpolate',
    ['linear'],
    ['get', fieldName],
    min.value,
    hexToRgba(min.color, 1),
    max.value,
    hexToRgba(max.color, 1),
  ],
];

export const gradientLegend = (legendName, unit, min, max) => ({
  type: 'gradient',
  name: legendName,
  unit,
  items: {
    min: {
      value: min.value,
      color: min.color,
    },
    max: {
      value: max.value,
      color: max.color,
    },
  },
});
