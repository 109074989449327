<template>
  <div
    :class="[
      {
        'min-h-[300px]': !small,
        'min-h-[220px] gap-1.5': small,
      },
      cardData.disabled
        ? 'pointer-events-none bg-subtle'
        : 'bg-default cursor-pointer',
    ]"
    class="hover:scale-[1.01] w-full p-2 mx-auto flex flex-col items-center standard-elevation-2 transition-all rounded-[8px]"
    @click="cardClick"
  >
    <IconWrapper
      :icon="cardData.icon"
      fill="text-core-color2"
      :class="['pointer-events-none', { 'opacity-50': cardData.disabled }]"
      :size="small ? 84 : 96"
    />
    <div
      :class="{ 'gap-1.5': small, 'gap-2 pt-4': !small }"
      class="flex flex-col items-center h-fit pointer-events-none"
    >
      <component
        :is="small ? 'h4' : 'h3'"
        class="mb-1 text-center whitespace-pre-wrap"
        :class="cardData.disabled ? 'text-disabled-neutral' : 'text-color1'"
        >{{ cardData.title }}
      </component>
      <div
        :class="[
          small ? 'body-3' : 'body-2',
          cardData.disabled ? 'text-disabled-neutral' : 'text-neutral',
        ]"
        class="text-center"
      >
        {{ cardData.description }}
      </div>
    </div>
  </div>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';

const props = defineProps({
  cardData: {
    type: Object,
  },
  small: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

function cardClick() {
  props.cardData.action();
}
</script>
