import { AZUR_BLUE_30 } from '@/configs/color-constants';
export const LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS =
  'heat_density_street_segments';

export default {
  [LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS]: {
    layoutState: 'heat_line_density_kwh_am',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS,
      sourceLayer: LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS,
      layerId: LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS,
      visibility: 'none',
      tilesDirectory: 'uc1',
    },
    sidebar: {
      name: 'Wärmeliniendichte',
      subtitles: ['id', 'municipality_name'],
      chips: [
        {
          title: 'Auswertung',
          active: true,
          items: [
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'buildings_count',
                  unit: '',
                },
                {
                  key: 'heat_demand_kwh_a',
                  unit: 'MWh(th)/a',
                  decimal: 1,
                  multiply: 0.001,
                },
                {
                  key: 'length_m',
                  unit: 'm',
                  decimal: 0,
                },
                {
                  key: 'yearly_heat_line_density_kwh_am',
                  unit: 'kWh(th)/am',
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: (year) => ({
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          AZUR_BLUE_30,
          [
            'interpolate',
            ['linear'],
            ['get', `heat_line_density_kwh_am_${year}`],
            500,
            '#FCEFBD',
            1000,
            '#FEDE82',
            1500,
            '#EDAF00',
            2000,
            '#FE8F11',
            3000,
            '#FE7E58',
            4000,
            '#E2491B',
            5000,
            '#C73B12',
          ],
        ],
        lineWidth: 2,
      }),
    },
    legend: {
      default: {
        name: 'Leitung nach Wärmeliniendichte in kWh(th)/am',
        unit: '',
        items: [
          {
            name: '0 bis 500',
            color: '#FCEFBD',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '501 bis 1000',
            color: '#FEDE82',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '1001 bis 1500',
            color: '#EDAF00',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '1501 bis 2000',
            color: '#FE8F11',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '2001 bis 3000',
            color: '#FE7E58',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '3001 bis 4000',
            color: '#E2491B',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: 'über 5000',
            color: '#C73B12',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
};
