<template>
  <div>
    <ButtonEl
      text="Parametrierungen verwalten"
      color="color2"
      icon="unarchive"
      @click="openDialog"
    />
    <AssumptionModalGeneralInfos
      title="Parametrierungen zur EE-Erzeugung"
      :selected
      :selectables
      :date="selectedAssumption.created_at"
      :description="selectedAssumption.description"
      :load-dialog
      :is-loading
      :can-delete
      base-url="/api/usecase-1/assumptions-total-increase-generation/"
      @apply-values="applyValues($event)"
      @delete-values="fetchInstances"
      @update:load-dialog="loadDialog = $event"
      @update:selected="selected = $event"
    >
      <AssumptionsTotalIncreaseRow
        v-for="assumption in assumptionsForDisplay"
        :key="assumption.title"
        :items="assumption.items"
        :title="assumption.title"
      />
    </AssumptionModalGeneralInfos>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import axios from '@/utils/axiosHelper';
import AssumptionsTotalIncreaseRow from './AssumptionsTotalIncreaseRow.vue';
import AssumptionModalGeneralInfos from '../shared/AssumptionModalGeneralInfos.vue';
import { getTotalIncreaseAssumptionsForDisplay } from '../helpers/modal';
import { useProjectResult } from '../../projects/project-result-detail/project-result-store';

const resultStore = useProjectResult();
const emit = defineEmits(['apply-values']);

const props = defineProps({
  scenarioId: {
    type: String,
    required: true,
  },
  potentials: {
    type: Object,
  },
  scenarioCountYears: {
    type: Number,
    required: true,
  },
  canDelete: {
    type: Boolean,
    default: false,
  },
});

const loadDialog = ref();
const instances = ref([]);
const selectables = ref([]);
const applied = computed(() => resultStore.appliedGenerationScalingId);
const selected = ref();
const isLoading = ref(false);

async function fetchInstances() {
  isLoading.value = true;
  axios({
    method: 'GET',
    url: '/api/usecase-1/assumptions-total-increase-generation/',
    params: { scenario_id: props.scenarioId },
  }).then((resp) => {
    instances.value = resp.data.results;
    selectables.value = [];
    for (const item of instances.value) {
      selectables.value.push({ id: item.id, title: item.title });
    }
    if (applied.value) selected.value = applied.value;
    else if (instances.value.length) selected.value = instances.value[0].id;
    isLoading.value = false;
  });
}

const selectedAssumption = computed(() => {
  return instances.value.find((e) => e.id === selected.value) || {};
});

const assumptionsForDisplay = computed(() =>
  getTotalIncreaseAssumptionsForDisplay(
    selectedAssumption.value,
    props.potentials,
    props.scenarioCountYears,
  ),
);
function openDialog() {
  fetchInstances();
  loadDialog.value = true;
}

function applyValues(instanceId) {
  emit('apply-values', {
    totalIncreasePvRoof: selectedAssumption.value.pv_roof_mw,
    totalIncreasePvFree: selectedAssumption.value.pv_free_mw,
    totalIncreaseWind: selectedAssumption.value.wind_mw,
    id: instanceId,
  });
  loadDialog.value = false;
}
</script>
