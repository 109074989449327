<template>
  <div
    class="switch-container"
    :class="[{ 'is-disabled': disabled }, 'w-full']"
    @click="disabled ? null : toggle()"
  >
    <div class="switch" :class="[size, colorClass, { 'is-checked': isActive }]">
      <div class="switch-handle" :class="handleColorClass"></div>
    </div>

    <div v-if="label" class="body-2 pt-0.5 select-none">
      {{ label }}
    </div>
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: [Boolean, String, Number, Array],
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'medium',
    validator: (value) => ['small', 'medium', 'large'].includes(value),
  },
  onColor: {
    type: String,
    default: 'bg-core-color1',
  },
  offColor: {
    type: String,
    default: 'bg-title-neutral',
  },
  handleColor: {
    type: String,
    default: 'bg-default',
  },
  value: {
    type: [Boolean, String, Number],
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const isActive = computed(() => {
  if (Array.isArray(props.modelValue)) {
    // For arrays, check if the value is included in the array
    return props.modelValue.includes(props.value);
  } else {
    // For non-array values, check if modelValue equals the value prop
    // This also covers boolean toggles where value prop might not be used
    return props.modelValue === props.value || props.modelValue === true;
  }
});

const colorClass = computed(() => {
  if (props.disabled) {
    return 'bg-disabled-neutral cursor-not-allowed';
  }
  return isActive.value ? props.onColor : props.offColor;
});
const handleColorClass = computed(() => props.handleColor);

function toggle() {
  if (props.disabled) {
    return;
  }

  // If modelValue is an array, add or remove the value
  if (Array.isArray(props.modelValue)) {
    let newValue;
    if (props.modelValue.includes(props.value)) {
      // Remove the value from the array
      newValue = props.modelValue.filter((item) => item !== props.value);
    } else {
      // Add the value to the array
      newValue = [...props.modelValue, props.value];
    }
    emit('update:modelValue', newValue);
  } else {
    // If there's a specific value to emit and modelValue is not an array
    if (props.value !== null) {
      const newValue = props.modelValue === props.value ? false : props.value;
      emit('update:modelValue', newValue);
    } else {
      // If no value prop is provided, just toggle the boolean state
      emit('update:modelValue', !props.modelValue);
    }
  }
}
</script>

<style scoped>
.switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

.switch {
  min-width: 30px;
  height: 18px;
  border-radius: 45px;
  position: relative;
  transition: background-color 0.3s;
}

.switch-handle {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: transform 0.2s;
}

.switch.is-checked .switch-handle {
  transform: translateX(12px);
}

.switch.small {
  min-width: 24px;
  height: 15px;
}

.switch.small .switch-handle {
  width: 11px;
  height: 11px;
  top: 2px;
  left: 0;
  transform: translateX(2px);
}

.switch.small.is-checked .switch-handle {
  transform: translateX(11px);
}

.switch.large {
  min-width: 36px;
  height: 22px;
}

.switch.large .switch-handle {
  width: 16px;
  height: 16px;
  top: 3px;
  left: 2px;
  transform: translateX(0);
}

.switch.large.is-checked .switch-handle {
  transform: translateX(18px);
}
</style>
