export class PotentialAnalysisDto {
  static BASE_URL = '/api/usecase-2/analysis/';

  id = null;
  title = '';
  description = '';
  municipalities = [];
  estates = [];
  is_manual = false;
  is_quickcheck = false;
  // unit conversion field please check units in backend
  min_area_m2 = 20;
  max_estate_density = 20;
  orientation_value_greater_equal_than = 135;
  orientation_value_smaller_equal_than = 225;
  min_slope_degree_to_consider_orientation = 5.0;
  slope_degree_value_greater_equal_than = 0;
  slope_degree_value_smaller_equal_than = 45;
  max_load_for_grid_connection_point_kw = 15000;
  max_load_for_substation_kw = 25000;
  max_load_for_high_voltage_kw = 100000;
  connection_radius_m = 10000;
  battery_capacity_proportion = 0.4;
  routing_option = 0;
  disadvantage_area_list = [];
  agricultural_plots_list = [];
  // unit conversion field please check units in backend
  specific_plant_size_kw_m2 = 900;
  dc_ac_ratio = 0.8;
  inverter_efficiency = 0.95;
  performance_ratio = 0.95;
  mounting_slope_degree = 40;
  cluster_apply_analysis = false;
  // unit conversion field please check units in backend
  cluster_max_transformer_load_kw = 60;
  // unit conversion field please check units in backend
  cluster_min_connected_load_kw = 10;
  // unit conversion field please check units in backend
  cluster_max_connected_load_kw = 40;
  cluster_consider_battery = true;
  cluster_only_unconnected_projects = false;
  cluster_max_number_projects = 4;
  cluster_max_distance_grid_m = 2000;
  cluster_max_distance_projects_m = 2000;
  cluster_consider_external_projects = true;
  configrestrictions_set = [];
}
