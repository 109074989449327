<template>
  <TopHeaderBar class="justify-start">
    <InputSearch
      class="w-[565px]"
      placeholder="Nach Potenzialanalyse suchen"
      :search-keys="['title']"
      :items="potentialsData"
      @update:search="filteredPotentials = $event"
      @click="stopFetchingScenarios"
      @reset="startFetchingAnalyses" />

    <PotentialAnalysisCreateDialog
      v-if="userStore.uc2CanAddAnalysis"
      :potentials-data
      @create-analysis="toggleForm"
  /></TopHeaderBar>

  <!-- <div class="flex justify-between items-center gap-5">
    <div class="flex items-center gap-2.5">
      <IconWrapper
        v-if="IconAnalysis"
        :size="48"
        :icon="IconAnalysis"
        fill="text-core-color2"
      />
      <h1 class="text-title-neutral">Potenzialanalyse</h1>
    </div>
  </div> -->
  <div>
    <div class="flex flex-col gap-5">
      <div class="flex flex-col gap-5">
        <VuetifyDataTable
          :headers="tableHeadersPotentials"
          :item-data="filteredPotentials"
          style="min-height: calc(100vh - 290px)"
          :set-loading="loading"
          :show-expand="false"
          class="rounded-xl"
        >
          <template #created_at="{ item }">
            <span>
              {{ `${formatDate(item.created_at)} von` }}<br />
              {{ item.created_by }}
            </span>
          </template>
          <template #updated_at="{ item }">
            {{ new Date(item.updated_at).toLocaleDateString('de-DE') }}
          </template>
          <template #is_type="{ item }">
            <div class="flex justify-start pl-3.5">
              {{
                item.is_quickcheck
                  ? 'Quick-Check'
                  : item.is_manual
                    ? 'Bottom-Up'
                    : 'Top-Down'
              }}
            </div>
          </template>
          <template #progress="{ item }">
            <div class="flex justify-start items-center">
              <TaskProgress
                :item
                :status-map="{
                  SUCCESS: 'success',
                  ERROR: 'exited',
                  PROGRESS: 'in_progress',
                }"
              />
            </div>
          </template>
          <template #functions="{ item }">
            <div class="flex items-center justify-end gap-2">
              <IconWrapper
                fill="text-core-color1"
                hover="hover:text-button-primary-hover-color1"
                icon="map"
                class="cursor-pointer"
                type="round"
                :disabled="item.progress !== 'success'"
                @click="navigateToMap(router, item.id)"
              />
              <IconWrapper
                v-if="userStore.uc2CanDeleteAnalysis"
                fill="text-core-color1"
                hover="hover:text-button-primary-hover-color1"
                icon="delete_forever"
                class="cursor-pointer"
                type="round"
                :disabled="item.progress === 'in_progress'"
                @click="toggleDeleteDialog(item)"
              />
              <IconWrapper
                icon="segment"
                fill="text-core-color1"
                hover="hover:text-button-primary-hover-color1"
                class="cursor-pointer"
                @click="toggleForm(item.id)"
              />
            </div>
          </template>
        </VuetifyDataTable>
      </div>
      <div
        v-if="showForm"
        class="bg-white rounded-xl standard-elevation-0-dark p-5 flex flex-col gap-5"
      >
        <div class="flex justify-between items-center gap-5 min-h-[48px]">
          <h3 class="text-title-neutral">Konfiguration Potenzialanalyse</h3>
          <div class="flex items-center gap-5">
            <ButtonEl
              v-if="!readOnly"
              text="Berechnung starten"
              icon="table_view"
              :disabled="
                !formIsValid ||
                !titleIsUnique ||
                !potentialAnalysis.municipalities.length ||
                !isClusterAnalysisComplete
              "
              :is-loading="loading"
              @click="save"
            />
            <IconWrapper
              icon="close"
              class="cursor-pointer"
              fill="text-core-color1"
              @click="closeForm"
            />
          </div>
        </div>
        <PotentialAnalysisTabsWrapper
          ref="formTabsEl"
          :potentials-data
          :form-data
          :potentials-names
          :meta-data
          @update:is-valid="formIsValid = $event"
          @update:read-only="readOnly = $event"
          @update:title-is-unique="titleIsUnique = $event"
          @update:potential-analysis="potentialAnalysis = $event"
        />
      </div>
    </div>

    <DeleteDialog
      :id="deleteItem?.id"
      :name-deleted-item="deleteItem?.title"
      :dto="PotentialAnalysisDto"
      :set-dialog="showDeleteDialog"
      @update:toggled-delete="loading = $event"
      @instance-removed="getPotentials(deleteItem?.id)"
      @update:dialog="showDeleteDialog = $event"
    />
  </div>
</template>

<script setup>
import { tableHeadersPotentials } from '@/apps/usecase-2/potential-analysis/configs/potential-analysis-data';
import { PotentialAnalysisDto } from '@/apps/usecase-2/potential-analysis/potential-analysis-dto';
import IconAnalysis from '@/assets/icons/custom/misc/IconAnalysis.vue';
import { useBaseStore } from '@/apps/basemodule/base-store';
import ButtonEl from '@/components/button/ButtonEl.vue';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import { computed, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue';
import PotentialAnalysisCreateDialog from './components/PotentialAnalysisCreateDialog.vue';
import PotentialAnalysisTabsWrapper from './components/PotentialAnalysisTabsWrapper.vue';
import { usePotentialAnalysisStore } from './potential-analysis-store';
import { usePotentialAnalysis } from './composables/use-potential-analysis';
import { navigateToMap } from '@/apps/usecase-2/utils';
import TaskProgress from '@/apps/features/task-progress/TaskProgress.vue';
import { useRouter } from 'vue-router';
import { useUsers } from '@/apps/auth/users-store';
import { formatDate } from '@/utils/formatUtils';
import InputSearch from '@/components/inputSearch/InputSearch.vue';

const router = useRouter();
const userStore = useUsers();

const formIsValid = ref(false);
const titleIsUnique = ref(true);
const readOnly = ref(false);
const formTabsEl = ref(null);
const deleteItem = ref(null);
const showDeleteDialog = ref(false);

const {
  create,
  getPotentials,
  fetchMetaData,
  loading,
  showForm,
  potentialsData,
  potentialAnalysis,
  metaData,
  potentialsNames,
  isClusterAnalysisComplete,
} = usePotentialAnalysis();

await getPotentials();
await usePotentialAnalysisStore().fetchRestrictions();
await useBaseStore().fetchSimplifiedMuniData();
await fetchMetaData();

const formData = ref({
  id: null,
  title: null,
});

onMounted(() => {
  startFetchingAnalyses();
});

function save() {
  potentialAnalysis.value.is_manual = false;
  potentialAnalysis.value.is_quickcheck = false;
  create(true);
}

function toggleForm(id = null, title = null) {
  showForm.value = true;
  formData.value = { id, title };
}

function closeForm() {
  showForm.value = false;
}

const filteredPotentials = ref([]);

const sortedPotentialsData = computed(() => {
  return [...potentialsData.value].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  );
});

watchEffect(() => {
  filteredPotentials.value = sortedPotentialsData.value;
});

function toggleDeleteDialog(item) {
  showDeleteDialog.value = true;
  deleteItem.value = { id: item.id, title: item.title };
}

// ------------ Interval reference
const interval = ref();

function startFetchingAnalyses() {
  if (!interval.value) {
    interval.value = setInterval(() => {
      getPotentials();
    }, 5000);
  }
}

function stopFetchingScenarios() {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
}

onBeforeUnmount(() => {
  stopFetchingScenarios();
});
</script>
