export const LAYER_KEY__PLANNING_MAP_PV_FREE = 'planning_map_pv_free';

import {
  LIND_GREEN_40,
  SUN_YELLOW_40,
  TAROCCO_40,
  NEUTRAL_GREY_60,
} from '@/configs/color-constants';

export default {
  [LAYER_KEY__PLANNING_MAP_PV_FREE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__PLANNING_MAP_PV_FREE,
      sourceLayer: LAYER_KEY__PLANNING_MAP_PV_FREE,
      layerId: LAYER_KEY__PLANNING_MAP_PV_FREE,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    style: {
      default: {
        fillColor: [
          'match',
          ['get', 'type'],
          'possible_in_principle',
          LIND_GREEN_40,
          'possible_in_individual_cases',
          SUN_YELLOW_40,
          'currently_not_possible',
          TAROCCO_40,
          NEUTRAL_GREY_60,
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
    },
    legend: {
      default: {
        name: 'Planhinweiskarte',
        unit: '',
        items: [
          {
            color: LIND_GREEN_40,
            name: 'Freiflächen-PV-Anlagen grundsätzlich möglich',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: SUN_YELLOW_40,
            name: 'Freiflächen-PV-Anlagen im Einzeilfall möglich',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: TAROCCO_40,
            name: 'Freiflächen-PV-Anlagen derzeit nicht möglich',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
};
