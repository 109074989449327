import { SimplePanelScenarioDependent } from '@/apps/features/map/info-panel/panel-data/simple-panel-scenario-dependent';
import { LAYER_KEY__BUILDINGS } from '../layer-config/buildings';
import {
  LAYER_KEY__BLOCK,
  LAYER_KEY__COUNTY,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
} from '../layer-config/geographical-level';
import { LAYER_KEY__ESTATE } from '../layer-config/estates';
import {
  LAYER_KEY__POTENTIAL_PV_FREE,
  LAYER_KEY__POTENTIAL_WIND,
} from '../layer-config/potential';
import { LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS } from '../layer-config/infrastructure';
import {
  LAYER_KEY__GENERATION_PV_FREE,
  LAYER_KEY__GENERATION_WIND,
  LAYER_KEY__GENERATION_HYDROPOWER,
  LAYER_KEY__GENERATION_BIOMASS,
} from '@/apps/usecase-1/map/layer-config/existing-power-plants';

export const panelObjects = [
  new SimplePanelScenarioDependent(
    '/api/usecase-1/building',
    [LAYER_KEY__BUILDINGS],
    {
      method: 'OPTIONS',
      url: '/api/usecase-1/building-main-result/',
    },
    {
      otherNestedKeys: ['building'],
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/building_block',
    [LAYER_KEY__BLOCK],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/district',
    [LAYER_KEY__DISTRICT],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/municipality',
    [LAYER_KEY__MUNICIPALITY],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/administrative_district',
    [LAYER_KEY__GOVERNMENT_DISTRICT],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/county',
    [LAYER_KEY__COUNTY],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/federal_state',
    [LAYER_KEY__FEDERAL_STATE],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/estate',
    [LAYER_KEY__ESTATE],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/existing-powerplant-pv-free',
    [LAYER_KEY__GENERATION_PV_FREE],
    {
      method: 'GET',
      url: '/api/usecase-1/existing-powerplant-pv-free-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/existing-powerplant-wind',
    [LAYER_KEY__GENERATION_WIND],
    {
      method: 'GET',
      url: '/api/usecase-1/existing-powerplant-wind-meta-data/',
    },
    {
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/existing-powerplant-biomass',
    [LAYER_KEY__GENERATION_BIOMASS],
    {
      method: 'GET',
      url: '/api/usecase-1/existing-powerplant-biomass-meta-data/',
    },
    {
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/existing-powerplant-hydropower',
    [LAYER_KEY__GENERATION_HYDROPOWER],
    {
      method: 'GET',
      url: '/api/usecase-1/existing-powerplant-hydropower-meta-data/',
    },
    {
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/potential-wind',
    [LAYER_KEY__POTENTIAL_WIND],
    {
      method: 'GET',
      url: '/api/usecase-1/potential-wind-meta-data/',
    },
    {
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/potential-pv-free',
    [LAYER_KEY__POTENTIAL_PV_FREE],
    {
      method: 'GET',
      url: '/api/usecase-1/potential-pv-free-meta-data/',
    },
    {
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/heat-segment-main-result',
    [LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS],
    {
      method: 'GET',
      url: '/api/usecase-1/heat-segment-main-result-meta-data/',
    },
    {
      customMetaView: true,
      yearlyResultsKey: 'yearly_results',
    },
  ),
];
