/**
 * Validates the intervals based on the specified rules:
 * 1. No duplicated from values
 * 2. Proper ordering (min value should not be lower than the next interval's min)
 * Emits 'validation-error' with appropriate messages if validation fails.
 */
export function validateIntervals(intervals) {
  const fromValue = intervals.map((e) => e.from);
  // 1. No duplicated from values
  if (fromValue.length !== new Set(fromValue).size) {
    return 'Intervalle müssen unterschiedliche Startwerte haben';
  }
  for (let i = 0; i < intervals.length - 1; i++) {
    const current = intervals[i];
    const next = intervals[i + 1];
    // 2. Proper ordering
    if (current.from >= next.from) {
      return 'Anordung der Intervalle ist fehlerhaft';
    }
  }
  return null;
}
