import * as Sentry from '@sentry/vue';

export function initSentry(app, router) {
  if (import.meta.env.PROD) {
    const dsn =
      'https://c3e50253cc7767d99f1269784e618973@o1327264.ingest.us.sentry.io/4508284709371904';
    let tracePropagationTargets = ['localhost'];
    const environment = window.location.hostname.includes('stage')
      ? 'stage'
      : 'prod';
    if (environment === 'prod') {
      tracePropagationTargets.push(/^https:\/\/pade\.enbw\.com/);
    } else if (environment === 'stage') {
      tracePropagationTargets.push(/^https:\/\/pade-stage\.enbw\.com/);
    }
    const sampleRate = environment === 'stage' ? 1.0 : 0.1;

    Sentry.init({
      app,
      environment,
      dsn,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: sampleRate,
      tracePropagationTargets,
      replaysSessionSampleRate: sampleRate,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}
