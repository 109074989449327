<template>
  <ExpansionPanel
    :eager="true"
    :first-panel-open="false"
    :panels="['Übersicht Gemeinden']"
    :show-circle-number="false"
  >
    <template #panel1>
      <VuetifyDataTable :headers :item-data="data" :show-expand="false">
        <template #population="{ item }">
          {{ item.population?.toLocaleString('de-DE') }}
        </template>
        <template #building_count="{ item }">
          {{ item.building_count?.toLocaleString('de-DE') }}
        </template>
        <template #area_km2="{ item }">
          {{ item.area_km2?.toLocaleString('de-DE') + ' km²' }}
        </template>
        <template #population_density="{ item }">
          {{ item.population_density?.toLocaleString('de-DE') + ' EW/km²' }}
        </template>
      </VuetifyDataTable>
    </template>
  </ExpansionPanel>
</template>
<script setup>
import ExpansionPanel from '@/components/ExpansionPanel/ExpansionPanel.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
});

const headers = [
  {
    title: 'Gemeinde',
    key: 'name',
    align: 'left',
  },
  {
    title: 'Landkreis',
    key: 'county_name',
    align: 'left',
  },
  {
    title: 'Regierungsbezirk',
    key: 'administrative_district_name',
    align: 'left',
  },
  {
    title: 'Einwohner',
    key: 'population',
    align: 'end',
  },
  {
    title: 'Anzahl Gebäude',
    key: 'building_count',
    align: 'end',
    width: '200px',
  },
  {
    title: 'Fläche',
    key: 'area_km2',
    align: 'end',
    width: '100px',
  },
  {
    title: 'Einwohnerdichte',
    key: 'population_density',
    align: 'end',
    width: '180px',
  },
];
</script>
