import {
  pointClickEnabledStyle,
  gradientLegend,
  gradientLayerStyle,
} from '@/apps/features/map/utils';
import { GENERATION_POTENTIAL, STATUS } from '@/configs/color-constants';

export const LAYER_KEY__GENERATION_PV_FREE = 'existing_powerplant_pvfree';
export const LAYER_KEY__GENERATION_BIOMASS = 'existing_powerplant_biomass';
export const LAYER_KEY__GENERATION_WIND = 'existing_powerplant_wind';
export const LAYER_KEY__GENERATION_HYDROPOWER =
  'existing_powerplant_hydropower';

export const statusStyleType = [
  'match',
  ['get', 'unit_state'],
  0,
  STATUS.REQUESTED,
  1,
  STATUS.IN_OPERATION,
  2,
  STATUS.SHUT_DOWN,
  3,
  STATUS.SHUT_DOWN,
  STATUS.UNKNOWN,
];

export const statusLegendType = (legendName) => ({
  name: legendName,
  unit: '',
  items: [
    {
      color: STATUS.IN_OPERATION,
      name: 'In Betrieb',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: STATUS.SHUT_DOWN,
      name: 'Stillgelegt',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: STATUS.REQUESTED,
      name: 'Beantragt',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: STATUS.UNKNOWN,
      name: 'Unbekannst',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const defaultLegendGeneration = (name, color) => ({
  name: 'Bestandsanlagen',
  unit: '',
  items: [
    {
      color: color,
      name: name,
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const defaultSidebarGenerations = (title, additionalFields) => ({
  name: title,
  subtitles: [
    'key',
    'eeg_key',
    'plant_operator_key',
    'municipality_name',
    'municipality_key',
  ],
  chips: [
    {
      title: 'Anlageninformationen',
      active: true,
      items: [
        {
          title: 'Anlageninformationen',
          active: true,
          items: [
            { key: 'unit_state' },
            { key: 'start_up_date' },
            { key: 'planned_start_up_date' },
            { key: 'type_of_feed_in' },
            ...additionalFields,
            {
              key: 'production_kwh_a',
              unit: 'kWh(el)/a',
              //decimal: 0,
            },
          ],
        },
      ],
    },
  ],
});

export default {
  [LAYER_KEY__GENERATION_PV_FREE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__GENERATION_PV_FREE,
      sourceLayer: LAYER_KEY__GENERATION_PV_FREE,
      layerId: LAYER_KEY__GENERATION_PV_FREE,
      visibility: 'none',
      tilesDirectory: 'basemodule',
      dependsOnScenario: false,
    },
    sidebar: defaultSidebarGenerations('PV-Freifläche', [
      {
        key: 'park_orientation',
      },
      {
        key: 'slope_degree',
        unit: '',
        decimal: 0,
      },
      {
        key: 'count_of_modules',
        decimal: 0,
      },
      {
        key: 'power_kw',
        unit: 'kWp',
        decimal: 0,
      },
    ]),
    style: {
      default: pointClickEnabledStyle([GENERATION_POTENTIAL.PV_FREE]),
      generationPvFreeKw: pointClickEnabledStyle(
        gradientLayerStyle(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 1000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      status: pointClickEnabledStyle([statusStyleType]),
    },
    legend: {
      default: defaultLegendGeneration(
        'PV-Freifläche',
        GENERATION_POTENTIAL.PV_FREE,
      ),
      generationPvFreeKw: gradientLegend(
        'PV-Freifläche nach Anlagenleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 1, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
      status: statusLegendType('PV-Freifläche nach Betriebsstatus'),
    },
  },
  [LAYER_KEY__GENERATION_WIND]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__GENERATION_WIND,
      sourceLayer: LAYER_KEY__GENERATION_WIND,
      layerId: LAYER_KEY__GENERATION_WIND,
      visibility: 'none',
      tilesDirectory: 'basemodule',
      dependsOnScenario: false,
    },
    sidebar: defaultSidebarGenerations('Wind', [
      { key: 'technology' },
      { key: 'manufacturer' },
      { key: 'plant_type' },
      {
        key: 'hub_height_m',
        unit: 'm',
        decimal: 0,
      },
      {
        key: 'rotor_diameter_m',
        unit: 'm',
        decimal: 0,
      },
      {
        key: 'power_kw',
        unit: 'kW(el)',
        decimal: 0,
      },
    ]),
    style: {
      default: pointClickEnabledStyle([GENERATION_POTENTIAL.WIND]),
      generationWindKw: pointClickEnabledStyle(
        gradientLayerStyle(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
          { value: 5000, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
        ),
      ),
      status: pointClickEnabledStyle([statusStyleType]),
    },
    legend: {
      default: defaultLegendGeneration('Wind', GENERATION_POTENTIAL.WIND),
      generationWindKw: gradientLegend(
        'Windpotenzial nach Nennleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
        { value: 5, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
      ),
      status: statusLegendType('Wind nach Betriebsstatus'),
    },
  },
  [LAYER_KEY__GENERATION_BIOMASS]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__GENERATION_BIOMASS,
      sourceLayer: LAYER_KEY__GENERATION_BIOMASS,
      layerId: LAYER_KEY__GENERATION_BIOMASS,
      visibility: 'none',
      tilesDirectory: 'basemodule',
      dependsOnScenario: false,
    },
    sidebar: defaultSidebarGenerations('Biomasse', [
      { key: 'technology' },
      { key: 'main_fuel' },
      { key: 'type_of_biomass' },
      { key: 'is_chp' },
      {
        key: 'power_kw',
        unit: 'kW(el)',
        decimal: 0,
      },
    ]),
    style: {
      default: pointClickEnabledStyle([GENERATION_POTENTIAL.BIOMASS]),
      generationBiomassKw: pointClickEnabledStyle(
        gradientLayerStyle(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.BIOMASS_MIN_GRADIENT },
          { value: 1000, color: GENERATION_POTENTIAL.BIOMASS_MAX_GRADIENT },
        ),
      ),
      status: pointClickEnabledStyle([statusStyleType]),
    },
    legend: {
      default: defaultLegendGeneration(
        'Biomasse',
        GENERATION_POTENTIAL.BIOMASS,
      ),
      generationBiomassKw: gradientLegend(
        'Biomasse nach Anlagenleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.BIOMASS_MIN_GRADIENT },
        { value: 1, color: GENERATION_POTENTIAL.BIOMASS_MAX_GRADIENT },
      ),
      status: statusLegendType('Biomasseanlagen nach Betriebsstatus'),
    },
  },
  [LAYER_KEY__GENERATION_HYDROPOWER]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__GENERATION_HYDROPOWER,
      sourceLayer: LAYER_KEY__GENERATION_HYDROPOWER,
      layerId: LAYER_KEY__GENERATION_HYDROPOWER,
      visibility: 'none',
      tilesDirectory: 'basemodule',
      dependsOnScenario: false,
    },
    sidebar: defaultSidebarGenerations('Wasserkraft', [
      { key: 'type_of_hydropower' },
      {
        key: 'power_kw',
        unit: 'kW(el)',
        decimal: 0,
      },
    ]),
    style: {
      default: pointClickEnabledStyle([GENERATION_POTENTIAL.WATER]),
      generationHydropowerKw: pointClickEnabledStyle(
        gradientLayerStyle(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.WATER_MIN_GRADIENT },
          { value: 1000, color: GENERATION_POTENTIAL.WATER_MAX_GRADIENT },
        ),
      ),
      status: pointClickEnabledStyle([statusStyleType]),
    },
    legend: {
      default: defaultLegendGeneration(
        'Wasserkraft',
        GENERATION_POTENTIAL.WATER,
      ),
      generationHydropowerKw: gradientLegend(
        'Wasserkraft nach Anlagenleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.WATER_MIN_GRADIENT },
        { value: 1, color: GENERATION_POTENTIAL.WATER_MAX_GRADIENT },
      ),
      status: statusLegendType('Wasserkraft nach Betriebsstatus'),
    },
  },
};
