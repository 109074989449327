<template>
  <div class="standard-elevation-0-dark p-5 rounded-xl">
    <ToolTip>
      <template #text>
        Der Werthaltigkeitsindikator gibt auf Basis von sechs zu
        parametrierenden Kriterien an, wie werthaltig ein Fläche gegenüber
        anderen Flächen ist. Der Werthaltigkeitsindikator ist als
        Priorisierungskriterium zu verstehen.
      </template>
    </ToolTip>
    <div class="flex items-center justify-end mb-2.5">
      <div class="caption-1 text-center text-title-neutral w-[700px]">
        Gewichtungsfaktor
      </div>
      <div class="text-center caption-1 text-title-neutral w-[88px]">
        Anteil Werthaltigkeit
      </div>
    </div>
    <div class="flex flex-col gap-5">
      <WeightingRow
        :weighting-data
        :sum-weighting
        :input-keys="['minimum_area_ha']"
        slider-key="weighting_minimum_area"
      />
      <WeightingRow
        :weighting-data
        :sum-weighting
        :input-keys="['form_factor_width_m', 'area_share_bbox']"
        slider-key="weighting_form_factors"
      />
      <WeightingRow
        :weighting-data
        :sum-weighting
        :input-keys="['max_specific_investment_eur_kw']"
        slider-key="weighting_specific_investment"
      />
      <WeightingRow
        :weighting-data
        :sum-weighting
        :input-keys="['area_share_eligible']"
        slider-key="weighting_share_eligible"
      />
      <WeightingRow
        :weighting-data
        :sum-weighting
        :input-keys="['area_share_field']"
        slider-key="weighting_share_field"
      />
    </div>
    <div class="flex justify-between pt-2.5">
      <ButtonEl
        v-if="userStore.uc2CanChangeWeightingValue"
        icon="balance"
        color="color2"
        text="Alle gleich gewichten"
        class="w-fit my-5"
        @click="setSameWeight()"
      />
      <div class="flex items-center pr-5">
        <p class="subtitle-2 mr-4 text-color1">Summe</p>
        <SquareMeasureWeighting
          :model-value="sumWeightingShare"
          container-classes="bg-core-color1 text-inverted-color1"
        />
      </div>
    </div>
  </div>
  <div class="flex gap-5 mt-[30px]">
    <ButtonEl
      v-if="userStore.uc2CanChangeWeightingValue"
      icon="save"
      icon-fill="text-inverted-color1"
      text="Speichern"
      class="w-fit"
      @click="saveDialog = true"
    />
  </div>
  <VuetifyDialog
    v-if="saveDialog"
    v-model="saveDialog"
    title="Hinweis"
    :persistent="true"
    width="620px"
  >
    <template #content>
      Die angepassten Einstellungen wirken sich auf alle bestehenden Projekte
      aus.
    </template>
    <template #actions>
      <ButtonEl text="Abbrechen" color="color2" @click="saveDialog = false" />
      <ButtonEl text="Speichern" @click="save()" />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { WeightingValuesDto } from '@/apps/usecase-2/settings/weighting-values/weighting-values-dto';
import ButtonEl from '@/components/button/ButtonEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import {
  convertMetaDataToFormRules,
  getWritableFields,
  restFetchAll,
  restGetOrFetchMetaData,
  restPatch,
  restPost,
} from '@/utils/rest-utils';
import { sum } from 'lodash';
import { computed, ref } from 'vue';
import SquareMeasureWeighting from './components/SquareMeasureWeighting.vue';
import { useToastStore } from '@/stores/toast-store';
import { useUsers } from '@/apps/auth/users-store';
import WeightingRow from '@/apps/usecase-2/settings/weighting-values/components/WeightingRow.vue';

const { showToast } = useToastStore();
const userStore = useUsers();

const weightingMetaData = await restGetOrFetchMetaData(WeightingValuesDto);
const weightingFormRules = convertMetaDataToFormRules(weightingMetaData);
const weightingItemData = await restFetchAll(WeightingValuesDto);

const weightingDto = ref(new WeightingValuesDto());
const itemExists = computed(() => weightingItemData.length > 0);
// if instance exists set form fields accordingly in dto
if (itemExists.value) {
  weightingDto.value = weightingItemData[0];
  weightingDto.value.minimum_area_ha =
    weightingDto.value.minimum_area_ha.toLocaleString('de-DE', {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    });
}
const weightingData = ref({
  weightingMetaData,
  weightingFormRules,
  weightingDto: weightingDto.value,
});

const sumWeighting = computed(() =>
  sum([
    weightingDto.value.weighting_minimum_area,
    weightingDto.value.weighting_form_factors,
    weightingDto.value.weighting_share_eligible,
    weightingDto.value.weighting_specific_investment,
    weightingDto.value.weighting_share_field,
  ]),
);

const sumWeightingShare = computed(() => {
  return sum([
    calculateWeightingShare(weightingDto.value.weighting_minimum_area),
    calculateWeightingShare(weightingDto.value.weighting_form_factors),
    calculateWeightingShare(weightingDto.value.weighting_share_eligible),
    calculateWeightingShare(weightingDto.value.weighting_specific_investment),
    calculateWeightingShare(weightingDto.value.weighting_share_field),
  ]);
});

const saveDialog = ref(false);

function save() {
  if (itemExists.value) {
    restPatch(WeightingValuesDto, weightingDto.value.id, weightingDto.value)
      .then(() => showSuccessToast())
      .catch((err) => showErrorToast());
  } else {
    restPost(weightingDto.value, getWritableFields(weightingMetaData))
      .then(() => showSuccessToast())
      .catch((err) => showErrorToast());
  }
  saveDialog.value = false;
}

function setSameWeight() {
  weightingDto.value.weighting_minimum_area = 1;
  weightingDto.value.weighting_form_factors = 1;
  weightingDto.value.weighting_share_eligible = 1;
  weightingDto.value.weighting_specific_investment = 1;
  weightingDto.value.weighting_share_field = 1;
}

function showSuccessToast() {
  showToast({
    color: 'success',
    message: 'erfolgreich gespeichert',
  });
}

function showErrorToast() {
  showToast({
    color: 'error',
    message: 'Fehlgeschlagen, bitte überprüfen Sie ihre Eingaben.',
  });
}

function calculateWeightingShare(input) {
  return (input / sumWeighting.value) * 5;
}
</script>
