<template>
  <div class="grid grid-cols-3">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="flex flex-col items-center cursor-pointer"
      :class="[
        {
          'tab--active': modelValue === index,
        },
      ]"
      @click="setActiveTab(index)"
    >
      <!-- Icon will be conditionally styled based on the active tab -->
      <div :class="{ 'text-title-color1': modelValue === index }" class="p-2.5">
        <IconWrapper :icon="tab.icon" :fill="tabFillColor(index)" />
      </div>
      <div
        class="w-full h-1.5 bottom-line"
        :style="{ backgroundColor: tabFillColor(index) }"
        :class="{
          'rounded-l-[4px]': index === 0,
          'rounded-r-[4px]': index === tabs.length - 1,
        }"
      ></div>
    </div>
  </div>
</template>

<script setup>
import IconWrapper from '../IconWrapper/IconWrapper.vue';

const props = defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
  validCards: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: Number,
    default: 0,
  },
  disableAutomaticTabSwitching: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:activeTab', 'clickTab', 'update:modelValue']);

function setActiveTab(index) {
  if (!props.disableAutomaticTabSwitching) {
    emit('update:modelValue', index);
  } else {
    emit('clickTab', index);
  }
}

function tabFillColor(index) {
  const isActive = props.modelValue === index;
  const isValid = props.validCards.includes(index);
  const isValidLessOne = Array.from(
    { length: props.tabs.length - 1 },
    (_, i) => index - i - 1,
  ).some((i) => props.validCards.includes(i));

  if (isActive && !isValid) {
    return '#000099'; // Edit Active tab color
  } else if (isActive && isValid) {
    return '#000099'; // Valid Active tab color
  } else if (!isActive && isValid) {
    return '#000099'; // Valid Inactive tab color
  } else if (!isActive && isValidLessOne) {
    return '#C3BCB6'; // Color for inactive tabs
  } else {
    return '#C3BCB6'; // Color for deactivated tabs
  }
}
</script>
