<template>
  <div class="space-y-5">
    <div class="standard-container">
      <DropDown
        v-model="filterSet.municipality"
        :items-data="municipalities"
        label="Gemeinden"
        items-data-key="title"
        value="id"
        enable-search
        clearable
        max-height-dropdown="200px"
        @update:model-value="emit('update:municipality', $event)"
      />

      <DropDown
        v-model="filterSet.analysis"
        :items-data="potentialsData"
        label="Nach Potenzialanalyse"
        items-data-key="title"
        value="id"
        enable-search
        clearable
        max-height-dropdown="200px"
        :disabled="filterAnalysisInMap >= 0"
      />
    </div>
    <div class="standard-container">
      <DropDown
        v-model="selectedFilterId"
        :items-data="filterSets"
        label="Filterset"
        items-data-key="name"
        value="id"
      >
        <template #selected>
          <span
            v-if="filterSet.is_default"
            class="caption-3 uppercase text-title-neutral p-[5px] pb-1 rounded-sm border border-title-neutral"
            >Standard</span
          >
        </template>
        <template #options="{ item }">
          <span
            v-if="item.is_default"
            class="caption-3 uppercase text-title-neutral p-[5px] pb-1 rounded-sm border border-title-neutral"
            >Standard</span
          >
          <IconWrapper
            v-else-if="userStore.uc2CanChangeFilterSetProject"
            icon="more_horiz"
            fill="text-title-neutral"
            hover="hover:text-color1"
            class="cursor-pointer"
            @click.stop="showSetDefault = item.id"
          />

          <Transition name="fade">
            <div
              v-if="showSetDefault === item.id"
              ref="setDefaultContainer"
              class="absolute w-fit right-2.5 bg-white rounded-[4px] standard-elevation-1 overflow-hidden flex items-center gap-1.5 p-2 py-1.5 hover:bg-active-area cursor-pointer"
              @click="setFilterAsDefault(item.id)"
            >
              <IconWrapper icon="flag" fill="text-title-neutral" />
              <div class="body-2 pt-[3px] text-neutral">
                Als Standard festlegen
              </div>
            </div>
          </Transition>
        </template>
      </DropDown>
      <div class="flex items-center justify-between gap-2.5 relative">
        <ButtonEl
          v-if="userStore.uc2CanAddFilterSetProject"
          icon="more_horiz"
          color="color2"
          @click="showMoreOptions = true"
        />
        <ButtonEl
          text="anwenden"
          :disabled="!filterSets.length"
          @click="fetchProjects"
        />

        <Transition name="fade">
          <div
            v-if="showMoreOptions"
            ref="moreOptionsContainer"
            class="absolute top-0 w-full bg-white rounded-md standard-elevation-0-dark z-10 overflow-hidden"
          >
            <div
              v-for="option in moreOptionsFilterSet.filter(
                (option) => !option.disabled,
              )"
              :key="option"
              class="flex items-center gap-1.5 p-3 hover:bg-active-area cursor-pointer"
              @click="option.action()"
            >
              <IconWrapper :icon="option.icon" fill="text-title-neutral" />
              <div class="body-2 pt-[3px] text-neutral">
                {{ option.label }}
              </div>
            </div>
          </div>
        </Transition>
      </div>
      <div class="flex flex-col gap-2.5">
        <div
          v-for="option in filterOptions"
          :key="option.label"
          class="flex items-end gap-2.5"
        >
          <InputEl
            v-model="filterSet[option.keys[0]]"
            :suffix="option.unit"
            :input-type="option.unit === '%' ? 'percent' : 'float'"
            :label="option.label"
          />
          <span class="caption-1 text-core-color1 mb-3">bis</span>
          <InputEl
            v-model="filterSet[option.keys[1]]"
            :suffix="option.unit"
            :input-type="option.unit === '%' ? 'percent' : 'float'"
            placeholder="∞"
          />
        </div>
      </div>

      <div class="flex flex-col gap-2.5">
        <div class="subtitle-2 text-core-color1">Anschluss Spannungsebene</div>
        <CheckboxEl
          v-for="choice in filtersetOptions.allowed_voltage_levels.child
            .choices"
          :key="choice"
          v-model="filterSet.allowed_voltage_levels"
          :label="choice.display_name"
          :value="choice.value"
        />
      </div>

      <div class="flex flex-col gap-2.5 -ml-1">
        <SwitchItem
          v-model="filterSet.only_favorites"
          size="small"
          label="Nur Prioritätsflächen"
          class="gap-1.5"
        />
      </div>
      <div class="flex flex-col gap-2.5">
        <div class="subtitle-2 text-core-color1">Status</div>
        <CheckboxEl
          v-for="choice in filtersetOptions.allowed_review_states.child.choices"
          :key="choice"
          v-model="filterSet.allowed_review_states"
          :label="choice.display_name"
          :value="choice.value"
        />
      </div>
    </div>

    <VuetifyDialog
      v-model="showCreateDialog"
      :title="
        filterSets.length
          ? `Filterset basierend auf '${filterSet.name}' erstellen`
          : 'Filterset erstellen'
      "
    >
      <template #content>
        <FormValidator v-model="createNameIsValid">
          <InputEl
            v-model="newFiltersetName"
            label="Name neues Filterset"
            :rules="{ unique: [filterSets.map((i) => i.name)] }"
          />
        </FormValidator>
      </template>
      <template #actions>
        <ButtonEl
          text="Anlegen"
          :disabled="!newFiltersetName || !createNameIsValid"
          @click="createFilterSet"
        />
      </template>
    </VuetifyDialog>

    <DeleteDialog
      :id="selectedFilterId"
      :name-deleted-item="filterSet.name"
      :dto="FiltersetDto"
      :set-dialog="showDeleteDialog"
      @instance-removed="handleFilterSetRemoved"
      @update:dialog="showDeleteDialog = $event"
    />
  </div>
</template>

<script setup>
import ButtonEl from '@/components/button/ButtonEl.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import SwitchItem from '@/components/SwitchItem/SwitchItem.vue';
import { onClickOutside } from '@vueuse/core';
import { computed, ref, toRefs, watch, watchEffect } from 'vue';
import { FiltersetDto } from '../filterset-dto';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import InputEl from '@/components/input/InputEl.vue';
import { restFetch, restPatch, restPost } from '@/utils/rest-utils';
import { useToastStore } from '@/stores/toast-store';
import { usePotentialAnalysisStore } from '../../potential-analysis/potential-analysis-store';
import FormValidator from '@/components/FormValidator.vue';
import { useUsers } from '@/apps/auth/users-store';

const props = defineProps({
  municipalities: {
    type: Array,
    required: true,
  },
  resultsStore: {
    type: Object,
    required: true,
  },
  // If component is used in map, selected analysis is used by default
  filterAnalysisInMap: {
    type: Number,
    default: -1,
  },
});

const { showToast } = useToastStore();
const potentialsStore = usePotentialAnalysisStore();
const userStore = useUsers();
const { potentialsData } = toRefs(potentialsStore);
const { fetchPotentials } = potentialsStore;

await fetchPotentials();
await props.resultsStore.getFiltersetOptions();
await props.resultsStore.getFilterSets();

const { getFilterSets, getEvaluationResults } = props.resultsStore;
const {
  filtersetOptions,
  filterSets,
  filterSet,
  applyFilter,
  activeResultType,
} = toRefs(props.resultsStore);

const emit = defineEmits([
  'update:modalIsOpen',
  'update:filteredIds',
  'update:municipality',
]);

const selectedFilterId = ref(null);
const showMoreOptions = ref(false);
const showSetDefault = ref(null);
const moreOptionsContainer = ref(null);
const setDefaultContainer = ref(null);
const showDeleteDialog = ref(false);
const showCreateDialog = ref(false);
const newFiltersetName = ref('');
const createNameIsValid = ref(false);
const moreOptionsFilterSet = ref([
  {
    label: 'Neues Filterset erstellen',
    icon: 'add',
    action: () => {
      showCreateDialog.value = true;
      showMoreOptions.value = false;
    },
  },
  {
    label: 'Filterset auf gespeicherten Stand zurücksetzen',
    icon: 'replay',
    disabled: computed(() => !filterSets.value.length),
    action: () => {
      resetFilterSet();
    },
  },
  {
    label: 'Filterset löschen',
    icon: 'delete',
    disabled: computed(
      () => filterSet.value.is_default || !filterSets.value.length,
    ),
    action: () => {
      showMoreOptions.value = false;
      showDeleteDialog.value = true;
    },
  },
]);

const filterOptions = ref([
  {
    label: 'Fläche',
    unit: 'ha',
    keys: ['min_area_m2', 'max_area_m2'],
  },
  {
    label: 'Installierte Leistung',
    unit: 'MW',
    keys: ['min_power_kw', 'max_power_kw'],
  },
  {
    label: 'Netzanschlusslänge',
    unit: 'm',
    keys: ['min_connection_length_m', 'max_connection_length_m'],
  },
  {
    label: 'Leistung Batteriespeicher',
    unit: 'MW',
    keys: ['min_battery_capacity_kw', 'max_battery_capacity_kw'],
  },
  {
    label: 'Formfaktor Breite',
    unit: 'm',
    keys: ['min_bbox_width_m', 'max_bbox_width_m'],
  },
  {
    label: 'Formfaktor Flächenanteil',
    unit: '%',
    keys: [
      'min_share_potential_area_in_bbox_percent',
      'max_share_potential_area_in_bbox_percent',
    ],
  },
  {
    label: 'Werthaltigkeit',
    keys: ['min_utility_value', 'max_utility_value'],
  },
]);

onClickOutside(moreOptionsContainer, () => {
  showMoreOptions.value = false;
});

onClickOutside(setDefaultContainer, () => {
  showSetDefault.value = null;
});

async function createFilterSet() {
  if (!filterSets.value.length) {
    // Initialize with FiltersetDto if no filtersets exist
    filterSet.value = new FiltersetDto();
  }

  filterSet.value.name = newFiltersetName.value;

  const fieldsToPost = { ...filterSet.value };
  delete fieldsToPost.id;
  delete fieldsToPost.is_default;

  const newFilterSet = await restPost(
    filterSet.value,
    Object.keys(fieldsToPost),
  );
  await getFilterSets();
  showCreateDialog.value = false;
  selectedFilterId.value = newFilterSet.data.id;
}

async function setFilterAsDefault(id) {
  await restPatch(FiltersetDto, id, { is_default: true }).then(async () => {
    showSetDefault.value = null;
    showToast({
      color: 'success',
      message: 'Filterset als Standard festgelegt',
    });
    await getFilterSets();
  });
}

async function resetFilterSet() {
  filterSet.value = await restFetch(FiltersetDto, selectedFilterId.value);
  showMoreOptions.value = false;
  showToast({
    color: 'success',
    message: 'Filterset zurückgesetzt',
  });
}

async function fetchProjects() {
  applyFilter.value = true;

  const isMapFilter = props.filterAnalysisInMap >= 0;

  await getEvaluationResults(
    { page: 1, itemsPerPage: 10 },
    activeResultType.value,
    !isMapFilter,
    isMapFilter,
  );

  emit(
    'update:filteredIds',
    props.resultsStore.projectData.results.map((e) => e.id),
  );
}

watch(selectedFilterId, (newId) => {
  if (newId && filterSets.value.length) {
    filterSet.value =
      filterSets.value.find((set) => set.id === newId) || new FiltersetDto();
    showDeleteDialog.value = false;
  }
});

watch(
  [showDeleteDialog, showCreateDialog],
  ([newDeleteDialog, newCreateDialog]) => {
    emit('update:modalIsOpen', newDeleteDialog || newCreateDialog);
    if (newCreateDialog) {
      newFiltersetName.value = '';
    }
  },
);

watch(
  () => props.filterAnalysisInMap,
  (analysisId) => {
    if (analysisId >= 0 && filterSet.value) {
      filterSet.value.analysis = analysisId;
    }
  },
  { immediate: true },
);

watchEffect(() => {
  selectedFilterId.value = filterSet.value?.id || null;
});

async function handleFilterSetRemoved() {
  await getFilterSets();
  selectedFilterId.value =
    filterSets.value.length > 0 ? filterSets.value[0].id : null;
}
</script>
