import IconUrbaneStandorte from '@/assets/icons/custom/misc/IconUrbaneStandorte.vue';
import IconEnergieEffizienz from '@/assets/icons/custom/misc/IconEnergieEffizienz.vue';
import IconDevelopment from '@/assets/icons/custom/misc/IconDevelopment.vue';
import IconZahnrad from '@/assets/icons/custom/misc/IconZahnrad.vue';

const useCase1CardData = [
  {
    title: 'Projekte',
    id: 'projects',
    description:
      'Anlegen von Projektgebieten und Auswertung der Projekte hinsichtlich Entwicklungsmöglichkeiten zur bilanziellen Energieautarkie.',
    icon: IconEnergieEffizienz,
    row: 1,
    order: 1,
    routeName: 'UseCase1ProjectList',
  },
  {
    title: 'Kartenansicht',
    id: 'mapView',
    description:
      'Geografische Verortung und Auswertung der Projektanalysen in der Kartenansicht.',
    icon: IconUrbaneStandorte,
    row: 1,
    order: 2,
    routeName: 'mapProjectSelection',
  },
  {
    title: 'Szenariorahmen',
    id: 'assumptions_scenario',
    description:
      'Festlegung individueller Szenariorahmen als Grundlage für die Analyse der strom- und wärmeseitigen Nachfrageentwicklung.',
    icon: IconDevelopment,
    row: 2,
    order: 1,
    routeName: 'AssumptionsScenario',
  },
  {
    title: 'Allgemeine Annahmen',
    id: 'general_assumptions',
    description:
      'Zentrale Einstellung aller projekt- und szenarioübergreifenden Annahmen und Parametern.',
    icon: IconZahnrad,
    row: 2,
    order: 2,
    routeName: 'AssumptionsGeneral',
  },
];

export { useCase1CardData };
