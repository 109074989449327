<template>
  <div ref="searchContainer" class="relative">
    <InputEl
      v-model="searchText"
      :label
      :placeholder
      :search-suggestions="searchSuggestions"
      :suffix-icon="true"
      suffix="search"
      suffix-color="text-neutral"
      :suffix-left="true"
      class="w-full"
      @update:model-value="highlightMatch($event)"
      @clicked:search-suggestion="emitSearchSuggestion"
    />
    <IconWrapper
      v-if="searchText"
      icon="close"
      fill="text-active-area"
      type="outlined"
      :size="22"
      class="absolute right-[10px] bottom-[-2px] translate-y-[-50%] cursor-pointer bg-core-color1 rounded-full"
      @click.stop="resetAndFocus"
    />
  </div>
</template>

<script setup>
import InputEl from '../../components/input/InputEl.vue';
import IconWrapper from '../../components/IconWrapper/IconWrapper.vue';
import { computed, nextTick, ref, watch } from 'vue';
import FlexSearch from 'flexsearch';

const { Index } = FlexSearch;

const props = defineProps({
  placeholder: {
    type: String,
  },
  label: {
    type: String,
  },
  items: {
    type: Array,
    required: true,
  },
  searchKeys: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [String, Number, Object],
    default: null,
  },
  itemValue: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:search', 'update:modelValue', 'reset']);

const searchText = ref(null);
const itemsInner = ref([]);
const searchContainer = ref(null);
const index = ref(null);

watch(
  () => props.items,
  () => {
    async function initializeSearch() {
      itemsInner.value = props.items;

      index.value = new Index({
        tokenize: 'forward',
        preset: 'score',
        resolution: 9,
        threshold: 0, // Lower threshold to catch more partial matches
        depth: 4, // Increase depth for better partial matching
      });

      itemsInner.value.forEach((item, id) => {
        const attributes = props.searchKeys
          .map((key) => String(item[key] || '')) // Remove the space removal here
          .join(' ');
        index.value.add(id, attributes);
      });
    }

    initializeSearch();
  },
  { deep: true, immediate: true },
);

const searchResults = computed(() => {
  if (!searchText.value) {
    return itemsInner.value;
  }

  const searchTerm = searchText.value.toLowerCase();
  const results = index.value.search(searchTerm);
  return results.map((id) => itemsInner.value[id]);
});

const searchSuggestions = computed(() => {
  return searchResults.value.map((item) =>
    props.searchKeys.map((key) => item[key]).join(' / '),
  );
});

function highlightMatch(word) {
  const divs = searchContainer.value.querySelectorAll('.search-item');
  divs.forEach((div) => {
    div.innerHTML = div.textContent;

    if (word) {
      const regex = new RegExp(`(${word})`, 'gi');

      div.innerHTML = div.innerHTML.replace(
        regex,
        `<span class="bg-active-area">$1</span>`,
      );
    }
  });
}

function resetAndFocus() {
  searchText.value = '';
  emit('update:modelValue', null);
  emit('reset');
}

watch(searchText, () => {
  emit('update:search', searchResults.value);
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      // Find the item in props.items that matches the modelValue
      const selectedItem = props.items.find((item) => {
        if (typeof newValue === 'object') {
          // For objects, compare all properties
          return Object.keys(newValue).every(
            (key) => item[key] === newValue[key],
          );
        }
        // For string values, compare with the id field
        return String(item.id) === String(newValue);
      });

      if (selectedItem) {
        searchText.value = props.searchKeys
          .map((key) => selectedItem[key])
          .join(' / ');
      }
    } else {
      searchText.value = null;
    }
  },
  { immediate: true },
);

function emitSearchSuggestion(suggestion) {
  const selectedItem = searchResults.value.find(
    (item) =>
      props.searchKeys.map((key) => item[key]).join(' / ') === suggestion,
  );

  if (selectedItem) {
    searchText.value = suggestion;
    emit(
      'update:modelValue',
      props.itemValue ? selectedItem[props.itemValue] : selectedItem,
    );
  }
}
</script>
