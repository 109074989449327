<template>
  <div
    class="flex flex-col"
    :class="{
      'pointer-events-none': disabled,
    }"
  >
    <TheLabel
      :label="label"
      :class="disabled ? 'text-disabled-neutral' : 'text-core-color1'"
    />
    <VueDatePicker
      v-model="date"
      :placeholder
      auto-apply
      :close-on-auto-apply="true"
      :class="[customBackground, inputWrapperClasses]"
      :style="{
        '--custom-bg': customBackground,
      }"
      locale="de"
      :format="yearPicker ? 'yyyy' : 'dd.MM.yyyy'"
      :clearable
      :enable-time-picker="false"
      :cancel-text
      :select-text
      :position
      :year-first="true"
      :year-picker
      :year-range
    >
      <template #input-icon>
        <IconWrapper
          icon="calendar_month"
          :fill="disabled ? 'text-disabled-neutral' : 'text-core-color1'"
          type="round"
          :size="iconSize"
          custom-inner-icon-classes="absolute"
        />
      </template>
    </VueDatePicker>
  </div>
</template>

<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import TheLabel from '../label/TheLabel.vue';
import { computed } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';

const props = defineProps({
  modelValue: {
    type: [String, Date, Number],
    default: null,
  },
  size: {
    type: String,
    default: 'l',
    validate: (value) => ['s', 'm', 'l', 'xl'].includes(value),
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  cancelText: {
    type: String,
    default: 'abbrechen',
  },
  selectText: {
    type: String,
    default: 'auswählen',
  },
  position: {
    type: String,
    default: 'right',
  },
  placeholder: {
    type: String,
    default: null,
  },
  customBackground: {
    type: String,
    default: 'bg-active-area',
  },
  label: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  yearPicker: {
    type: Boolean,
    default: false,
  },
  yearRange: {
    type: Array,
    default: () => [1900, 2100],
  },
});

const emit = defineEmits(['update:modelValue']);

const date = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});

const inputWrapperClasses = computed(() => {
  return {
    'input-size-s': props.size === 's',
    'input-size-m': props.size === 'm',
    'input-size-l': props.size === 'l',
    'input-size-xl': props.size === 'xl',
    disabled: props.disabled,
  };
});

const iconSize = computed(() => {
  if (props.size === 's') return 12;
  if (props.size === 'm') return 16;
  if (props.size === 'l') return 24;
  return 32;
});
</script>

<style lang="scss" scoped>
@use '@/assets/styles';

:deep(.dp__input_icon) {
  left: unset;
  right: 0;
}

:deep(.dp__action_row) {
  justify-content: center;

  .dp__selection_preview {
    display: none;
  }

  .dp__action_buttons {
    margin-left: unset;
    gap: 5px;
  }
}

:deep(.dp__calendar_header_item) {
  @extend .body-2;
  @apply font-medium;
}

:deep(.dp__calendar) {
  .dp__today {
    @apply border-button-primary-default-color1;
  }

  .dp__active_date {
    @apply bg-button-primary-default-color1 border-button-primary-default-color1;
  }
}

:deep(.dp__overlay_container) {
  @extend .body-2;

  .dp__overlay_cell_active {
    @apply bg-button-primary-default-color1 border-button-primary-default-color1;
  }
}

:deep(.dp__main) {
  .dp__input_wrap {
    .dp__input {
      border-width: 0;
      border-radius: 0;
      padding-top: 7px;

      &:hover {
        border-bottom-width: 1px;
        padding-top: 8px;
        @apply border-b-line-color1;
      }

      &.dp__input_focus {
        padding-top: 9px;
      }
    }

    .dp__input_focus {
      border-bottom-width: 2px !important;
      @apply border-b-line-color1;
    }

    .dp__pointer {
      padding-left: 10px;
      background-color: var(--custom-bg);
      @extend .body-2;
    }

    .dp__clear_icon {
      margin-right: 20px !important;
    }

    .dp__input_icon {
      padding-right: 10px;
    }
  }

  &.input-size-s {
    .dp__input_wrap {
      .dp__input {
        @extend .caption-1;
        height: 24px;
        padding: 7px 6px 6px 6px;
      }

      .dp__clear_icon {
        height: 12px;
        margin-right: 14px !important;
      }
    }
  }

  &.input-size-m {
    .dp__input_wrap {
      .dp__input {
        @extend .body-3;
        height: 32px;
        padding: 9px 8px 8px 8px;
      }

      .dp__clear_icon {
        height: 14px;
        margin-right: 18px !important;
      }
    }
  }

  &.input-size-l {
    .dp__input_wrap {
      .dp__input {
        @extend .body-2;
        height: 40px;
        padding: 9px 10px 8px 10px;

        &:hover {
          padding-top: 10px;
        }

        &.dp__input_focus {
          padding-top: 11px;
        }
      }

      .dp__clear_icon {
        margin-right: 28px !important;
      }
    }
  }

  &.input-size-xl {
    .dp__input_wrap {
      .dp__input {
        @extend .body-2;
        height: 48px;
        padding: 9px 10px 8px 10px;
      }

      .dp__clear_icon {
        margin-right: 36px !important;
      }
    }
  }

  &.disabled {
    .dp__input_wrap {
      .dp__input {
        border-bottom-width: 1px;
        @apply border-b-line-color1 bg-subtle text-disabled-neutral;
      }
    }
  }
}

:deep(.dp__menu_inner) {
  @extend .body-2;
}
</style>
