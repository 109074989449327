import MapboxDraw from '@mapbox/mapbox-gl-draw';
const DrawPoint = MapboxDraw.modes.draw_point;

const setPoint = Object.assign({}, DrawPoint);

setPoint.onSetup = function (opts) {
  const onSetup = DrawPoint.onSetup.bind(this);
  const state = onSetup();
  state.drawCreateCallback = opts.drawCreateCallback;
  state.drawDeleteCallback = opts.drawDeleteCallback;
  if (typeof opts.featureType !== 'string') {
    throw new Error('a unique feature type string must be set');
  }
  // feature type must be set i.o.t be able to identify what feature was
  // created from what draw mode on data handling
  state.point.properties.featureType = opts.featureType;
  return state;
};

setPoint.onTap = setPoint.onClick = function (state, e) {
  const onClick = DrawPoint.onClick.bind(this, state, e);
  onClick();
  if (typeof state.drawCreateCallback === 'function') {
    state.drawCreateCallback(state.point);
  }
};

export default setPoint;
