import {
  CONSTRUCTION_YEAR_CLASS_LEGEND,
  CONSTRUCTION_YEAR_STYLING,
  HEATING_TECHNOLOGY_LEGEND,
  powerLegendType,
  powerStyleType,
  SECTOR_LEGEND,
  SECTOR_STYLING,
  TECHNOLOGY_STYLING,
} from './utils';
import { polygonClickEnabledStyle } from '@/apps/features/map/utils';
import {
  CONSTRUCTION_YEAR_BY_ENUM,
  GENERATION_POTENTIAL,
  GEOGRAPHICAL_LEVEL,
  HEATING_TYPE_BY_ENUM,
  POWER_DEMAND,
  SECTOR_BY_ENUM,
} from '@/configs/color-constants';
import { SECTOR_NO_MOBILITY_LEGENDS_ORDERED } from '@/utils/chart-utils';

export const LAYER_KEY__BLOCK = 'aggregation_buildingblock';
export const LAYER_KEY__DISTRICT = 'aggregation_district';
export const LAYER_KEY__MUNICIPALITY = 'aggregation_municipality';
export const LAYER_KEY__COUNTY = 'aggregation_county';
export const LAYER_KEY__GOVERNMENT_DISTRICT =
  'aggregation_administrative_district';
export const LAYER_KEY__FEDERAL_STATE = 'aggregation_federal_state';

const linearFillStyle = (key, type) => [
  ['==', ['get', key], null],
  'rgba(255,255,255,0)',
  [
    'interpolate',
    ['linear'],
    ['get', key],
    type.min.value * 1000, // convert back to kw
    type.min.color,
    type.max.value * 1000, // convert back to kw
    type.max.color,
  ],
];

const AGGREGATION_STYLE = (name, layerStyle) => {
  return {
    style: {
      default: polygonClickEnabledStyle(['rgba(255,255,255,0)']),
      sector: polygonClickEnabledStyle([SECTOR_STYLING]),
      constructionYearClass: polygonClickEnabledStyle([
        CONSTRUCTION_YEAR_STYLING,
      ]),
      heating_technology: (year) =>
        polygonClickEnabledStyle([TECHNOLOGY_STYLING(year)]),
      generationPvFreeKw: polygonClickEnabledStyle(
        powerStyleType(
          'pv_free_generation_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      generationWindKw: polygonClickEnabledStyle(
        powerStyleType(
          'wind_generation_kw',
          { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
        ),
      ),
      generationBiomassKw: polygonClickEnabledStyle(
        powerStyleType(
          'biomass_generation_kw',
          { value: 0, color: GENERATION_POTENTIAL.BIOMASS_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.BIOMASS_MAX_GRADIENT },
        ),
      ),
      generationHydropowerKw: polygonClickEnabledStyle(
        powerStyleType(
          'hydropower_generation_kw',
          { value: 0, color: GENERATION_POTENTIAL.WATER_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.WATER_MAX_GRADIENT },
        ),
      ),
      generationPvRoofKw: polygonClickEnabledStyle(
        powerStyleType(
          'pv_roof_generation_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      potentialPvFreeKw: polygonClickEnabledStyle(
        powerStyleType(
          'pv_free_potential_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      potentialWindKw: polygonClickEnabledStyle(
        powerStyleType(
          'wind_potential_kw',
          { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
        ),
      ),
      potentialPvRoofKw: polygonClickEnabledStyle(
        powerStyleType(
          'pv_roof_potential_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      heat_demand_total_kwh_a(property) {
        const key = `heat_demand_total_kwh_a_${property}`;
        return polygonClickEnabledStyle(
          linearFillStyle(key, layerStyle.heat_demand_total_kwh_a),
        );
      },
      heat_demand_total_kw(property) {
        const key = `heat_demand_total_kw_${property}`;
        return polygonClickEnabledStyle(
          linearFillStyle(key, layerStyle.heat_demand_total_kw),
        );
      },
      electricity_demand_total_kwh_a(property) {
        const key = `electricity_demand_total_kwh_a_${property}`;
        return polygonClickEnabledStyle(
          linearFillStyle(key, layerStyle.electricity_demand_total_kwh_a),
        );
      },
      electricity_demand_total_kw(property) {
        const key = `electricity_demand_total_kw_${property}`;
        return polygonClickEnabledStyle(
          linearFillStyle(key, layerStyle.electricity_demand_total_kw),
        );
      },
    },
    legend: {
      sector: SECTOR_LEGEND(`${name} nach Sektoren`),
      constructionYearClass: CONSTRUCTION_YEAR_CLASS_LEGEND(
        `${name} nach Baualtersklasse`,
      ),
      heating_technology: HEATING_TECHNOLOGY_LEGEND(
        `${name} nach dominanten Energieträgern`,
      ),
      generationPvFreeKw: {
        type: 'gradient',
        name: `${name} nach PV-Freifläche Anlagenleistung`,
        unit: 'MW',
        items: layerStyle.generationPvFreeKw,
      },
      generationWindKw: {
        type: 'gradient',
        name: `${name} nach Wind Anlagenleistung`,
        unit: 'MW',
        items: layerStyle.generationWindKw,
      },
      generationBiomassKw: {
        type: 'gradient',
        name: `${name} nach Biomasse Anlagenleistung`,
        unit: 'MW',
        items: layerStyle.generationBiomassKw,
      },
      generationHydropowerKw: {
        type: 'gradient',
        name: `${name} nach Wasserkraft Anlagenleistung`,
        unit: 'MW',
        items: layerStyle.generationHydropowerKw,
      },
      generationPvRoofKw: {
        type: 'gradient',
        name: `${name} nach PV-Dach Anlagenleistung`,
        unit: 'MW',
        items: layerStyle.generationPvRoofKw,
      },
      potentialPvFreeKw: powerLegendType(
        `${name} nach PV-Freifläche Potenzial`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
      potentialWindKw: powerLegendType(
        `${name} nach Wind Potenzial`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
        { value: 10000, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
      ),
      potentialPvRoofKw: powerLegendType(
        `${name} nach PV-Dach Potenzial`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 10000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
      heat_demand_total_kwh_a: {
        type: 'gradient',
        name: `${name} nach Wärmebedarf`,
        unit: 'MWh(th)/a',
        items: layerStyle.heat_demand_total_kwh_a,
      },
      heat_demand_total_kw: {
        type: 'gradient',
        name: `${name} nach Wärmelast`,
        unit: 'MW(th)',
        items: layerStyle.heat_demand_total_kw,
      },
      electricity_demand_total_kwh_a: {
        type: 'gradient',
        name: `${name} nach Strombedarf`,
        unit: 'MWh(el)/a',
        items: layerStyle.electricity_demand_total_kwh_a,
      },
      electricity_demand_total_kw: {
        type: 'gradient',
        name: `${name} nach Stromlast`,
        unit: 'MW(el)',
        items: layerStyle.electricity_demand_total_kw,
      },
    },
  };
};

export function getLayerConfig(layerKey) {
  return {
    type: 'polygon',
    id: layerKey,
    sourceLayer: layerKey,
    layerId: layerKey,
    visibility: 'none',
    tilesDirectory: 'uc1',
  };
}

function createStyleConfig(minValue, minColor, maxValue, maxColor) {
  return {
    min: { value: minValue, color: minColor },
    max: { value: maxValue, color: maxColor },
  };
}

export function getStyle(
  heatDemandMax,
  heatLoadMax,
  powerDemandMax,
  powerLoadMax,
  generationPvFreeMax,
  generationWindMax,
  generationBiomassMax,
  generationHydropowerMax,
  generationPvRoofMax,
) {
  return {
    generationPvFreeKw: createStyleConfig(
      0,
      GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT,
      generationPvFreeMax,
      GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT,
    ),
    generationWindKw: createStyleConfig(
      0,
      GENERATION_POTENTIAL.WIND_MIN_GRADIENT,
      generationWindMax,
      GENERATION_POTENTIAL.WIND_MAX_GRADIENT,
    ),
    generationBiomassKw: createStyleConfig(
      0,
      GENERATION_POTENTIAL.BIOMASS_MIN_GRADIENT,
      generationBiomassMax,
      GENERATION_POTENTIAL.BIOMASS_MAX_GRADIENT,
    ),
    generationHydropowerKw: createStyleConfig(
      0,
      GENERATION_POTENTIAL.WATER_MIN_GRADIENT,
      generationHydropowerMax,
      GENERATION_POTENTIAL.WATER_MAX_GRADIENT,
    ),
    generationPvRoofKw: createStyleConfig(
      0,
      GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT,
      generationPvRoofMax,
      GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT,
    ),
    heat_demand_total_kwh_a: createStyleConfig(
      0,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MIN_GRADIENT,
      heatDemandMax,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MAX_GRADIENT,
    ),
    heat_demand_total_kw: createStyleConfig(
      0,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MIN_GRADIENT,
      heatLoadMax,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MAX_GRADIENT,
    ),
    electricity_demand_total_kwh_a: createStyleConfig(
      0,
      GEOGRAPHICAL_LEVEL.ELECTRICITY_DEMAND_MIN_GRADIENT,
      powerDemandMax,
      GEOGRAPHICAL_LEVEL.ELECTRICITY_DEMAND_MAX_GRADIENT,
    ),
    electricity_demand_total_kw: createStyleConfig(
      0,
      GEOGRAPHICAL_LEVEL.ELECTRICITY_DEMAND_MIN_GRADIENT,
      powerLoadMax,
      GEOGRAPHICAL_LEVEL.ELECTRICITY_DEMAND_MAX_GRADIENT,
    ),
  };
}

function createItem(key, unit = '', decimal = undefined, multiply = undefined) {
  return { key, unit, decimal, multiply };
}

const getSidebarBaseChips = [
  {
    title: 'Gebäude',
    active: true,
    items: [
      {
        title: 'Gebäudeinformationen',
        active: true,
        items: [
          createItem('num_buildings'),
          createItem('usable_area_m2', 'm²', 0),
        ],
      },
      {
        title: 'Gebäudestruktur',
        active: false,
        items: [
          {
            panelItemType: 'pieChart',
            title: 'Gebäude nach Sektoren',
            titleInside: 'Gebäude',
            key: 'sectors_building',
            valueFieldName: 'num_buildings',
            seriesFieldName: 'sector',
            style: SECTOR_BY_ENUM,
            legend: SECTOR_NO_MOBILITY_LEGENDS_ORDERED,
            unit: '',
          },
          {
            panelItemType: 'pieChart',
            title: 'Gebäude nach Baualtersklassen',
            titleInside: 'Gebäude',
            key: 'construction_year_classes',
            valueFieldName: 'num_buildings',
            seriesFieldName: 'construction_year_class',
            style: CONSTRUCTION_YEAR_BY_ENUM,
            unit: '',
          },
          {
            panelItemType: 'pieChart',
            title: 'Gebäude nach Energieträger',
            titleInside: 'Gebäude',
            key: 'heating_types',
            valueFieldName: 'num_buildings',
            seriesFieldName: 'heating_type',
            style: HEATING_TYPE_BY_ENUM,
            unit: '',
          },
        ],
      },
    ],
  },
  {
    title: 'Nachfrage',
    active: false,
    items: [
      {
        title: 'Strom',
        active: true,
        items: [
          createItem('electricity_demand_total_kwh_a', 'MWh(el)/a', 1, 0.001),
          createItem('electricity_demand_total_kw', 'MW(el)', 1, 0.001),
          createItem(
            'electricity_demand_total_pre_simultaneity_kw',
            'MW(el)',
            1,
            0.001,
          ),
        ],
      },
      {
        title: 'Strom - Charts',
        active: false,
        items: [
          {
            panelItemType: 'pieChart',
            title: 'Strombedarf nach Sektoren',
            titleInside: 'Strombedarf',
            key: 'sectors',
            valueFieldName: 'electricity_demand_total_kwh_a',
            seriesFieldName: 'sector',
            style: SECTOR_BY_ENUM,
            unit: 'MWh(el)',
            multiply: 0.001,
          },
          {
            panelItemType: 'pieChart',
            title: 'Strombedarf nach Verbrauchstreibern',
            titleInside: 'Strombedarf',
            unit: 'MWh(el)/a',
            // needed if data is on base level and not pre grouped in
            // backend. data is resp obj from backend
            customDataTransformFunction: (data) => [
              {
                color: POWER_DEMAND.CONVENTIONAL,
                name: 'Konventionell',
                value: Math.round(data.electricity_demand_base_kwh_a * 0.001),
              },
              {
                color: POWER_DEMAND.ELECTRIFICATION_MOBILITY,
                name: 'Elektrifizierung Mobilität',
                value: Math.round(data.electricity_demand_bev_kwh_a * 0.001),
              },
              {
                color: POWER_DEMAND.ELECTRIFICATION_HEAT_SINGLE,
                name: 'Elektrifizierung Wärme Einzelversorgung',
                value: Math.round(
                  data.electricity_demand_heat_pump_kwh_a * 0.001,
                ),
              },
              {
                color: POWER_DEMAND.ELECTRIFICATION_HEAT_NETWORK,
                name: 'Elektrifizierung Wärmenetz',
                value: Math.round(
                  data.electricity_demand_heat_network_kwh_a * 0.001,
                ),
              },
            ],
          },
        ],
      },
      {
        title: 'Wärme',
        active: false,
        items: [
          createItem('heat_demand_kwh_a', 'MWh(th)/a', 1, 0.001),
          createItem('heat_demand_kw', 'MW(th)', 1, 0.001),
          createItem('heat_demand_pre_simultaneity_kw', 'MW(th)', 1, 0.001),
          createItem('building_count_refurbished'),
        ],
      },
      {
        title: 'Wärme - Charts',
        active: false,
        items: [
          {
            panelItemType: 'pieChart',
            title: 'Wärmebedarf nach Sektoren',
            titleInside: 'Wärmebedarf',
            key: 'sectors',
            valueFieldName: 'heat_demand_kwh_a',
            seriesFieldName: 'sector',
            style: SECTOR_BY_ENUM,
            unit: 'MWh(th)',
            multiply: 0.001,
          },
          {
            panelItemType: 'pieChart',
            title: 'Wärmebedarf nach Energieträger',
            titleInside: 'Wärmebedarf',
            key: 'heating_types',
            valueFieldName: 'heat_demand_kwh_a',
            seriesFieldName: 'heating_type',
            style: HEATING_TYPE_BY_ENUM,
            unit: 'MWh(th)',
            multiply: 0.001,
          },
        ],
      },
    ],
  },
];

export function getSidebarSimple(name, subtitleKeys) {
  return {
    name,
    subtitles: subtitleKeys,
    chips: [
      ...getSidebarBaseChips,
      {
        title: 'Erzeugung',
        active: false,
        items: [
          {
            title: 'PV-Dach (Potenzial)',
            active: true,
            items: [
              {
                key: 'pv_roof_power_kw',
                unit: 'MWp',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'pv_roof_production_kwh_a',
                unit: 'MWh(el)/a',
                decimal: 2,
                multiply: 0.001,
              },
            ],
          },
        ],
      },
    ],
  };
}

export function getSidebarExtended(name, subtitleKeys) {
  return {
    name,
    subtitles: subtitleKeys,
    chips: [
      ...getSidebarBaseChips,
      {
        title: 'Erzeugung',
        active: false,
        items: [
          {
            title: 'Bestand',
            active: true,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Leistung nach EE-Erzeuger',
                titleInside: 'Leistung',
                unit: 'MW(el)',
                // needed if data is on base level and not pre grouped in
                // backend. data is resp obj from backend
                customDataTransformFunction: (data) => [
                  {
                    color: GENERATION_POTENTIAL.WIND,
                    name: 'Wind',
                    value: Math.round(data.existing_wind_power_kw * 0.001),
                  },
                  {
                    color: GENERATION_POTENTIAL.PV_FREE,
                    name: 'PV-Freifläche',
                    value: Math.round(data.existing_pv_free_power_kw * 0.001),
                  },
                  {
                    color: GENERATION_POTENTIAL.PV_ROOF,
                    name: 'PV-Aufdach',
                    value: Math.round(data.existing_pv_roof_power_kw * 0.001),
                  },
                  {
                    color: GENERATION_POTENTIAL.WATER,
                    name: 'Wasserkraft',
                    value: Math.round(
                      data.existing_hydropower_power_kw * 0.001,
                    ),
                  },
                  {
                    color: GENERATION_POTENTIAL.BIOMASS,
                    name: 'Biomasse',
                    value: Math.round(data.existing_biomass_power_kw * 0.001),
                  },
                ],
              },
              {
                panelItemType: 'pieChart',
                title: 'Stromerzeugung nach EE-Erzeuger',
                titleInside: 'Stromerzeugung',
                unit: 'MWh(el)/a',
                // needed if data is on base level and not pre grouped in
                // backend. data is resp obj from backend
                customDataTransformFunction: (data) => [
                  {
                    color: GENERATION_POTENTIAL.WIND,
                    name: 'Wind',
                    value: Math.round(data.existing_wind_power_kwh_a * 0.001),
                  },
                  {
                    color: GENERATION_POTENTIAL.PV_FREE,
                    name: 'PV-Freifläche',
                    value: Math.round(
                      data.existing_pv_free_power_kwh_a * 0.001,
                    ),
                  },
                  {
                    color: GENERATION_POTENTIAL.PV_ROOF,
                    name: 'PV-Aufdach',
                    value: Math.round(
                      data.existing_pv_roof_power_kwh_a * 0.001,
                    ),
                  },
                  {
                    color: GENERATION_POTENTIAL.WATER,
                    name: 'Wasserkraft',
                    value: Math.round(
                      data.existing_hydropower_power_kwh_a * 0.001,
                    ),
                  },
                  {
                    color: GENERATION_POTENTIAL.BIOMASS,
                    name: 'Biomasse',
                    value: Math.round(
                      data.existing_biomass_power_kwh_a * 0.001,
                    ),
                  },
                ],
              },
            ],
          },
          {
            title: 'Potenzial',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Leistung nach EE-Erzeuger',
                titleInside: 'Leistung',
                unit: 'MW(el)',
                // needed if data is on base level and not pre grouped in
                // backend. data is resp obj from backend
                customDataTransformFunction: (data) => [
                  {
                    color: GENERATION_POTENTIAL.WIND,
                    name: 'Wind',
                    value: Math.round(data.wind_power_kw * 0.001),
                  },
                  {
                    color: GENERATION_POTENTIAL.PV_FREE,
                    name: 'PV-Freifläche',
                    value: Math.round(data.pv_free_power_kw * 0.001),
                  },
                  {
                    color: GENERATION_POTENTIAL.PV_ROOF,
                    name: 'PV-Aufdach',
                    value: Math.round(data.pv_roof_power_kw * 0.001),
                  },
                ],
              },
              {
                panelItemType: 'pieChart',
                title: 'Stromerzeugung nach EE-Erzeuger',
                titleInside: 'Stromerzeugung',
                unit: 'MWh(el)/a',
                // needed if data is on base level and not pre grouped in
                // backend. data is resp obj from backend
                customDataTransformFunction: (data) => [
                  {
                    color: GENERATION_POTENTIAL.WIND,
                    name: 'Wind',
                    value: Math.round(data.wind_production_kwh_a * 0.001),
                  },
                  {
                    color: GENERATION_POTENTIAL.PV_FREE,
                    name: 'PV-Freifläche',
                    value: Math.round(data.pv_free_production_kwh_a * 0.001),
                  },
                  {
                    color: GENERATION_POTENTIAL.PV_ROOF,
                    name: 'PV-Aufdach',
                    value: Math.round(data.pv_roof_production_kwh_a * 0.001),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
}

export default {
  [LAYER_KEY__BLOCK]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__BLOCK, {}),
    sidebar: getSidebarSimple('Block', [
      'id',
      'district_name',
      'municipality_name',
      'municipality_key',
    ]),
    ...AGGREGATION_STYLE('Block', getStyle(1000, 10, 40000, 10, 0, 0, 0, 0, 0)),
  },
  [LAYER_KEY__DISTRICT]: {
    layoutState: 'default',
    layerConfig: getLayerConfig(LAYER_KEY__DISTRICT),
    sidebar: getSidebarSimple('Stadtteil', [
      'id',
      'district_name',
      'municipality_name',
      'municipality_key',
    ]),
    ...AGGREGATION_STYLE(
      'Stadtteil',
      getStyle(200000, 200, 200000, 50, 0, 0, 0, 0, 0),
    ),
  },
  [LAYER_KEY__MUNICIPALITY]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__MUNICIPALITY),
    sidebar: getSidebarExtended('Gemeinde', [
      'municipality_name',
      'municipality_key',
    ]),
    ...AGGREGATION_STYLE(
      'Gemeinde',
      getStyle(800000, 400, 400000, 100, 10, 10, 10, 10, 10),
    ),
  },
  [LAYER_KEY__GOVERNMENT_DISTRICT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__GOVERNMENT_DISTRICT),
    sidebar: getSidebarExtended('Regierungsbezirk', [
      'administrative_district_name',
    ]),
    ...AGGREGATION_STYLE(
      'Regierungsbezirk',
      getStyle(9000000, 50000000000, 6000, 2010, 100, 100, 100, 100, 100),
    ),
  },
  [LAYER_KEY__COUNTY]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__COUNTY),
    sidebar: getSidebarExtended('Landkreis', ['county_name']),
    ...AGGREGATION_STYLE(
      'Landkreis',
      getStyle(800000, 4000000000, 6000, 2010, 50, 50, 50, 50, 50),
    ),
  },
  [LAYER_KEY__FEDERAL_STATE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__FEDERAL_STATE),
    sidebar: getSidebarExtended('Bundesland', ['federal_state_name']),
    ...AGGREGATION_STYLE(
      'Bundesland',
      getStyle(9000000, 50000000000, 6000, 20, 1000, 1000, 1000, 1000, 1000),
    ),
  },
};
