import markerIcon from '@/assets/img/mapIcons/marker.png';

export default {
  marker: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      geoJSONSource: true,
      type: 'symbol',
      id: 'markerSource',
      layerId: 'marker',
      visibility: 'visible',
      dependsOnScenario: false,
      icons: [
        {
          name: 'markerIcon',
          path: markerIcon,
        },
      ],
    },
    style: {
      default: {
        layout: {
          'icon-image': 'markerIcon',
          'icon-size': 0.1,
          'icon-offset': [0, -165],
        },
      },
    },
  },
};
