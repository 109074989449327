import {
  LIND_GREEN_60,
  DEEP_BLUE_LIGHT,
  RESTRICTIONS_BY_ENUM,
  AQUAMARIN_70,
  AQUAMARIN_40,
  BLACK,
  IMPULSE_ORANGE_MEDIUM,
  NEUTRAL_GREY_60,
  SUN_YELLOW_40,
  TAROCCO_40,
  AZUR_BLUE_30,
  AZUR_BLUE_40,
  DEEP_BLUE_MEDIUM,
  AZUR_BLUE_10,
  AQUAMARIN_00,
} from '@/configs/color-constants';
import {
  gradientLayerStyle,
  gradientLegend,
  pointClickEnabledStyle,
} from '@/apps/features/map/utils';

export const LAYER_KEY__HIGH_VOLTAGE_GRID = 'hv_grid';
export const LAYER_KEY__GRID_STATIONS_SUBSTATION = 'grid_substation';
export const LAYER_KEY__GRID_STATIONS_NETWORK_CONNECTION =
  'grid_network_connection_point';
export const LAYER_KEY__SEARCH_AREA_STATIONS =
  'search_area_new_transformer_station';
export const LAYER_KEY__ESTATE = 'estate';
export const LAYER_KEY__RESTRICTION = 'restriction';

export default {
  [LAYER_KEY__ESTATE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__ESTATE,
      sourceLayer: LAYER_KEY__ESTATE,
      layerId: LAYER_KEY__ESTATE,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    sidebar: {
      name: 'Flurstück',
      subtitles: ['key', 'district_name', 'municipality_name'],
      chips: [
        {
          title: 'Gebäudestruktur',
          active: true,
          items: [
            {
              title: 'Detailinformationen',
              active: true,
              items: [
                {
                  key: 'district_key',
                  unit: '',
                },
                {
                  key: 'parcel_nr',
                  unit: '',
                },
                {
                  key: 'parcel_numerator',
                  unit: '',
                },
                {
                  key: 'parcel_denominator',
                  unit: '',
                },
                {
                  key: 'area_m2',
                  unit: 'm²',
                },
              ],
            },
            {
              title: 'Flächenkategorisierung',
              active: true,
              items: [
                {
                  key: 'landcover',
                  unit: '',
                },
                {
                  key: 'disadvantage_share',
                  unit: '',
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          AZUR_BLUE_30,
          ['boolean', ['feature-state', 'marked'], false],
          LIND_GREEN_60,
          'rgba(255,255,255,0)',
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
    },
  },
  [LAYER_KEY__RESTRICTION]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__RESTRICTION,
      sourceLayer: LAYER_KEY__RESTRICTION,
      layerId: LAYER_KEY__RESTRICTION,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    sidebar: {
      name: 'Restriktionsfläche',
      subtitles: [
        'id',
        'municipality_name',
        'restriction_category',
        'restriction_sub_category',
      ],
      chips: [],
    },
    style: {
      default: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          AZUR_BLUE_30,
          [
            'match',
            ['get', 'restriction_category'],
            ...Object.entries(RESTRICTIONS_BY_ENUM).flatMap(
              ([key, { color }]) => [key, color],
            ),
            'white',
          ],
        ],
        fillOpacity: 1,
        fillOutlineColor: '#000000',
      },
    },
    legend: {
      default: {
        name: 'Restriktionsflächen',
        unit: '',
        items: Object.values(RESTRICTIONS_BY_ENUM).map((v) => ({
          color: v.color,
          name: v.label,
          icon: 'mdi-checkbox-blank-circle',
        })),
      },
    },
  },
  [LAYER_KEY__SEARCH_AREA_STATIONS]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__SEARCH_AREA_STATIONS,
      sourceLayer: LAYER_KEY__SEARCH_AREA_STATIONS,
      layerId: LAYER_KEY__SEARCH_AREA_STATIONS,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    style: {
      default: {
        fillColor: AQUAMARIN_40,
        fillOpacity: 0.7,
        fillOutlineColor: BLACK,
      },
      construction_category: {
        fillColor: [
          'match',
          ['get', 'construction_category'],
          'Neubau',
          DEEP_BLUE_LIGHT,
          'Erweiterung',
          IMPULSE_ORANGE_MEDIUM,
          NEUTRAL_GREY_60,
        ],
        fillOpacity: 0.7,
        fillOutlineColor: BLACK,
      },
      time_period: {
        fillColor: [
          'match',
          ['get', 'time_period'],
          'bis 2028',
          DEEP_BLUE_LIGHT,
          '2029 bis 2033',
          IMPULSE_ORANGE_MEDIUM,
          NEUTRAL_GREY_60,
        ],
        fillOpacity: 0.7,
        fillOutlineColor: BLACK,
      },
    },
    legend: {
      default: {
        name: 'Infrastruktur',
        unit: '',
        items: [
          {
            color: AQUAMARIN_40,
            name: 'Umspannwerk-Suchräume',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      construction_category: {
        name: 'Umspannwerk-Suchräume Status',
        unit: '',
        items: [
          {
            color: DEEP_BLUE_LIGHT,
            name: 'Neubau',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: IMPULSE_ORANGE_MEDIUM,
            name: 'Erweiterung',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: NEUTRAL_GREY_60,
            name: 'Unbekannt',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      time_period: {
        name: 'Umspannwerk-Suchräume Zeitraum',
        unit: '',
        items: [
          {
            color: DEEP_BLUE_LIGHT,
            name: 'bis 2028',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: IMPULSE_ORANGE_MEDIUM,
            name: '2029 bis 2033',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: NEUTRAL_GREY_60,
            name: 'Unbekannt',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
  [LAYER_KEY__GRID_STATIONS_SUBSTATION]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__GRID_STATIONS_SUBSTATION,
      sourceLayer: LAYER_KEY__GRID_STATIONS_SUBSTATION,
      layerId: LAYER_KEY__GRID_STATIONS_SUBSTATION,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    sidebar: {
      name: 'Umspannwerk',
      subtitles: ['key', 'municipality_name'],
      chips: [
        {
          title: 'Detailinformationen',
          active: true,
          items: [
            {
              title: 'Detailinformationen',
              active: true,
              items: [
                {
                  key: 'type',
                  unit: '',
                },
                {
                  key: 'operator',
                  unit: '',
                },
                {
                  key: 'max_power_kw',
                  unit: 'kW',
                },
                {
                  key: 'reference_date',
                  unit: '',
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: pointClickEnabledStyle([AZUR_BLUE_40]),
      maxPowerKw: pointClickEnabledStyle(
        gradientLayerStyle(
          'max_power_kw',
          { value: 0, color: AQUAMARIN_00 },
          { value: 20000, color: AQUAMARIN_70 },
        ),
      ),
      operator: pointClickEnabledStyle([
        [
          'match',
          ['get', 'operator'],
          'Netze BW',
          DEEP_BLUE_LIGHT,
          'Andere',
          TAROCCO_40,
          AZUR_BLUE_40,
        ],
      ]),
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: AZUR_BLUE_40,
            name: 'HS/MS- und HÖS/HS- Umspannwerk',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      maxPowerKw: gradientLegend(
        'HS/MS- und HÖS/HS- Umspannwerk',
        'MW',
        { value: 0, color: AQUAMARIN_00 },
        { value: 20000, color: AQUAMARIN_70 },
      ),
      operator: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: DEEP_BLUE_LIGHT,
            name: 'Umspannwerke Netze BW',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: TAROCCO_40,
            name: 'Umspannwerke Andere',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
  [LAYER_KEY__GRID_STATIONS_NETWORK_CONNECTION]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__GRID_STATIONS_NETWORK_CONNECTION,
      sourceLayer: LAYER_KEY__GRID_STATIONS_NETWORK_CONNECTION,
      layerId: LAYER_KEY__GRID_STATIONS_NETWORK_CONNECTION,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    sidebar: {
      name: 'Netzverknüpfungspunkt',
      subtitles: ['key', 'municipality_name'],
      chips: [
        {
          title: 'Detailinformationen',
          active: true,
          items: [
            {
              title: 'Detailinformationen',
              active: true,
              items: [
                {
                  key: 'type',
                  unit: '',
                },
                {
                  key: 'operator',
                  unit: '',
                },
                {
                  key: 'max_power_kw',
                  unit: 'kW',
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: pointClickEnabledStyle([DEEP_BLUE_MEDIUM]),
      maxPowerKw: pointClickEnabledStyle(
        gradientLayerStyle(
          'max_power_kw',
          { value: 0, color: AZUR_BLUE_10 },
          { value: 15000, color: DEEP_BLUE_MEDIUM },
        ),
      ),
      operator: pointClickEnabledStyle([
        [
          'match',
          ['get', 'operator'],
          'Netze BW',
          DEEP_BLUE_LIGHT,
          'Andere',
          TAROCCO_40,
          AZUR_BLUE_40,
        ],
      ]),
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: DEEP_BLUE_MEDIUM,
            name: 'Netzverknüpfungspunkt',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      maxPowerKw: gradientLegend(
        'Netzverknüpfungspunkt',
        'MW',
        { value: 0, color: AZUR_BLUE_10 },
        { value: 15000, color: DEEP_BLUE_MEDIUM },
      ),
      operator: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: DEEP_BLUE_LIGHT,
            name: 'Netzverknüpfungspunkt Netze BW',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: TAROCCO_40,
            name: 'Netzverknüpfungspunkt Andere',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
  [LAYER_KEY__HIGH_VOLTAGE_GRID]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__HIGH_VOLTAGE_GRID,
      sourceLayer: LAYER_KEY__HIGH_VOLTAGE_GRID,
      layerId: LAYER_KEY__HIGH_VOLTAGE_GRID,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    style: {
      default: {
        lineColor: SUN_YELLOW_40,
        lineWidth: 2,
      },
      ltype: {
        lineColor: [
          'match',
          ['get', 'ltype'],
          'HS-F',
          TAROCCO_40,
          'HS-K',
          DEEP_BLUE_LIGHT,
          NEUTRAL_GREY_60,
        ],
        lineWidth: 2,
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: SUN_YELLOW_40,
            name: 'Hochspannungsleitung',
            icon: 'mdi-minus',
          },
        ],
      },
      ltype: {
        name: 'Leitungstyp Hochspannung',
        unit: '',
        items: [
          {
            color: TAROCCO_40,
            name: 'Freileitung',
            icon: 'mdi-minus',
          },
          {
            color: DEEP_BLUE_LIGHT,
            name: 'Kabel',
            icon: 'mdi-minus',
          },
          {
            color: NEUTRAL_GREY_60,
            name: 'Unbekannt',
            icon: 'mdi-minus',
          },
        ],
      },
    },
  },
};
