import {
  AQUAMARIN_20,
  AQUAMARIN_70,
  SUN_YELLOW_10,
  SUN_YELLOW_70,
  NEUTRAL_GREY_00,
  AZUR_BLUE_70,
  AZUR_BLUE_40,
  AZUR_BLUE_60,
  AZUR_BLUE_30,
  AQUAMARIN_30,
  AQUAMARIN_40,
  AQUAMARIN_60,
  TAROCCO_70,
  TAROCCO_60,
  TAROCCO_40,
  TAROCCO_30,
  SUN_YELLOW_40,
  SUN_YELLOW_30,
  SUN_YELLOW_80,
} from '@/configs/color-constants';
import { gradientLegend } from '@/apps/features/map/utils';

export const LAYER_KEY__ENVIRONMENT = 'environment';
export const LAYER_KEY__GLOBAL_RADIATION = 'global_radiation';
export const LAYER_KEY__ALTITUDE = 'mean_altitude_m';
export const LAYER_KEY__SLOPE = 'slope_degree';
export const LAYER_KEY__ORIENTATION = 'orientation_text';
export const ENVIRONMENT__LAYER_KEYS = [
  LAYER_KEY__GLOBAL_RADIATION,
  LAYER_KEY__ALTITUDE,
  LAYER_KEY__SLOPE,
  LAYER_KEY__ORIENTATION,
];

const radiation = {
  min: { value: 1097, color: SUN_YELLOW_10 },
  max: { value: 1212, color: SUN_YELLOW_70 },
};

const altitude = {
  min: { value: 0, color: AQUAMARIN_20 },
  max: { value: 1500, color: AQUAMARIN_70 },
};

const slope = {
  min: { value: 0, color: NEUTRAL_GREY_00 },
  max: { value: 45, color: AZUR_BLUE_70 },
};

const orientationLegend = {
  name: 'Geländeausrichtung',
  unit: '',
  items: [
    {
      color: AZUR_BLUE_70,
      name: 'N',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: AZUR_BLUE_60,
      name: 'NNO',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: AZUR_BLUE_40,
      name: 'NO',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: AZUR_BLUE_30,
      name: 'ONO',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: AQUAMARIN_30,
      name: 'O',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: AQUAMARIN_40,
      name: 'OSO',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: AQUAMARIN_60,
      name: 'SO',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: AQUAMARIN_70,
      name: 'SSO',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: TAROCCO_70,
      name: 'S',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: TAROCCO_60,
      name: 'SSW',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: TAROCCO_40,
      name: 'SW',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: TAROCCO_30,
      name: 'WSW',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SUN_YELLOW_30,
      name: 'W',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SUN_YELLOW_40,
      name: 'WNW',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SUN_YELLOW_70,
      name: 'NW',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SUN_YELLOW_80,
      name: 'NNW',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
};

const defaultStyle = {
  maxZoom: 15,
  rasterOpacity: 0.7,
};

export default {
  [LAYER_KEY__GLOBAL_RADIATION]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'raster',
      id: LAYER_KEY__GLOBAL_RADIATION,
      sourceLayer: LAYER_KEY__GLOBAL_RADIATION,
      layerId: LAYER_KEY__GLOBAL_RADIATION,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    style: { default: defaultStyle },
    legend: {
      default: gradientLegend(
        'Globalstrahlung',
        'kWh/m2',
        radiation.min,
        radiation.max,
      ),
    },
  },
  [LAYER_KEY__ALTITUDE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'raster',
      id: LAYER_KEY__ALTITUDE,
      sourceLayer: LAYER_KEY__ALTITUDE,
      layerId: LAYER_KEY__ALTITUDE,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    style: { default: defaultStyle },
    legend: {
      default: gradientLegend(
        'Topographie',
        'm. ü. NN',
        altitude.min,
        altitude.max,
      ),
    },
  },
  [LAYER_KEY__SLOPE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'raster',
      id: LAYER_KEY__SLOPE,
      sourceLayer: LAYER_KEY__SLOPE,
      layerId: LAYER_KEY__SLOPE,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    style: { default: defaultStyle },
    legend: {
      default: gradientLegend('Geländeneigung', 'Grad', slope.min, slope.max),
    },
  },
  [LAYER_KEY__ORIENTATION]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'raster',
      id: LAYER_KEY__ORIENTATION,
      sourceLayer: LAYER_KEY__ORIENTATION,
      layerId: LAYER_KEY__ORIENTATION,
      visibility: 'none',
      dependsOnScenario: false,
      tilesDirectory: 'basemodule',
    },
    style: { default: defaultStyle },
    legend: { default: orientationLegend },
  },
};
